<template>
  <BaseDialog max-width="800" persistent :value="true">
    <template #modal>
      <BaseCosmoCard v-if="automationEffect">
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('automation.remove_dialog.title')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="close()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div
            class="alert_danger"
            v-text="
              $t('automation.remove_dialog.confirmContent', {
                actionName: $t(automationEffect.effectLabel),
              })
            "
          />
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="close()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="remove()"
          >
            {{ $t("utils.delete") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";
import { AutomationEffect } from "@/core/Models/AutomationEffect";

export default {
  name: "AutomationRemoveDialog",
  props: {
    automationEffect: {
      type: AutomationEffect,
      default: () => null,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    workflowId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async remove() {
      this.isSubmiting = true;
      try {
        await WorkflowAPI.deleteWorkflowEffect({
          organizationId: this.organizationId,
          workflowId: this.workflowId,
          effectId: this.automationEffect.id,
        });
        this.$emit("effectRemoved", this.automationEffect);
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.snackbar.deleteSuccess"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding: 24px;
  .alert_danger {
    padding: 0 8px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ff5267;
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  & > * {
    margin-left: 16px;
  }
}
</style>
