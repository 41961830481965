<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8335 2H6.8335C6.30306 2 5.79436 2.21071 5.41928 2.58579C5.04421 2.96086 4.8335 3.46957 4.8335 4V20C4.8335 20.5304 5.04421 21.0391 5.41928 21.4142C5.79436 21.7893 6.30306 22 6.8335 22H18.8335C19.3639 22 19.8726 21.7893 20.2477 21.4142C20.6228 21.0391 20.8335 20.5304 20.8335 20V8L14.8335 2ZM10.8335 18H8.8335V16H10.8335V18ZM10.8335 14H8.8335V9H10.8335V14ZM13.8335 9V3.5L19.3335 9H13.8335Z"
      fill="#FF5267"
    />
  </svg>
</template>

<script>
export default {
  name: "FileError",
};
</script>
