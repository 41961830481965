export class Import {
  constructor({
    author = "",
    dateCreated = "",
    dateUpdated = "",
    done = false,
    file = null,
    fileErrors = null,
    id = Number.NaN,
  }) {
    this.author = author;
    this.dateCreated = dateCreated;
    this.dateUpdated = dateUpdated;
    this.done = done;
    this.file = file;
    this.fileErrors = fileErrors;
    this.id = id;
  }
}
