<!-- eslint-disable vue/no-v-html -->
<template>
  <BaseDialog
    max-width="800"
    :persistent="isSubmiting"
    :value="dialogState"
    @click:outside="reset()"
  >
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$tc('label.createFolder', 1)" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <div class="input_group">
            <div class="label" v-text="`${$tc('utils.name', 1)}*`" />
            <BaseTextField
              v-model="form.name"
              autofocus
              :placeholder="`${$tc('utils.name', 1)}*`"
              :error-messages="nameErrors"
              :has-error="!!nameErrors"
              @change="$v.form.$touch()"
            />
          </div>
          <div class="input_group">
            <div class="label" v-text="'Workflow'" />
            <base-autocomplete
              v-model="form.workflow"
              :loading="workflowsFilter.loading"
              :items="workflows.items"
              placeholder="Workflow"
              item-text="name"
              item-value="id"
              outlined
              :hide-details="!!selectedCampaign"
              :filter="() => true"
              :search-input.sync="workflowsFilter.name"
            >
              <template #append-item>
                <div v-intersect="loadMoreWorkflows" /> </template
            ></base-autocomplete>
            <div v-if="selectedCampaign" class="d-flex align-items">
              <input v-model="sendCampaign" type="checkbox" class="check" />
              <div
                class="campaign_alert"
                v-html="
                  $t('workflowCampaignModal.alert', {
                    campaignName: selectedCampaign.name,
                  })
                "
              />
            </div>
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :loading="isSubmiting"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import FolderAPI from "@/modules/Folder/Services/folder.api";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";

export default {
  name: "ClientCreateFolderDialog",
  mixins: [validationMixin],
  props: {
    dialogState: {
      type: Boolean,
    },
    client: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isSubmiting: false,
      form: {
        name: "",
        workflow: null,
      },
      sendCampaign: true,
      workflows: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      workflowsFilter: {
        loading: false,
        page: 1,
        pageSize: 10,
        is_ready: true,
        name: undefined,
        searchTimeoutRef: undefined,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("folder.createAndEditForm.validation.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("folder.createAndEditForm.validation.nameTooLong");
      }
      return "";
    },
    selectedCampaign() {
      if (!this.form.workflow) return null;
      const tmp = this.workflows.find((w) => w.id == this.form.workflow);
      if (!tmp?.campaign) return null;
      return tmp.campaign;
    },
  },
  watch: {
    "workflowsFilter.name"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterWorkflows();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.fetchWorkflows();
    },
    async fetchWorkflows() {
      this.workflowsFilter.loading = true;
      try {
        this.workflowsFilter.page = 1;
        const res = await WorkflowAPI.getOrganizationWorkflows({
          organizationId: this.organizationId,
          params: {
            page: this.workflowsFilter.page,
            name: this.workflowsFilter.name || "",
            page_size: this.workflowsFilter.pageSize,
            is_ready: this.workflowsFilter.is_ready,
          },
        });
        this.workflows = {
          ...res.data,
          items: res.data.results,
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.workflowsFilter.loading = false;
    },
    filterWorkflows() {
      if (this.workflowsFilter.searchTimeoutRef)
        clearTimeout(this.workflowsFilter.searchTimeoutRef);
      this.workflowsFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchWorkflows();
      }, 500);
    },
    async loadMoreWorkflows(_, __, isIntersecting) {
      if (
        !isIntersecting ||
        this.workflowsFilter.loading ||
        !this.workflows.next
      )
        return;
      this.workflowsFilter.page = this.workflows.next;
      this.workflowsFilter.loading = true;
      try {
        const res = await WorkflowAPI.getOrganizationWorkflows({
          organizationId: this.organizationId,
          params: {
            page: this.workflowsFilter.page,
            name: this.workflowsFilter.name || "",
            page_size: this.workflowsFilter.pageSize,
            is_ready: this.workflowsFilter.is_ready,
          },
        });
        this.workflows = {
          ...res.data,
          items: [...this.workflows.items, ...res.data.results],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.workflowsFilter.loading = false;
    },
    reset() {
      if (this.isSubmiting) return;
      this.form = {
        name: "",
        workflow: null,
        client: null,
      };
      this.$v.form.$reset();
      this.$emit("close");
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmiting = true;
      try {
        const payload = { ...this.form, client: this.client.id };
        if (this.selectedCampaign) payload.send_campaign = this.sendCampaign;
        const folder = (
          await FolderAPI.createFolder(this.organizationId, payload)
        ).data;
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.successCreate"),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "folderDetail",
          params: {
            organizationId: this.organizationId,
            folderId: folder.id,
          },
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.errorCreate"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .input_group {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    .label {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
      color: #2536cc;
    }
    .campaign_alert {
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 4px;
      margin-bottom: 24px;
      b {
        font-weight: 600;
      }
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  gap: 16px;
}
.check {
  transform: translateY(-10px);
  margin: 0px 3px;
  accent-color: #2536cc;
  background-color: #2536cc;
}
</style>
