<template>
  <div class="qfe_container">
    <div class="left_section">
      <template v-if="activeFile">
        <BaseFilePreviewer
          v-if="!activeFile.isOfficeFile"
          :url="activeFile.url"
          :name="activeFile.name"
          :display-ged="displayGed"
          @deleteFile="openConfirmDeleting(activeFileIndex)"
          @sendToGed="$emit('sendToGed')"
        />
        <BaseFilePreviewer
          v-else-if="!activeFile.convertingFile"
          :url="activeFile.convertedFile"
          :name="activeFile.convertedFileName"
          :display-ged="displayGed"
          @deleteFile="openConfirmDeleting(activeFileIndex)"
          @sendToGed="$emit('sendToGed')"
        />
        <div v-else class="loading_container">
          <div class="loading_label" v-text="`${$t('utils.loading')}...`" />
        </div>
      </template>
    </div>
    <div class="right_section">
      <div v-if="extractedData.length" class="extracted_section">
        <div class="extracted_title" v-text="$t('utils.extractedData')" />
        <div
          v-for="(data, index) in extractedData"
          :key="index"
          class="extracted_item"
        >
          <div class="label" v-text="data.key" />
          <div v-if="data.isImage" class="response_img">
            <img :src="data.value" />
          </div>
          <div v-else class="response" v-text="data.value" />
        </div>
      </div>
      <div class="file_section">
        <div
          class="section_title"
          v-text="$tc('utils.file', form.value.length)"
        />
        <div class="files">
          <!-- FILES SECTIONS -->
          <div
            v-for="(file, i) in form.value"
            :key="`f-${i}`"
            class="file_item"
            :class="{
              active_file: i == activeFileIndex,
            }"
            @click="activeFileIndex = i"
          >
            <BaseIcon icon="$mdiFileDocument" color="#9BBDFF" class="icon" />
            <div class="file_name" v-text="file.name" />
            <v-menu offset-y left>
              <template #activator="{ on, attrs }">
                <BaseButtonIcon
                  icon="$mdiDotsHorizontal"
                  v-bind="attrs"
                  small
                  v-on="on"
                />
              </template>
              <div class="menu_list">
                <v-btn
                  x-large
                  class="menu_list_item"
                  block
                  text
                  @click="downloadFile(file.url, file.name)"
                >
                  <BaseIcon class="icon" icon="$mdiDownload" />
                  <span class="label" v-text="$t('utils.downloadFile')" />
                </v-btn>
                <v-btn
                  v-if="displayGed"
                  x-large
                  class="menu_list_item"
                  block
                  text
                  @click="$emit('sendToGed')"
                >
                  <BaseIcon class="icon" icon="$mdiSend" />
                  <span class="label" v-text="$t('utils.sendToGed')" />
                </v-btn>
                <v-btn
                  x-large
                  class="menu_list_item"
                  block
                  text
                  @click="openConfirmDeleting(i)"
                >
                  <BaseIcon class="icon icon_danger" icon="$mdiTrashCan" />
                  <span class="label" v-text="$t('utils.deleteFile')" />
                </v-btn>
              </div>
            </v-menu>
          </div>
          <!-- FIELD -->
          <div class="field">
            <BaseFileDrag
              v-model="form.value"
              :multiple="'MULTIPLE_FILE' == question.typeQuestion"
              :label="
                'FILE' == question.typeQuestion ? $t('utils.replaceFile') : ''
              "
              @changeValue="handleFormChange()"
            />
          </div>
        </div>
      </div>
    </div>

    <FolderFileConfirmDeleteDialog
      :dialog-state="openConfirmDeletion"
      :file-to-delete="fileToDelete.file"
      :question="question"
      @delete="removeFile(fileToDelete.index)"
      @close="openConfirmDeletion = false"
    />
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import FolderFileConfirmDeleteDialog from "@/modules/Folder/Components/FolderOffer/FolderFileConfirmDeleteDialog.vue";
import { fileURLToFile, convertFileToPdf } from "@/core/Utils/file.utils";
export default {
  name: "QuestionFileEditor",
  components: {
    FolderFileConfirmDeleteDialog,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    question: {
      type: Object,
      required: true,
    },
    displayGed: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        value: [],
      },
      openConfirmDeletion: false,
      fileToDelete: {
        file: null,
        index: null,
      },
      activeFileIndex: -1,
    };
  },
  computed: {
    activeFile() {
      if (!this.form.value?.length || this.activeFileIndex < 0) return null;
      return this.form.value[this.activeFileIndex];
    },
    extractedData() {
      if (!this.question.answer.extractedData) return [];
      return [
        {
          key: this.$t("utils.authority"),
          value: this.question.answer.extractedData.authority,
        },
        {
          key: this.$t("utils.id"),
          value: this.question.answer.extractedData.permitId,
        },
        {
          key: this.$t("utils.permitList"),
          value: this.question.answer.extractedData.permitList,
        },
        {
          key: this.$t("utils.useName"),
          value: this.question.answer.extractedData.useName,
        },
        {
          key: this.$tc("utils.name", 1),
          value: this.question.answer.extractedData.name,
        },
        {
          key: this.$t("utils.firstName"),
          value: this.question.answer.extractedData.firstName,
        },
        {
          key: this.$t("utils.birthDate"),
          value: this.question.answer.extractedData.birthDate,
        },
        {
          key: this.$t("utils.birthPlace"),
          value: this.question.answer.extractedData.birthPlace,
        },
        {
          key: this.$t("utils.height"),
          value: this.question.answer.extractedData.height,
        },
        {
          key: this.$t("utils.addressFullText"),
          value: this.question.answer.extractedData.addressFullText,
        },
        {
          key: this.$t("utils.validityDate"),
          value: this.question.answer.extractedData.validityDate,
        },
        {
          key: this.$tc("utils.releaseDate", 1),
          value: this.question.answer.extractedData.releaseDate,
        },
        {
          key: this.$tc("utils.iban"),
          value: this.question.answer.extractedData.iban,
        },
        {
          key: this.$tc("utils.bic"),
          value: this.question.answer.extractedData.bic,
        },
        {
          key: this.$tc("utils.holder"),
          value: this.question.answer.extractedData.holder,
        },
        {
          key: this.$tc("utils.socialNumber"),
          value: this.question.answer.extractedData.socialNumber,
        },
        {
          key: this.$t("utils.signature"),
          value: this.question.answer.extractedData.signature,
          isImage: true,
        },
        {
          key: this.$t("utils.photo"),
          value: this.question.answer.extractedData.photo,
          isImage: true,
        },
      ].filter((wd) => wd.value);
    },
  },
  mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.form.value = [
        ...JSON.parse(JSON.stringify(this.question.answer.content)),
      ];
      this.checkOfficeFile();
      await this.convertAllFilesToPdf();
      this.activeFileIndex = 0;
    },
    downloadFile(url, name) {
      saveAs(url, name);
    },
    async buildAnswer() {
      return {
        question: this.question,
        content: this.form.value.filter((file) => !file.deletionEndpoint),
      };
    },
    openConfirmDeleting(index) {
      if (this.question.typeQuestion == "FILE") {
        this.form.value[index].deletionEndpoint = "";
      }
      this.fileToDelete.file = this.form.value[index];
      this.fileToDelete.index = index;
      this.openConfirmDeletion = true;
    },
    async removeFile(index) {
      this.form.value.splice(index, 1);
      if (this.form.value?.length) this.activeFileIndex = 0;
      this.handleFormChange();
    },
    async handleFormChange() {
      this.$emit("input", await this.buildAnswer());
      this.checkOfficeFile();
      this.convertAllFilesToPdf();
    },
    checkOfficeFile() {
      const officeFileExtensions = [
        ".xls",
        ".xlsx",
        ".doc",
        ".docx",
        ".ppt",
        ".pptx",
      ];
      for (const file of this.form.value) {
        const lowerCaseFilename = file.name.toLowerCase();
        if (
          !officeFileExtensions.some((ext) => lowerCaseFilename.endsWith(ext))
        )
          continue;
        file.convertingFile = true;
        file.isOfficeFile = true;
      }
    },
    async convertAllFilesToPdf() {
      for (const file of this.form.value) {
        try {
          if (file.convertedFile || !file.isOfficeFile) continue;
          file.convertingFile = true;
          const tmpFile = await fileURLToFile(file.url, file.name);
          file.convertedFile = await convertFileToPdf(tmpFile);
          file.convertedFileName = `${file.name.split(".").shift()}.pdf`;
          file.convertingFile = false;
        } catch (error) {
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qfe_container {
  width: 100%;
  height: 100%;
  display: flex;
  --right-width: 334px;
  .left_section {
    width: calc(100% - var(--right-width));
    height: 100%;
    flex: none;
    background: #f4f7ff;
    .loading_container {
      color: #707080;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
    }
  }
  .right_section {
    flex: none;
    width: var(--right-width);
    height: 100%;
    overflow-y: auto;
    padding: 26px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 3px;
      border: 1px solid #eeeef7;
    }

    &::-webkit-scrollbar-thumb {
      background: #b6bdff;
      border-radius: 3px;
    }
    .extracted_section {
      margin-bottom: 24px;
      .extracted_title {
        color: #353542;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 18px;
      }
      .extracted_item {
        padding-left: 18px;
        margin-bottom: 12px;
        .label {
          color: #707080;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          margin-bottom: 6px;
        }
        .response {
          color: #353542;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
        }
        .response_img {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            object-fit: contain;
          }
        }
        a {
          color: #2536cc;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .file_section {
      .section_title {
        color: #353542;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 18px;
      }
      .files {
        .file_item {
          cursor: pointer;
          display: flex;
          height: 36px;
          padding: 0px 16px;
          align-items: center;
          border-radius: 5px;
          background: #fff;
          border: 1px solid #eeeef7;
          margin-bottom: 18px;
          &.active_file {
            border-color: #2536cc;
          }
          .icon {
            ::v-deep {
              .v-icon__svg {
                width: 16px;
                height: 16px;
                flex: none;
              }
            }
          }
          .file_name {
            color: #707080;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
            width: calc(100% - 54px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    &::v-deep {
      .icon {
        margin-right: 8px;
        color: #2536cc;
        &.icon_danger {
          color: #ff5267;
        }
        .v-icon__svg {
          width: 16px;
          height: 16px;
          flex: none;
        }
      }
      .label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #353542;
        transition: all 0.3s ease-in;
      }
    }
    &:hover {
      &::v-deep {
        .label {
          color: #2536cc;
        }
      }
    }
    &.v-btn--disabled {
      &::v-deep {
        .icon {
          .v-icon {
            color: #cfcfdc !important;
          }
        }
        .label {
          color: #cfcfdc;
        }
      }
    }
  }
}
</style>
