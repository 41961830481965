var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qt_container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header_title",domProps:{"textContent":_vm._s(_vm.$t('utils.managmentOfQuestionTemplate'))}}),_c('BaseButton',{attrs:{"type":"primary","icon":"$mdiPlus"},on:{"click":function($event){_vm.dialogState.createAndEditQuestionTemplate = true}}},[_vm._v(" "+_vm._s(_vm.$t("utils.create"))+" ")])],1),(_vm.activeFilters.length)?_c('div',{staticClass:"header_center"},[_c('div',{staticClass:"filters"},[_c('div',{staticClass:"filter_icon"},[_c('BaseIcon',{attrs:{"icon":"$mdiFilterOutline"}})],1),_vm._l((_vm.activeFilters),function(filter){return _c('div',{key:filter.value,staticClass:"filter_item"},[_c('div',{staticClass:"filter_item_label"},[_vm._v(" "+_vm._s(((filter.label) + " : " + (filter.value)))+" ")]),_c('div',{staticClass:"close_icon",on:{"click":function($event){return _vm.removeFilter(("" + (filter.name)))}}},[_c('BaseIcon',{attrs:{"icon":"$mdiClose"}})],1)])})],2)]):_vm._e(),_c('BaseDataTable',{attrs:{"items":_vm.questionTemplate.items,"loading":_vm.questionTemplateFilter.loading,"options":{
      page: _vm.questionTemplateFilter.page,
      itemsPerPage: _vm.questionTemplateFilter.pageSize,
    },"server-items-length":_vm.questionTemplate.count,"loading-text":_vm.$t('utils.loadingData')},on:{"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"header-col filtrable"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","nudge-bottom":10,"bottom":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({staticClass:"filtrable"},on),[_vm._v(" "+_vm._s(_vm.$t("utils.field"))+" "),_c('BaseIcon',{attrs:{"icon":"$mdiMenuDown"}})],1)]}}])},[_c('div',{staticClass:"filter_search_section"},[_c('div',{staticClass:"search_header"},[_c('div',{staticClass:"filter_icon"},[_c('BaseIcon',{attrs:{"icon":"$mdiFilterOutline"}})],1),_c('div',{staticClass:"header_label"},[_vm._v(_vm._s(_vm.$t("utils.filter")))])]),_c('div',{staticClass:"search_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.name),expression:"filters.name"}],staticClass:"input",attrs:{"placeholder":"Rechercher..","type":"text"},domProps:{"value":(_vm.filters.name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "name", $event.target.value)},function (event) {
                        _vm.handleChange(
                          _vm.$t('utils.field'),
                          'name',
                          event.target.value
                        );
                      }]}})])])])],1),_c('th',[_c('div',{staticClass:"header-col d-flex justify-center align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","nudge-bottom":10,"bottom":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({staticClass:"filtrable"},on),[_vm._v(" "+_vm._s(_vm.$t("utils.slug"))+" "),_c('BaseIcon',{attrs:{"icon":"$mdiMenuDown"}})],1)]}}])},[_c('div',{staticClass:"filter_search_section"},[_c('div',{staticClass:"search_header"},[_c('div',{staticClass:"filter_icon"},[_c('BaseIcon',{attrs:{"icon":"$mdiFilterOutline"}})],1),_c('div',{staticClass:"header_label"},[_vm._v(_vm._s(_vm.$t("utils.filter")))])]),_c('div',{staticClass:"search_input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.slug),expression:"filters.slug"}],staticClass:"input",attrs:{"placeholder":"Rechercher..","type":"text"},domProps:{"value":(_vm.filters.slug)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "slug", $event.target.value)},function (event) {
                          _vm.handleChange(
                            _vm.$t('utils.slug'),
                            'slug',
                            event.target.value
                          );
                        }]}})])])])],1)]),_c('th',[_c('div',{staticClass:"header-col d-flex justify-center align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","nudge-bottom":10,"bottom":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({staticClass:"filtrable"},on),[_vm._v(" "+_vm._s(_vm.$tc("utils.type", 2))+" "),_c('BaseIcon',{attrs:{"icon":"$mdiMenuDown"}})],1)]}}])},[_c('div',{staticClass:"filter_search_section"},[_c('div',{staticClass:"search_header"},[_c('div',{staticClass:"filter_icon"},[_c('BaseIcon',{attrs:{"icon":"$mdiFilterOutline"}})],1),_c('div',{staticClass:"header_label"},[_vm._v(_vm._s(_vm.$t("utils.filter")))])]),_c('div',{staticClass:"search_input"},[_c('base-autocomplete',{attrs:{"items":_vm.typeItems,"placeholder":_vm.$t('question.chooseAType'),"label":("" + (_vm.$t('workflow.status.question.type'))),"item-text":"name","item-value":"type"},on:{"change":function (event) {
                          _vm.handleChange(
                            _vm.$tc('utils.type', 2),
                            'typeQuestion',
                            event
                          );
                        }},model:{value:(_vm.filters.typeQuestion),callback:function ($$v) {_vm.$set(_vm.filters, "typeQuestion", $$v)},expression:"filters.typeQuestion"}})],1)])])],1)]),_c('th',{staticClass:"header-col text-center"},[_vm._v(_vm._s(_vm.$t("utils.helpers")))]),_c('th',{staticClass:"header-col text-end"},[_vm._v(_vm._s(_vm.$tc("utils.action", 3)))])])])]},proxy:true},{key:"item",fn:function(ref){
                        var item = ref.item;
return [_c('tr',{staticClass:"tab_row"},[_c('td',[_c('div',{staticClass:"name_section"},[_c('div',{staticClass:"question_label",domProps:{"textContent":_vm._s(item.label)}}),_c('div',{staticClass:"question_name",domProps:{"textContent":_vm._s(item.name)}})])]),_c('td',[_c('div',{staticClass:"slug",domProps:{"textContent":_vm._s(item.slug)}})]),_c('td',[_c('div',{staticClass:"type_question",domProps:{"textContent":_vm._s(item.typeQuestion)}})]),_c('td',[_c('div',{staticClass:"help_section"},[_c('BaseIcon',{class:{ help_disable: !item.helpText },attrs:{"icon":"$mdiHelpCircle","color":"primary"}}),_c('BaseIcon',{class:{ help_disable: !item.helpFile },attrs:{"icon":"$mdiFileQuestion","color":"primary"}})],1)]),_c('td',[_c('div',{staticClass:"action_section"},[_c('BaseButtonIcon',{attrs:{"small":"","color":"primary","icon":"$mdiPencilOutline"},on:{"click":function($event){return _vm.editQuestionTemplate(item)}}}),_c('BaseButtonIcon',{attrs:{"small":"","color":"primary","icon":"$mdiReloadAlert"},on:{"click":function($event){return _vm.propagateQuestionTemplate(item)}}})],1)])])]}}])}),_c('QuestionTemplateCreateAndEditDialog',{attrs:{"dialog-state":_vm.dialogState.createAndEditQuestionTemplate,"question-template":_vm.activeQuestionTemplate,"organization-id":_vm.organizationId},on:{"questionTemplateCreated":function($event){return _vm.onQuestionTemplateCreated()},"questionTemplateEdited":function($event){return _vm.onQuestionTemplateEdited($event)},"close":function($event){return _vm.onCloseModal()}}}),_c('QuestionTemplatePropagateDialog',{attrs:{"dialog-state":_vm.dialogState.propagateQuestionTemplate,"question-template":_vm.activeQuestionTemplate,"organization-id":_vm.organizationId},on:{"close":function($event){return _vm.onCloseModal()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }