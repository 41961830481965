import { render, staticRenderFns } from "./FolderImport.vue?vue&type=template&id=85f8ab52&scoped=true"
import script from "./FolderImport.vue?vue&type=script&lang=js"
export * from "./FolderImport.vue?vue&type=script&lang=js"
import style0 from "./FolderImport.vue?vue&type=style&index=0&id=85f8ab52&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85f8ab52",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VMenu,VProgressCircular})
