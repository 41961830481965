import { render, staticRenderFns } from "./FolderPreviewQuestion.vue?vue&type=template&id=ca034422&scoped=true"
import script from "./FolderPreviewQuestion.vue?vue&type=script&lang=js"
export * from "./FolderPreviewQuestion.vue?vue&type=script&lang=js"
import style0 from "./FolderPreviewQuestion.vue?vue&type=style&index=0&id=ca034422&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca034422",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCheckbox,VSkeletonLoader})
