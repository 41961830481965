import DatatypeAPI from "@/core/Services/datatype.api";

export async function fetchDatatypes({ page, name, pageSize, typeQuestion }) {
  const res = await DatatypeAPI.getDatatypes({
    page,
    name,
    pageSize,
    typeQuestion,
  });
  return {
    ...res.data,
    items: res.data.results,
  };
}
