import AuthenticationAPI from "../Services/authentication.api";
import User from "@/core/Models/User";
import * as Sentry from "@sentry/vue";

export default {
  namespaced: true,
  state: {
    user: null,
    userPromise: null,
    isAuthenticated: false,
    modals: {
      isEditAvatarDialogOpen: false,
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload ? new User(payload) : null;
    },
    setUserPromise(state, payload) {
      state.userPromise = payload;
    },
    setIsAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    //#region Modals
    setIsEditAvatarDialog(state, payload) {
      state.modals.isEditAvatarDialogOpen = payload;
    },
    //#endregion
  },
  actions: {
    //#region User
    async fetchUser({ state, commit, dispatch }) {
      dispatch("resetUser");
      if (state.user) {
        commit("setIsAuthenticated", true);
        return state.user;
      }
      commit("setIsAuthenticated", false);
      if (state.userPromise) {
        return await state.userPromise;
      }
      const promise = AuthenticationAPI.getUser();
      commit("setUserPromise", promise);
      try {
        const res = await promise;
        const user = res.data;
        const value = user && JSON.stringify({ ...user });
        localStorage.setItem("user-connected", value);
        commit("setUser", user);
        commit("setUserPromise", null);
        commit("setIsAuthenticated", true);
        return user;
      } catch (error) {
        dispatch("resetUser");
        return false;
      }
    },
    async refreshUser({ commit }) {
      try {
        const promise = AuthenticationAPI.getUser();
        commit("setUserPromise", promise);
        const res = await promise;
        const user = res.data;
        commit("setUser", user);
        commit("setUserPromise", null);
        if (user == null) {
          return Sentry.captureException("Error refreshing user", {
            extra: {
              value: user,
            },
          });
        }
        return user;
      } catch (error) {
        Sentry.captureException(`${error.message}`);
        return false;
      }
    },
    resetUser({ commit }) {
      commit("setUser", null);
      commit("setUserPromise", null);
      commit("setIsAuthenticated", false);
    },
    async userRegister({ commit }, payload) {
      const res = await AuthenticationAPI.postUserRegister(payload);
      const user = res.data;
      commit("setUser", user);
      return user;
    },
    async updateUserAvatar({ dispatch }, { avatar }) {
      let payload = new FormData();
      payload.append("avatar", avatar);
      await AuthenticationAPI.patchUserAvatar(payload);
      dispatch("resetUser");
      const user = await dispatch("fetchUser");
      return user;
    },
    async updateUserPassword(_, { oldPassword, newPassword1, newPassword2 }) {
      await AuthenticationAPI.patchUserPassword({
        password_old: oldPassword,
        password_1: newPassword1,
        password_2: newPassword2,
      });
    },
    async deleteUser(_, password) {
      await AuthenticationAPI.deleteUser({
        password,
        confirm: 1,
      });
    },
    async passwordReset(_, email) {
      return await AuthenticationAPI.passwordReset({ email });
    },
    async passwordResetValidateToken(_, token) {
      return await AuthenticationAPI.passwordResetValidateToken({ token });
    },
    async passwordResetConfirm(_, payload) {
      return await AuthenticationAPI.passwordResetConfirm(payload);
    },
    //#endregion
  },
};
