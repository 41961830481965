<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <BaseButton
      icon="$mdiPlus"
      @click="$store.commit('folder/setModalCreateAndEditFolder', true)"
    >
      {{ $tc("organization.createFolder", 1) }}
    </BaseButton>
    <BaseModal
      :value="dialogState"
      :modal-title="$tc('organization.createFolder', 1)"
      is-unique
      @close="reset"
    >
      <template #modal>
        <v-form ref="form" @submit.prevent="submit()">
          <BaseTextField
            v-model="form.name"
            autofocus
            :label="$tc('utils.name', 1) + '*'"
            :error-messages="nameErrors"
            :has-error="!!nameErrors"
            @change="$v.form.$touch()"
          />
          <base-autocomplete
            v-model="form.workflow"
            :loading="workflowsFilter.loading"
            :items="workflows.items"
            outlined
            item-text="name"
            item-value="id"
            label="Workflow"
            :hide-details="!!selectedCampaign"
            :filter="() => true"
            :search-input.sync="workflowsFilter.name"
          >
            <template #append-item>
              <div v-intersect="loadMoreWorkflows" /> </template
          ></base-autocomplete>
          <div v-if="selectedCampaign" class="d-flex align-items">
            <input v-model="sendCampaign" type="checkbox" class="check" />
            <div
              class="campaign_alert"
              v-html="
                $t('workflowCampaignModal.alert', {
                  campaignName: selectedCampaign.name,
                })
              "
            />
          </div>

          <OrganizationContactSelector
            v-model="form.client"
            :organization-id="organizationId"
          />
        </v-form>
      </template>
      <template #actions>
        <BaseButton
          text
          class="mx-2 ml-auto"
          type="secondary"
          color="#707080"
          @click="reset"
        >
          {{ $t("utils.cancel") }}
        </BaseButton>
        <BaseButton type="primary" @click="submit">
          {{ $t("utils.validate") }}
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import FolderAPI from "@/modules/Folder/Services/folder.api";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import OrganizationContactSelector from "./OrganizationContactSelector";
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";

export default {
  name: "FolderCreateAndEditDialog",
  components: {
    OrganizationContactSelector,
  },
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        workflow: null,
        client: null,
      },
      sendCampaign: true,
      workflows: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      workflowsFilter: {
        loading: false,
        page: 1,
        pageSize: 10,
        is_ready: true,
        name: undefined,
        searchTimeoutRef: undefined,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(255),
      },
    },
  },
  computed: {
    ...mapState({
      dialogState: (state) => state.folder.modals.isCreateAndEditDialogOpen,
    }),
    organizationId() {
      return parseInt(this.$route.params.organizationId);
    },
    nameErrors() {
      if (!this.$v.form.name.$dirty) {
        return "";
      }
      if (!this.$v.form.name.required) {
        return this.$t("folder.createAndEditForm.validation.nameRequired");
      }
      if (!this.$v.form.name.maxLength) {
        return this.$t("folder.createAndEditForm.validation.nameTooLong");
      }
      return "";
    },
    selectedCampaign() {
      if (!this.form.workflow) return null;
      const tmp = this.workflows.items.find((w) => w.id == this.form.workflow);
      if (!tmp?.campaign) return null;
      return tmp.campaign;
    },
  },
  watch: {
    organizationId() {
      this.reset();
      this.initialize();
    },
    "workflowsFilter.name"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterWorkflows();
    },
  },
  mounted() {
    this.reset();
    this.initialize();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    reset() {
      this.form = {
        name: "",
        workflow: null,
        client: null,
      };
      this.$v.form.$reset();
      this.$store.commit("folder/setModalCreateAndEditFolder", false);
    },
    async initialize() {
      this.fetchWorkflows();
    },
    async fetchWorkflows() {
      this.workflowsFilter.loading = true;
      try {
        this.workflowsFilter.page = 1;
        const res = await WorkflowAPI.getOrganizationWorkflows({
          organizationId: this.organizationId,
          params: {
            page: this.workflowsFilter.page,
            name: this.workflowsFilter.name || "",
            page_size: this.workflowsFilter.pageSize,
            is_ready: this.workflowsFilter.is_ready,
          },
        });
        this.workflows = {
          ...res.data,
          items: res.data.results,
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.workflowsFilter.loading = false;
    },
    filterWorkflows() {
      if (this.workflowsFilter.searchTimeoutRef)
        clearTimeout(this.workflowsFilter.searchTimeoutRef);
      this.workflowsFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchWorkflows();
      }, 500);
    },
    async loadMoreWorkflows(_, __, isIntersecting) {
      if (
        !isIntersecting ||
        this.workflowsFilter.loading ||
        !this.workflows.next
      )
        return;
      this.workflowsFilter.page = this.workflows.next;
      this.workflowsFilter.loading = true;
      try {
        const res = await WorkflowAPI.getOrganizationWorkflows({
          organizationId: this.organizationId,
          params: {
            page: this.workflowsFilter.page,
            name: this.workflowsFilter.name || "",
            page_size: this.workflowsFilter.pageSize,
            is_ready: this.workflowsFilter.is_ready,
          },
        });
        this.workflows = {
          ...res.data,
          items: [...this.workflows.items, ...res.data.results],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.workflowsFilter.loading = false;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;
      this.createFolder();
      this.reset();
    },
    async createFolder() {
      let payload = {
        name: this.form.name,
      };
      if (this.selectedCampaign) payload.send_campaign = this.sendCampaign;
      if (this.form.workflow) payload.workflow = this.form.workflow;
      if (this.form.client) payload.client = this.form.client;
      try {
        const folder = (
          await FolderAPI.createFolder(
            this.$route.params.organizationId,
            payload
          )
        ).data;
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.successCreate"),
          type: "SUCCESS",
        });
        this.$router.push({
          name: "folderDetail",
          params: {
            organizationId: this.$route.params.organizationId,
            folderId: folder.id,
          },
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.createAndEditForm.errorCreate"),
          type: "ERROR",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign_alert {
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 24px;
  b {
    font-weight: 600;
  }
}
.check {
  transform: translateY(-10px);
  margin: 0px 3px;
  accent-color: #2536cc;
  background-color: #2536cc;
}
</style>
