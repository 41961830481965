<template>
  <div class="email_item_container">
    <!-- HEADER -->
    <FolderControlItemHeader
      :expanded="expanded"
      :control="control"
      @openHistory="controlHistoryDialog = true"
      @expandChange="expanded = !expanded"
    />

    <!-- BODY -->
    <template v-if="expanded">
      <div
        class="item_body"
        :class="{
          error_body: control.isError,
          control_success: control.isSuccess,
        }"
      >
        <div class="separator" />
        <div class="item_content">
          <!-- CONTROL ERROR REASONS -->
          <div
            v-if="control && control.isError && control.reasons"
            class="email_details"
          >
            <div class="item__title">
              {{ $t("utils.errorReason") }}
            </div>
            <div class="message__container">
              <!-- SCHEMA ERRORS -->
              <div
                v-for="(message, i) in control.reasons.schemaErrors"
                :key="`se${i}`"
                class="error_item"
                v-text="message"
              />
              <!-- DOCUMENT ERRORS -->
              <ul v-if="control.reasons.documents.length">
                <li
                  v-for="(document, i) in control.reasons.documents"
                  :key="`de${i}`"
                >
                  <span class="error_item_title" v-text="document.name" />
                  <ul>
                    <li
                      v-for="(docError, j) in document.documentErrors"
                      :key="`dee${j}`"
                      class="error_item"
                      v-text="docError"
                    />
                  </ul>
                  <!-- DOCUMENT PAGE ERRORS -->
                  <ul v-if="document.pages.length">
                    <li v-for="(page, pi) in document.pages" :key="`pe${pi}`">
                      <span class="error_item_title" v-text="page.name" />
                      <ul>
                        <li
                          v-for="(pageError, pj) in page.pageErrors"
                          :key="`pee${pj}`"
                          class="error_item"
                          v-text="pageError"
                        />
                      </ul>
                      <!-- PAGE FIELDS ERROS -->
                      <ul v-if="page.fields.length">
                        <li v-for="(field, fi) in page.fields" :key="`fe${fi}`">
                          <span class="error_item_title" v-text="field.name" />
                          <ul>
                            <li
                              v-for="(fieldError, fei) in field.fieldErrors"
                              :key="`fee${fei}`"
                              class="error_item"
                              v-text="fieldError"
                            />
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <!-- CONTROL QUESTION -->
          <div class="control_details_item">
            <div class="detail_title" v-text="$tc('utils.document', 2)" />
            <div class="control_questions">
              <ControlPreviewDocument
                v-for="(question, i) in control.questions"
                :key="`q-${i}${question.id + 1}`"
                :question="question"
                :control="control"
              />
            </div>
            <div v-if="control.isError" class="control_question_action">
              <v-btn
                depressed
                class="purple lighten-5 purple--text"
                small
                @click="openSendCosmoformDialog()"
              >
                <BaseIcon left small icon="$mdiSend" />
                <span v-text="$tc('utils.sendFields', 2)" />
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </template>

    <ControlHistoryDialog
      :dialog-state="controlHistoryDialog"
      :control-group="controlGroup"
      @close="controlHistoryDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FolderControlItemHeader from "./FolderControlItemHeader.vue";
import ControlPreviewDocument from "./ControlPreviewDocument.vue";
import { DocumentControlGroup } from "@/core/Models/DocumentControl";
import ControlHistoryDialog from "./ControlHistoryDialog.vue";

export default {
  name: "FolderControlItem",
  components: {
    FolderControlItemHeader,
    ControlPreviewDocument,
    ControlHistoryDialog,
  },
  props: {
    controlGroup: {
      type: DocumentControlGroup,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
      controlHistoryDialog: false,
    };
  },
  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    control() {
      return this.controlGroup.lastControl;
    },
  },
  methods: {
    openSendCosmoformDialog() {
      this.$store.commit("folder/SET_ACTIVE_QUESTIONS", this.control.questions);
      this.$store.commit("folder/SET_ACTIVE_CONTROL", this.control);
      this.$store.commit("folder/SET_MODAL_SEND_COSMO_FORM", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.email_item_container {
  background: #ffffff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;

  .item_body {
    border-left: 32px solid #b6bdff;
    position: relative;
    &.control_success {
      border-left: 32px solid #18a300;
    }
    &.error_body {
      border-left: 32px solid #ff5267;
    }

    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .item_content {
      padding: 24px;

      .email_details {
        padding: 16px 24px;
        .email_detail_item {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #353542;
          }
        }
      }
      .control_details_item {
        padding: 16px 24px;
        .detail_title {
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 24px;
        }
        .control_questions {
          display: flex;
          gap: 16px;
          flex-wrap: wrap;
          padding: 20px;
          border-radius: 5px;
          border: 1px solid #cfcfdc;
        }
        .control_question_action {
          display: flex;
          margin-top: 24px;
          justify-content: flex-end;
        }
      }
      .item__title {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 24px;
      }
      .message__container {
        border-radius: 5px;
        padding: 16px;
        background: #fef4f4;
        .error_item_title {
          color: #ff5267;
          font-family: Inter;
          font-size: 14px;
          line-height: 28px;
          font-weight: bold;
        }
        .error_item {
          color: #ff5267;
          font-family: Inter;
          font-size: 14px;
          line-height: 28px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
