import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Organization > Folder > Offer - API
  getOrganizationFolderOfferList(organizationId, folderId, page = 1) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/offer/?page=${page}`
    );
  },
  getOrganizationFolderOfferMember(organizationId, procedureId, page = 1) {
    return cosmoAPI.get(
      `/organization/${organizationId}/procedure/${procedureId}/member/?page=${page}`
    );
  },
  getOrganizationFolderOfferSignedFile(organizationId, procedureId, page = 1) {
    return cosmoAPI.get(
      `/organization/${organizationId}/procedure/${procedureId}/file/?page=${page}`
    );
  },

  postOrganizationFolderOffer(organizationId, folderId, payload) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postOrganizationFolderOfferCancel(
    organizationId,
    folderId,
    offerId,
    payload
  ) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/cancel/`,
      payload
    );
  },
  patchOrganizationFolderOffer(organizationId, folderId, offerId, payload) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postOrganizationFolderOfferSubmit(organizationId, folderId, offerId) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/submit/`
    );
  },
  patchOfferClients({ organizationId, folderId, offerId, payload }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/update_clients/`,
      payload
    );
  },
  patchOfferStatus({
    organizationId,
    folderId,
    offerId,
    offerClientId,
    payload,
  }) {
    return cosmoAPI.patch(
      `/wallet/organization/${organizationId}/folder/${folderId}/offer/${offerId}/offerclient/${offerClientId}/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  postOrganizationFolderOfferRemind({
    organizationId,
    folderId,
    offerId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/remind/`,
      payload
    );
  },
  //#endregion
  //#region Organization > Folder > Offer > File - API
  deleteOrganizationFolderOfferFiles({
    organizationId,
    folderId,
    offerId,
    offerFileId,
  }) {
    return cosmoAPI.delete(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/file/${offerFileId}`
    );
  },
  getOrganizationFolderOfferFileList(
    organizationId,
    folderId,
    offerId,
    page = 1
  ) {
    return cosmoAPI.get(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/file/?page=${page}`
    );
  },
  postOrganizationFolderOfferFile({
    organizationId,
    folderId,
    offerId,
    payload,
  }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/file/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  patchOrganizationFolderOfferFile({
    organizationId,
    folderId,
    offerId,
    payload,
    fileId,
  }) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/file/${fileId}/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  //#endregion
  //#region
  getWalletOrganizationFolderOfferFileList(
    organizationId,
    folderId,
    offerId,
    page = 1
  ) {
    return cosmoAPI.get(
      `/wallet/organization/${organizationId}/folder/${folderId}/offer/${offerId}/file/?page=${page}`
    );
  },
  getFolderOfferTemplate({ organizationId, params }) {
    return cosmoAPI.get(`/organization/${organizationId}/template/offer/`, {
      params,
    });
  },
  getFolderOfferTemplateDetails(organizationId, page = 1, offerId) {
    return cosmoAPI.get(
      `/organization/${organizationId}/template/offer/${offerId}/?page=${page}`
    );
  },
  //#endregion
  // #region procedure yousign
  postOfferAskSignature({ organizationId, folderId, offerId }) {
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/yousign/`
    );
  },
  postYouSignMemberAndSignature({
    organizationId,
    procedureId,
    payload,
    procedureType,
  }) {
    // procedure Type === member|| signature || file
    return cosmoAPI.post(
      `/organization/${organizationId}/procedure/${procedureId}/${procedureType}/`,
      payload
    );
  },
  postYouSignFile({ organizationId, procedureId, payload }) {
    // procedure Type === member|| signature || file
    return cosmoAPI.post(
      `/organization/${organizationId}/procedure/${procedureId}/file/`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  //payload :'status': 'CANCELED'
  cancelSignature(organizationId, procedureId, payload) {
    return cosmoAPI.patch(
      `/organization/${organizationId}/procedure/${procedureId}/`,
      payload
    );
  },

  postOfferClient(data) {
    const { organizationId, folderId, offerId, payload } = data;
    return cosmoAPI.post(
      `/organization/${organizationId}/folder/${folderId}/offer/${offerId}/offerclient/`,
      payload
    );
  },
  //payload: 'comment':comment
  createComment(data) {
    const { uuid, payload } = data;
    return cosmoAPI.post(`/offer/${uuid}/comment/`, payload);
  },

  getOfferDetail(uuid, email) {
    const mail = encodeURIComponent(email);
    return cosmoAPI.get(`offer/${uuid}/?email=${mail}`);
  },

  updateOffer(data) {
    const { uuid, payload, offerClientId } = data;
    return cosmoAPI.patch(
      `offer/${uuid}/offerclient/${offerClientId}/`,
      payload
    );
  },

  getOfferClient(uuid) {
    return cosmoAPI.get(`offer/${uuid}/offerclient/`);
  },
};
