import { EffectLog } from "./EffectLog";

export class AutomationEffect {
  constructor({
    id = Number.NaN,
    action = "",
    actionExtra = {},
    event = "",
    eventExtra = {},
    dateCreated = null,
    activateIn = null,
    logs = [],
  } = {}) {
    this.id = id;
    this.action = action;
    this.actionExtra = actionExtra;
    this.event = event;
    this.eventExtra = eventExtra;
    this.activateIn = activateIn;
    this.dateCreated = dateCreated ? new Date(dateCreated) : new Date();
    this.logs = logs ? logs.map((log) => new EffectLog(log)) : [];
  }

  get effectLabel() {
    return `automation.effect.${this.action.toLocaleLowerCase()}`;
  }

  get effectExtraLabel() {
    if (this.actionExtra?.name) return this.actionExtra.name;
    else if (this.actionExtra?.title) return this.actionExtra.title;
    return "";
  }

  get eventLabel() {
    return `automation.event.${this.event.toLocaleLowerCase()}`;
  }

  get eventExtratLabel() {
    if (this.activateIn || this.activateIn == 0) return this.activateIn;
    return this.eventExtra.name ? this.eventExtra.name : this.eventExtra.title;
  }
}
