import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=f9d29f8a&scoped=true"
import script from "./Admin.vue?vue&type=script&lang=js"
export * from "./Admin.vue?vue&type=script&lang=js"
import style0 from "./Admin.vue?vue&type=style&index=0&id=f9d29f8a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9d29f8a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VMenu,VTab,VTabItem,VTabs,VTabsItems})
