<template>
  <BaseDialog max-width="800" :value="dialogState" @click:outside="reset()">
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('utils.controlHistory')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <BaseDataTable
            :headers="TABLE_HEADER"
            :items="controlGroup.controls"
            :items-per-page="6"
          >
            <template #item="{ item }">
              <tr class="tab_row">
                <td>
                  <div class="date" v-text="$d(item.dateCreated, 'veryshort')" />
                </td>
                <td>
                  <div class="status_container">
                    <div
                      class="status"
                      :class="{
                        error_status: item.isError,
                        success_status: item.isSuccess,
                      }"
                      v-text="getStatusLabel(item)"
                    />
                  </div>
                </td>
                <td>
                  <div class="reasons">
                    <template v-if="item.reasons.length">
                      <div
                        v-for="msg in item.reasons"
                        :key="msg"
                        v-text="msg"
                      />
                    </template>
                    <div v-else v-text="'-'" />
                  </div>
                </td>
              </tr>
            </template>
          </BaseDataTable>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { DocumentControlGroup } from "@/core/Models/DocumentControl";

export default {
  name: "ControlHistoryDialog",
  props: {
    dialogState: {
      type: Boolean,
    },
    controlGroup: {
      type: DocumentControlGroup,
      required: true,
    },
  },
  data() {
    return {
      TABLE_HEADER: [
        {
          text: this.$t("utils.controlDate"),
          value: "date",
        },
        {
          text: this.$t("utils.result"),
          value: "status",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("utils.errorReason"),
          value: "reasons",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    reset() {
      this.$emit("close");
    },
    getStatusLabel(control) {
      if (control.isPending) return this.$t("utils.controlRunning");
      if (control.isError) return this.$t("utils.error");
      if (control.isSuccess) return this.$t("utils.successControl");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  margin-top: 24px;
  .tab_row {
    .date {
      color: #707080;
      font-family: "Inter";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      text-transform: uppercase;
    }
    .status_container {
      display: flex;
      justify-content: center;
      .status {
        padding: 4px 8px;
        border-radius: 4px;
        background: #f4f7ff;
        color: #2536cc;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        &.error_status {
          color: #ff5267;
          background: #fef9fa;
        }
        &.success_status {
          background: #f0ffd6;
          color: #18a300;
        }
      }
    }
    .reasons {
      padding-top: 8px;
      margin-bottom: 8px;
      color: #353542;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      & > * {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
