import { render, staticRenderFns } from "./QuestionFileEditor.vue?vue&type=template&id=59d0c12f&scoped=true"
import script from "./QuestionFileEditor.vue?vue&type=script&lang=js"
export * from "./QuestionFileEditor.vue?vue&type=script&lang=js"
import style0 from "./QuestionFileEditor.vue?vue&type=style&index=0&id=59d0c12f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59d0c12f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VMenu})
