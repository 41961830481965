// VUEJS LIBRARY
import Vue from "vue/dist/vue.esm.js";
Vue.config.productionTip = false;

// ERROR REPORTING LIBRARY
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

const APP_VERSION = process.env.VUE_APP_LAST_DEPLOYED_BRANCH;

if (process.env.VUE_APP_SENTRY) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY,
    environment: process.env.NODE_ENV,
    release: APP_VERSION,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [process.env.VUE_APP_COSMO_URL_API],
      }),
    ],
    tracesSampleRate: 1.0,
    logErrors: true,
    attachProps: true,
    tracingOptions: {
      trackComponents: true,
    },
    attachStacktrace: true,
  });
}

// VUEJS STORE
import Vuex from "vuex";
import StorePlugin from "./plugins/vuex/index";
Vue.use(Vuex);
Vue.use(StorePlugin);
// VUEJS ROUTER
import { router } from "./plugins/router/index";

// VUEJS LOCALIZATION PLUGIN
import i18n from "./plugins/i18n/index";

// VUE TAG PLUGIN
import VueGtag from "vue-gtag";
if (process.env.VUE_APP_GOOGLE_TAG_ID) {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GOOGLE_TAG_ID,
    },
  });
}

// VUE SIGNATURE PAD
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);

// VUE EXCEL EDITOR FOR DATAGRID AND ROW_DATAGRID
// Here is the library documentation : https://github.com/cscan/vue-excel-editor
import VueExcelEditor from "vue-excel-editor";
Vue.use(VueExcelEditor);

// VUE DATAGRID & ROW DATAGRID LIBRARY
import CanvasDatagrid from "canvas-datagrid";
Vue.use(CanvasDatagrid);
Vue.config.ignoredElements = ["canvas-datagrid"];

// UI COMPONENT LIBRARY
import vuetify from "./plugins/vuetify/vuetify";

// CUSTOM VUEJS PLUGINS
import Static from "./plugins/static/index";
Vue.use(Static);

// GLOBAL REGISTRATION FOR COMPONENT
import globalBaseComponentRegistration from "./plugins/globalBaseComponentRegistration/index";
globalBaseComponentRegistration();

// VUe clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// VUe Vue2Editor
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import App from "./App.vue";

export const COSMO_APP = new Vue({
  el: "#app",
  i18n: i18n,
  vuetify: vuetify,
  components: { App },
  router,
});
