import Status from "./Status";

export default class Workflow {
  constructor({
    id = Number.NaN,
    name = "",
    primaryStatus = Number.NaN,
    campaign = {
      id: Number.NaN,
      name: "",
    },
    statuses = [],
  } = {}) {
    this.id = Number.parseInt(id);
    this.name = name;
    this.primaryStatus = Number.parseInt(primaryStatus);
    this.campaign = campaign;
    this.statuses = statuses
      ? statuses.map((status) => new Status(status))
      : [];
  }
}
