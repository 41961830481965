<template>
  <div class="item_header" @click="changeExpandStatus()">
    <div
      class="icon_left"
      :class="{
        error: control.isError,
        control_success: control.isSuccess,
      }"
    >
      <BaseIcon icon="$mdiCogs" class="icon" />
    </div>
    <div class="sections">
      <div class="section_left">
        <div class="section_info">
          <div class="section_title">
            <div class="stitle" v-text="control.validationSchema" />
            <BaseButtonIcon
              icon="$mdiClockTimeFourOutline"
              color="#2536CC"
              @click.stop="$emit('openHistory')"
            />
          </div>
          <div
            class="section_content"
            v-text="
              $t('folder.createdAt', {
                date: $d(control.dateCreated, 'veryshort'),
              })
            "
          />
        </div>
      </div>

      <div class="section_right">
        <div class="chips" :class="getStatusClass" v-text="getStatus" />
      </div>
    </div>
    <div class="icon_right">
      <v-btn icon @click.stop="changeExpandStatus()">
        <BaseIcon :icon="expanded ? '$mdiChevronUp' : '$mdiChevronDown'" />
      </v-btn>
    </div>
  </div>
</template>
<script>
import { DocumentControl } from "@/core/Models/DocumentControl";
export default {
  name: "FolderControlItemHeader",
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    control: {
      type: DocumentControl,
      required: true,
    },
  },
  computed: {
    getStatus() {
      if (this.control.isPending) return this.$t("utils.controlRunning");
      if (this.control.isError) return this.$t("utils.errorWizidee");
      if (this.control.isSuccess) return this.$t("utils.successControl");
      return "";
    },
    getStatusClass() {
      if (this.control.isSuccess) return "chips_success";
      else if (this.control.isError) return "chips_danger";
      return "chips";
    },
  },
  methods: {
    changeExpandStatus() {
      this.$emit("expandChange");
    },
  },
};
</script>

<style lang="scss" scoped>
.item_header {
  display: flex;
  cursor: pointer;
  .icon_left {
    width: 32px;
    background: #b6bdff;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &.control_success {
      background: #18a300;
    }
    &.error {
      background: #ff5267;
    }
    & > .icon {
      &::v-deep {
        .c_icon {
          width: auto;
          height: 14px;
          color: #fff;
        }
        .v-icon {
          width: 14px;
          height: 14px;
          color: #fff;
        }
      }
    }
  }
  .sections {
    padding: 24px;
    display: flex;
    flex: 1;
    .section_left {
      padding: 8px;
      padding-left: 16px;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .section_info {
        font-family: "Inter";
        font-style: normal;
        flex: 1;
        .section_title {
          display: flex;
          align-items: center;
          gap: 8px;
          .stitle {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            color: #4f4f4f;
          }
        }
        .section_content {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
        }
      }
    }
    .section_right {
      width: 200px;
      flex: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .chips {
        padding: 8px 16px;
        background: #f4f7ff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #2536cc;
        border-radius: 4px;
        &.chips_success {
          background: #f0ffd6;
          color: #18a300;
        }
        &.chips_danger {
          background: #fef9fa;
          color: #ff5267;
        }
      }
    }
  }
  .icon_right {
    flex: none;
    width: 76px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
