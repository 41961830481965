<template>
  <div class="qt_container">
    <div class="header">
      <div
        class="header_title"
        v-text="$t('utils.managmentOfQuestionTemplate')"
      />
      <BaseButton
        type="primary"
        icon="$mdiPlus"
        @click="dialogState.createAndEditQuestionTemplate = true"
      >
        {{ $t("utils.create") }}
      </BaseButton>
    </div>
    <div v-if="activeFilters.length" class="header_center">
      <div class="filters">
        <div class="filter_icon">
          <BaseIcon icon="$mdiFilterOutline" />
        </div>
        <div
          v-for="filter in activeFilters"
          :key="filter.value"
          class="filter_item"
        >
          <div class="filter_item_label">
            {{ `${filter.label} : ${filter.value}` }}
          </div>
          <div class="close_icon" @click="removeFilter(`${filter.name}`)">
            <BaseIcon icon="$mdiClose" />
          </div>
        </div>
      </div>
    </div>
    <BaseDataTable
      :items="questionTemplate.items"
      :loading="questionTemplateFilter.loading"
      :options="{
        page: questionTemplateFilter.page,
        itemsPerPage: questionTemplateFilter.pageSize,
      }"
      :server-items-length="questionTemplate.count"
      :loading-text="$t('utils.loadingData')"
      @update:options="onPaginationChange"
    >
      <template #header>
        <thead>
          <tr>
            <th class="header-col filtrable">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                :nudge-bottom="10"
                bottom
                min-width="auto"
              >
                <template #activator="{ on }">
                  <div class="filtrable" v-on="on">
                    {{ $t("utils.field") }}
                    <BaseIcon icon="$mdiMenuDown" />
                  </div>
                </template>
                <div class="filter_search_section">
                  <div class="search_header">
                    <div class="filter_icon">
                      <BaseIcon icon="$mdiFilterOutline" />
                    </div>
                    <div class="header_label">{{ $t("utils.filter") }}</div>
                  </div>
                  <div class="search_input">
                    <input
                      v-model="filters.name"
                      placeholder="Rechercher.."
                      class="input"
                      type="text"
                      @input="
                        (event) => {
                          handleChange(
                            $t('utils.field'),
                            'name',
                            event.target.value
                          );
                        }
                      "
                    />
                  </div>
                </div>
              </v-menu>
            </th>
            <th>
              <div class="header-col d-flex justify-center align-center">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :nudge-bottom="10"
                  bottom
                  min-width="auto"
                >
                  <template #activator="{ on }">
                    <div class="filtrable" v-on="on">
                      {{ $t("utils.slug") }}
                      <BaseIcon icon="$mdiMenuDown" />
                    </div>
                  </template>
                  <div class="filter_search_section">
                    <div class="search_header">
                      <div class="filter_icon">
                        <BaseIcon icon="$mdiFilterOutline" />
                      </div>
                      <div class="header_label">{{ $t("utils.filter") }}</div>
                    </div>
                    <div class="search_input">
                      <input
                        v-model="filters.slug"
                        placeholder="Rechercher.."
                        class="input"
                        type="text"
                        @input="
                          (event) => {
                            handleChange(
                              $t('utils.slug'),
                              'slug',
                              event.target.value
                            );
                          }
                        "
                      />
                    </div>
                  </div>
                </v-menu>
              </div>
            </th>
            <th>
              <div class="header-col d-flex justify-center align-center">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  :nudge-bottom="10"
                  bottom
                  min-width="auto"
                >
                  <template #activator="{ on }">
                    <div class="filtrable" v-on="on">
                      {{ $tc("utils.type", 2) }}
                      <BaseIcon icon="$mdiMenuDown" />
                    </div>
                  </template>
                  <div class="filter_search_section">
                    <div class="search_header">
                      <div class="filter_icon">
                        <BaseIcon icon="$mdiFilterOutline" />
                      </div>
                      <div class="header_label">{{ $t("utils.filter") }}</div>
                    </div>
                    <div class="search_input">
                      <base-autocomplete
                        v-model="filters.typeQuestion"
                        :items="typeItems"
                        :placeholder="$t('question.chooseAType')"
                        :label="`${$t('workflow.status.question.type')}`"
                        item-text="name"
                        item-value="type"
                        @change="
                          (event) => {
                            handleChange(
                              $tc('utils.type', 2),
                              'typeQuestion',
                              event
                            );
                          }
                        "
                      />
                    </div>
                  </div>
                </v-menu>
              </div>
            </th>
            <th class="header-col text-center">{{ $t("utils.helpers") }}</th>

            <th class="header-col text-end">{{ $tc("utils.action", 3) }}</th>
          </tr>
        </thead>
      </template>
      <template #item="{ item }">
        <tr class="tab_row">
          <td>
            <div class="name_section">
              <div class="question_label" v-text="item.label" />
              <div class="question_name" v-text="item.name" />
            </div>
          </td>
          <td>
            <div class="slug" v-text="item.slug" />
          </td>
          <td>
            <div class="type_question" v-text="item.typeQuestion" />
          </td>
          <td>
            <div class="help_section">
              <BaseIcon
                icon="$mdiHelpCircle"
                color="primary"
                :class="{ help_disable: !item.helpText }"
              />
              <BaseIcon
                icon="$mdiFileQuestion"
                color="primary"
                :class="{ help_disable: !item.helpFile }"
              />
            </div>
          </td>
          <td>
            <div class="action_section">
              <BaseButtonIcon
                small
                color="primary"
                icon="$mdiPencilOutline"
                @click="editQuestionTemplate(item)"
              />
              <BaseButtonIcon
                small
                color="primary"
                icon="$mdiReloadAlert"
                @click="propagateQuestionTemplate(item)"
              />
            </div>
          </td>
        </tr>
      </template>
    </BaseDataTable>
    <QuestionTemplateCreateAndEditDialog
      :dialog-state="dialogState.createAndEditQuestionTemplate"
      :question-template="activeQuestionTemplate"
      :organization-id="organizationId"
      @questionTemplateCreated="onQuestionTemplateCreated()"
      @questionTemplateEdited="onQuestionTemplateEdited($event)"
      @close="onCloseModal()"
    />
    <QuestionTemplatePropagateDialog
      :dialog-state="dialogState.propagateQuestionTemplate"
      :question-template="activeQuestionTemplate"
      :organization-id="organizationId"
      @close="onCloseModal()"
    />
  </div>
</template>

<script>
import { fetchQuestionTemplates } from "@/core/Services/questionTemplate.service";
import QuestionTemplateCreateAndEditDialog from "./QuestionTemplateCreateAndEditDialog";
import QuestionTemplatePropagateDialog from "./QuestionTemplatePropagateDialog";

export default {
  name: "QuestionTemplateList",
  components: {
    QuestionTemplateCreateAndEditDialog,
    QuestionTemplatePropagateDialog,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      TABLE_HEADER: [
        {
          text: this.$t("utils.field"),
          value: "label",
          sortable: false,
        },
        {
          text: this.$t("utils.slug"),
          value: "slug",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.type", 2),
          value: "type",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("utils.helpers"),
          value: "helpers",
          align: "center",
          sortable: false,
        },
        {
          text: this.$tc("utils.action", 3),
          value: "action",
          align: "end",
          sortable: false,
        },
      ],
      activeQuestionTemplate: null,
      dialogState: {
        createAndEditQuestionTemplate: false,
        propagateQuestionTemplate: false,
      },
      questionTemplate: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      filters: {
        name: null,
        slug: null,
        typeQuestion: null,
      },
      activeFilters: [],
      questionTemplateFilter: {
        page: 1,
        pageSize: 10,
        loading: false,
      },
      searchTimeoutRef: null,
      typeItems: [
        { name: "----", type: null },
        { name: this.$t("question.types.text"), type: "TEXT" },
        { name: this.$t("question.types.textarea"), type: "TEXTAREA" },
        { name: this.$t("question.types.number"), type: "NUMBER" },
        { name: this.$t("question.types.date"), type: "DATE" },
        { name: this.$t("question.types.datetime"), type: "DATETIME" },
        { name: this.$t("question.types.select"), type: "SELECT" },
        { name: this.$t("question.types.checkbox"), type: "CHECKBOX" },
        { name: this.$t("question.types.radio"), type: "RADIOBUTTON" },
        { name: this.$t("question.types.file"), type: "FILE" },
        { name: this.$t("question.types.multiFile"), type: "MULTIPLE_FILE" },
        { name: this.$t("question.types.signature"), type: "SIGNATURE" },
        { name: this.$t("question.types.datagrid"), type: "DATAGRID" },
        { name: this.$t("question.types.rowDatagrid"), type: "ROW_DATAGRID" },
      ],
    };
  },
  created() {
    this.fetchQuestionTemplates();
  },
  methods: {
    handleChange(label, name, value) {
      this.handleFilterChange(label, name, value);
      if (name === "typeQuestion") this.fetchQuestionTemplates();
      else this.handleInputFilter();
    },
    async fetchQuestionTemplates() {
      this.questionTemplateFilter.loading = true;
      try {
        this.questionTemplate = await fetchQuestionTemplates({
          organizationId: this.organizationId,
          page: this.questionTemplateFilter.page,
          pageSize: this.questionTemplateFilter.pageSize,
          typeQuestion: this.filters.typeQuestion,
          slug: this.filters.slug,
          name: this.filters.name,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.questionTemplateFilter.loading = false;
    },
    handleFilterChange(label, key, value) {
      const indexFilter = this.activeFilters.findIndex(
        (item) => item.name == key
      );
      const newValue = { label, name: key, value };
      if (!value | (value == "")) {
        // remove filter
        return this.activeFilters.splice(indexFilter, 1);
      }
      if (indexFilter != -1) {
        return (this.activeFilters[indexFilter].value = value);
      }
      return this.activeFilters.push(newValue);
    },
    async removeFilter(key) {
      this.filters[key] = null;
      await this.fetchQuestionTemplates();
      const indexFilter = this.activeFilters.findIndex(
        (item) => item.name == key
      );
      this.activeFilters.splice(indexFilter, 1);
    },
    handleInputFilter() {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        this.fetchQuestionTemplates();
      }, 500);
    },
    onPaginationChange(pagination) {
      this.questionTemplateFilter.page = pagination.page;
      this.questionTemplateFilter.pageSize =
        pagination.itemsPerPage < 0
          ? this.questionTemplate.count
          : pagination.itemsPerPage;
      this.fetchQuestionTemplates();
    },
    editQuestionTemplate(questionTemplate) {
      this.activeQuestionTemplate = questionTemplate;
      this.dialogState.createAndEditQuestionTemplate = true;
    },
    propagateQuestionTemplate(questionTemplate) {
      this.activeQuestionTemplate = questionTemplate;
      this.dialogState.propagateQuestionTemplate = true;
    },
    onQuestionTemplateCreated() {
      this.onCloseModal();
      this.questionTemplate.page = 1;
      this.fetchQuestionTemplates();
    },
    onQuestionTemplateEdited(editedQuestion) {
      this.questionTemplate.items = this.questionTemplate.items.map((qt) => {
        if (qt.id != editedQuestion.id) return qt;
        return editedQuestion;
      });
      this.onCloseModal();
    },
    onCloseModal() {
      this.dialogState.createAndEditQuestionTemplate = false;
      this.dialogState.propagateQuestionTemplate = false;
      this.activeQuestionTemplate = null;
    },
  },
};
</script>

<style scoped lang="scss">
.qt_container {
  padding: 0 8px;
  .header {
    margin-bottom: 32px;
    .header_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #353542 !important;
      margin-top: 8px;
      margin-bottom: 32px;
    }
  }
}
.tab_row {
  .name_section {
    padding: 16px 0;
    font-family: "Inter";
    font-style: normal;
    .question_label {
      color: #2536cc;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }
    .question_name {
      color: #707080;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
  .slug {
    color: #707080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
  .type_question {
    color: #707080;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
  .help_section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    .help_disable {
      opacity: 0.6;
    }
  }
  .action_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;
  }
}
.filter_search_section {
  padding: 10px 16px;
  background: white;
  position: relative;
  ::v-deep .v-text-field__details {
    display: none;
  }
  .progression {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .search_header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .filter_icon {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
      &::v-deep {
        .v-icon {
          color: #484964;
          width: 16px;
          height: 16px;
        }
      }
    }
    .header_label {
      color: #484964;
      font-family: "Inter";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .search_input {
    width: 100%;
    .input {
      color: #353542;
      font-family: "Inter";
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      padding: 6px 16px;
      border: 1px solid #000;
      border-radius: 5px;
      width: 100%;
      &:focus {
        border: 1px solid #2536cc;
        outline: 3px solid #ebf2ff;
      }
    }
    &::v-deep {
      .v-input__append-inner {
        display: none;
      }

      .v-input {
        color: #353542;
        font-family: "Inter";
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        &.v-input--is-focused {
          outline: 3px solid #ebf2ff;
        }
      }
    }
  }
}
.header_center {
  width: calc(100% - 417px);
  overflow: hidden;
}
.filters {
  display: flex;
  padding: 0 12px 12px 0px;
  align-items: center;
  .filter_icon {
    flex: none;
    background: #f4f7ff;
    border-radius: 9px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    &::v-deep {
      .v-icon {
        height: 20px;
        width: 20px;
        color: #242533;
      }
    }
  }

  .filter_item {
    border-radius: 36px;
    background: #f4f7ff;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    .filter_item_icon {
      width: 18px;
      height: 18px;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::v-deep {
        .v-icon {
          height: 18px;
          width: 18px;
          color: #707080;
        }
      }
    }
    .filter_item_label {
      color: #242533;
      font-family: "Inter";
      font-size: 12px;
      line-height: 20px;
      font-style: normal;
      font-weight: 500;
    }
    .close_icon {
      width: 18px;
      height: 18px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &::v-deep {
        .v-icon {
          height: 18px;
          width: 18px;
          color: #2536cc;
        }
      }
    }
  }
}
.header-col {
  color: #353542 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
.filtrable {
  cursor: pointer;
}
</style>
