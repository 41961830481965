<template>
  <div class="item_header" @click.stop="changeExpandStatus()">
    <div :class="isSpam ? ' icon_left is_spam' : 'icon_left'">
      <BaseIcon icon="$mdiLogin" class="icon" />
    </div>
    <div class="sections">
      <div class="section_top">
        <div class="section_left">
          <div class="section_title" v-text="cosmoformDetail.title" />
          <div
            class="section_content"
            v-text="
              $t('folder.createdAt', {
                date: $d(new Date(cosmoformDetail.dateCreated), 'veryshort'),
              })
            "
          />
          <div
            v-if="!cosmoformDetail.isExpired && lastRemindDate"
            class="section_content"
            v-text="
              $t('folder.lastRelaunched', {
                date: $d(new Date(lastRemindDate), 'veryshort'),
              })
            "
          />
        </div>
        <div class="section_center">
          <div class="section_title" v-text="$tc('utils.contact', 1)" />
          <div
            class="section_footer"
            :class="{ text_primary: cosmoformDetail.isExpired }"
            v-text="cosmoformDetail.email"
          />
        </div>
        <div class="section_right">
          <div class="chips" :class="cosmoformDetailStatus.chipsClass">
            {{ cosmoformDetailStatus.label }}
          </div>
        </div>
      </div>
      <div v-if="lastEmailStatus" class="email_status">
        <div class="email_status_label" v-text="$tc('utils.emailStatus', 1)" />
        <div class="email_status_chips" :class="lastEmailStatus.className">
          <div class="chips_icon">
            <BaseIcon small :icon="lastEmailStatus.icon" />
          </div>
          <div class="chips_label" v-text="lastEmailStatus.label" />
        </div>
      </div>
    </div>
    <div class="icon_right">
      <v-btn icon @click.stop="changeExpandStatus()">
        <BaseIcon :icon="expanded ? '$mdiChevronUp' : '$mdiChevronDown'" />
      </v-btn>
    </div>
  </div>
</template>

<script>
import { EMAIL_STATUS } from "@/core/Utils/types.utils";

export default {
  name: "FolderCosmoformItemHeader",
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    cosmoformDetail: {
      type: Object,
      required: true,
    },
    isSpam: {
      type: Boolean,
    },
  },

  computed: {
    cosmoformDetailStatus() {
      let cosmoformDetailStatus = {
        label: "En attente de renseignement",
        chipsClass: "",
      };
      if (this.cosmoformDetail.state == "CLOSED") {
        cosmoformDetailStatus.label = this.$t("cosmoform.closed");
      } else if (this.cosmoformDetail.isExpired) {
        cosmoformDetailStatus.label = this.$t("cosmoform.consent");
        cosmoformDetailStatus.chipsClass = "chips_success";
      }
      return cosmoformDetailStatus;
    },
    lastEmailStatus() {
      if (!this.cosmoformDetail.reminders?.length) return null;
      const emailStatus = this.cosmoformDetail.reminders[0].transactionalEmail;
      if (!emailStatus) return null;
      switch (emailStatus.status) {
        case EMAIL_STATUS.PENDING:
          emailStatus.label = this.$t("utils.pending");
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.IN_PROGRESS:
          emailStatus.label = this.$t("utils.inProgess");
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.SUCCESS:
          emailStatus.label = this.$t("utils.emailSent");
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.ERROR:
          emailStatus.label = this.$t("utils.error");
          emailStatus.icon = "$mdiEmailAlertOutline";
          emailStatus.className = "chips_danger";
          break;
        case EMAIL_STATUS.SENT:
          emailStatus.label = this.$t("utils.receivedEmail");
          emailStatus.icon = "$mdiEmailFastOutline";
          break;
        case EMAIL_STATUS.CLICKED:
          emailStatus.label = this.$t("utils.clicked");
          emailStatus.icon = "$mdiEmailOpenOutline";
          break;
        case EMAIL_STATUS.SPAM:
          emailStatus.label = this.$t("utils.spam");
          emailStatus.icon = "$emailSpamIcon";
          emailStatus.className = "chips_danger";

          break;
        case EMAIL_STATUS.OPEN:
          emailStatus.label = this.$t("utils.emailOpen");
          emailStatus.icon = "$mdiEmailOpenOutline";
          break;
        case EMAIL_STATUS.HARD_BOUNCE:
          emailStatus.label = this.$t("utils.hardBounce");
          emailStatus.icon = "$mdiEmailRemoveOutline";
          break;
        case EMAIL_STATUS.SOFT_BOUNCE:
          emailStatus.label = this.$t("utils.softBounce");
          emailStatus.icon = "$emailTemporary";
          break;
      }
      return emailStatus;
    },
    lastRemindDate() {
      if (
        !this.cosmoformDetail.reminders?.length ||
        this.cosmoformDetail.reminders.length == 1
      )
        return null;
      return this.cosmoformDetail.reminders[0].dateCreated;
    },
  },

  methods: {
    changeExpandStatus() {
      this.$emit("expandChange");
    },
  },
};
</script>

<style lang="scss" scoped>
.item_header {
  display: flex;
  cursor: pointer;
  .icon_left {
    width: 32px;
    background: #b6bdff;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is_spam {
      background: #ffc084;
    }
    & > .icon {
      &::v-deep {
        .v-icon {
          width: 16px;
          height: 16px;
          color: #fff;
        }
      }
    }
  }
  .sections {
    padding: 16px;
    flex: 1;
    .section_top {
      display: flex;
      align-items: center;
      .section_left {
        padding: 8px;
        padding-left: 16px;
        flex: 1;
        font-family: "Inter";
        font-style: normal;
        .section_title {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #4f4f4f;
        }
        .section_content {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
        }
      }
      .section_center {
        padding: 8px;
        flex: 1;
        .section_title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #707080;
        }
        .section_content {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #353542;
          margin-top: 6px;
        }
        .section_footer {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
          &.text_primary {
            color: #2536cc;
          }
        }
      }
      .section_right {
        width: 200px;
        flex: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        .chips {
          background: #eeeef7;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
          padding: 4px 8px;
          border-radius: 24px;
          &.chips_success {
            background: #f0ffd6;
            color: #18a300;
          }
          &.chips_danger {
            background: #fef9fa;
            color: #ff5267;
          }
        }
      }
    }
    .email_status {
      padding: 4px 16px;
      display: flex;
      align-items: center;
      .email_status_label {
        padding: 8px 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #353542;
      }
      .email_status_chips {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        background: #fcfcfc;
        border-radius: 4px;
        .chips_icon {
          padding: 0 4px;
          color: #cfcfdc;
          &::v-deep {
            .v-icon {
              color: #cfcfdc;
            }
          }
        }
        .chips_label {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          padding: 0 4px;
          color: #707080;
        }
        &.chips_danger {
          .chips_icon {
            padding: 0 4px;
            color: #cfcfdc;
            &::v-deep {
              .v-icon {
                color: #ff5267;
              }
            }
          }
          .chips_label {
            color: #ff5267;
          }
        }
      }
    }
  }
  .icon_right {
    flex: none;
    width: 76px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
