<template>
  <BaseDialog max-width="800" persistent :value="true">
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('automation.dialog.title')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              :disabled="isSubmiting"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <v-progress-linear
            v-if="isSubmiting"
            class="progression"
            indeterminate
          />
          <!-- SECTION ACTION CONFIGURATION -->
          <div class="input_group">
            <div class="input_label">
              <div
                class="label_content"
                v-text="$t('automation.dialog.action_configuration')"
              />
            </div>
            <div class="input_section">
              <div class="section_item">
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.type_of_action')"
                />
                <base-autocomplete
                  v-model="effect.action"
                  :items="effectItems"
                  :placeholder="$t('automation.dialog.choose_type_of_action')"
                  outlined
                  item-text="label"
                  item-value="value"
                  :disabled="isSubmiting"
                  clearable
                  :error-messages="effectActionError"
                  :has-error="!!effectActionError"
                  @change="handleEffectChange()"
                />
              </div>
              <!-- SET_STATUS  -->
              <div
                v-if="effect.action === AUTOMATION_EFFECT.SET_STATUS"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.target_status')"
                />
                <base-autocomplete
                  v-model="effect.content_id"
                  :items="workflowStatuses"
                  :placeholder="$t('automation.dialog.choose_target_status')"
                  outlined
                  item-text="name"
                  item-value="id"
                  :disabled="isSubmiting"
                  :error-messages="effectContentError"
                  :has-error="!!effectContentError"
                  clearable
                />
              </div>
              <!-- SEND_OFFER  -->
              <div
                v-if="effect.action === AUTOMATION_EFFECT.SEND_OFFER"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.offer_template')"
                />
                <base-autocomplete
                  v-model="effect.content_id"
                  :items="offerTemplates.items"
                  :placeholder="$t('automation.dialog.offer_template')"
                  outlined
                  item-text="title"
                  item-value="id"
                  :disabled="isSubmiting"
                  :error-messages="effectContentError"
                  :has-error="!!effectContentError"
                  :loading="fetchingOfferTemplate"
                  clearable
                />
              </div>
              <!-- SEND_FORM  -->
              <div
                v-if="effect.action === AUTOMATION_EFFECT.SEND_FORM"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.cosmoform_template')"
                />
                <base-autocomplete
                  v-model="effect.content_id"
                  :items="cosmoformTemplates"
                  :placeholder="$t('automation.dialog.cosmoform_template')"
                  outlined
                  item-text="title"
                  item-value="id"
                  :disabled="isSubmiting"
                  :error-messages="effectContentError"
                  :has-error="!!effectContentError"
                  :loading="fetchingCosmoformTemplate"
                  clearable
                />
              </div>
              <!-- SEND_TO_GED  -->
              <div
                v-if="effect.action === AUTOMATION_EFFECT.SEND_TO_GED"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.sendtoged_xml_template')"
                />
                <base-text-area
                  v-model="effect.action_config"
                  :placeholder="$t('automation.dialog.sendtoged_xml_template')"
                  outlined
                  :error-messages="actionConfigError"
                  :has-error="!!actionConfigError"
                />
              </div>
              <!-- GENERATE_DOC  -->
              <div
                v-if="effect.action === AUTOMATION_EFFECT.GENERATE_DOC"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('utils.chooseTemplate')"
                />
                <base-autocomplete
                  v-model="effect.content_id"
                  :items="documentTemplates"
                  :placeholder="$t('utils.chooseTemplate')"
                  outlined
                  item-text="name"
                  item-value="id"
                  :disabled="isSubmiting"
                  :error-messages="effectContentError"
                  :has-error="!!effectContentError"
                  :loading="fetchingDocumentTemplates"
                  clearable
                />
              </div>

              <!-- SEND_CAMPAIGN  -->
              <div
                v-if="effect.action === AUTOMATION_EFFECT.SEND_CAMPAIGN"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.choose_campaign')"
                />
                <base-autocomplete
                  key="effect"
                  v-model="effect.content_id"
                  :items="effectCampaigns.items"
                  :loading="effectCampaignFilter.loading"
                  :placeholder="$t('automation.dialog.choose_campaign')"
                  outlined
                  item-text="name"
                  item-value="id"
                  :disabled="isSubmiting"
                  clearable
                  :error-messages="effectContentError"
                  :has-error="!!effectContentError"
                  :filter="() => true"
                  :search-input.sync="effectCampaignFilter.name"
                />
              </div>
            </div>
          </div>
          <!-- SECTION TRIGER CONFIGURATION -->
          <div class="input_group">
            <div class="input_label">
              <div
                class="label_content"
                v-text="$t('automation.dialog.triger_configuration')"
              />
            </div>
            <div class="input_section">
              <div class="section_item">
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.trigger_type')"
                />
                <base-autocomplete
                  v-model="eventEffect.action"
                  :items="eventItems"
                  :placeholder="$t('automation.dialog.choose_trigger_type')"
                  outlined
                  item-text="label"
                  item-value="value"
                  :disabled="isSubmiting"
                  :error-messages="eventEffectActionError"
                  :has-error="!!eventEffectActionError"
                  clearable
                  @change="handleEventChange()"
                />
              </div>
              <!-- SET_STATUS  -->
              <div
                v-if="eventEffect.action === AUTOMATION_EVENT.STATUS_IS_SET"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.arrival_status')"
                />
                <base-autocomplete
                  v-model="eventEffect.content_id"
                  :items="workflowStatuses"
                  :placeholder="$t('automation.dialog.arrival_status')"
                  outlined
                  item-text="name"
                  item-value="id"
                  :disabled="isSubmiting"
                  :error-messages="eventEffectContentError"
                  :has-error="!!eventEffectContentError"
                  clearable
                />
              </div>
              <!-- ACTIVATE_IN -->
              <div
                v-else-if="eventEffect.action === AUTOMATION_EVENT.ACTIVATE_IN"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.event.activate_in')"
                />
                <BaseTextField
                  v-model="eventEffect.activate_in"
                  type="number"
                  :placeholder="$t('automation.event.activate_in')"
                  :error-messages="activeInError"
                  :has-error="!!activeInError"
                />
              </div>
              <!-- CAMPAIGN_ANSWERED  -->
              <div
                v-if="eventEffect.action === 'CAMPAIGN_ANSWERED'"
                class="section_item"
              >
                <div
                  class="section_label"
                  v-text="$t('automation.dialog.choose_campaign')"
                />
                <base-autocomplete
                  v-model="eventEffect.content_id"
                  :items="eventCampaignFiltered"
                  :loading="eventCampaignFilter.loading"
                  :placeholder="$t('automation.dialog.choose_campaign')"
                  outlined
                  item-text="name"
                  item-value="id"
                  :disabled="isSubmiting"
                  clearable
                  :error-messages="eventEffectContentError"
                  :has-error="!!eventEffectContentError"
                  :filter="() => true"
                  :search-input.sync="eventCampaignFilter.name"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :disabled="isSubmiting"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";
import OfferAPI from "@/modules/Folder/Services/offer.api";
import { getOrganizationCosmoformTemplate } from "@/modules/Admin/Services/workflow.service";
import { getOrganizationCampaigns } from "@/modules/Editor/Services/campaign.service";
import { AUTOMATION_EFFECT, AUTOMATION_EVENT } from "@/core/Utils/types.utils";
import OrganizationAPI from "@/core/Services/organization.api";

export default {
  name: "AutomationActionDialog",
  mixins: [validationMixin],
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
    hasEnoxa: {
      type: Boolean,
    },
    workflow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFetchingCampaign: true,
      isSubmiting: false,
      effectItems: [
        {
          label: this.$t("automation.effect.set_status"),
          value: AUTOMATION_EFFECT.SET_STATUS,
        },
        {
          label: this.$t("automation.effect.send_offer"),
          value: AUTOMATION_EFFECT.SEND_OFFER,
        },
        {
          label: this.$t("automation.effect.send_form"),
          value: AUTOMATION_EFFECT.SEND_FORM,
        },
        {
          label: this.$t("automation.effect.send_to_ged"),
          value: AUTOMATION_EFFECT.SEND_TO_GED,
          disabled: !this.hasEnoxa,
        },
        {
          label: this.$t("automation.effect.archive_folder"),
          value: AUTOMATION_EFFECT.ARCHIVE_FOLDER,
        },
        {
          label: this.$t("automation.effect.send_campaign"),
          value: AUTOMATION_EFFECT.SEND_CAMPAIGN,
        },
        {
          label: this.$t("automation.effect.generate_doc"),
          value: AUTOMATION_EFFECT.GENERATE_DOC,
        },
      ],
      eventItems: [
        {
          label: this.$t("automation.event.form_answered"),
          value: AUTOMATION_EVENT.FORM_ANSWERED,
        },
        {
          label: this.$t("automation.event.status_is_set"),
          value: AUTOMATION_EVENT.STATUS_IS_SET,
        },
        {
          label: this.$t("automation.event.create_folder"),
          value: AUTOMATION_EVENT.CREATE_FOLDER,
        },
        {
          label: this.$t("automation.event.campaign_answered"),
          value: AUTOMATION_EVENT.CAMPAIGN_ANSWERED,
        },
        {
          label: this.$t("automation.event.activate_in"),
          value: AUTOMATION_EVENT.ACTIVATE_IN,
        },
      ],
      effect: {
        action: null,
        content_id: null,
        action_config: null,
      },
      eventEffect: {
        action: null,
        content_id: null,
        activate_in: null,
      },
      offerTemplates: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      eventCampaigns: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      eventCampaignFilter: {
        loading: false,
        page: 1,
        pageSize: 100,
        name: undefined,
        status: "IN_PROGRESS",
        searchTimeoutRef: undefined,
      },
      // effect
      effectCampaignFilter: {
        loading: false,
        page: 1,
        pageSize: 100,
        name: undefined,
        status: "IN_PROGRESS",
        searchTimeoutRef: undefined,
      },
      effectCampaigns: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      fetchingOfferTemplate: false,
      fetchingCosmoformTemplate: false,
      cosmoformTemplates: [],
      documentTemplates: [],
      AUTOMATION_EFFECT: AUTOMATION_EFFECT,
      AUTOMATION_EVENT: AUTOMATION_EVENT,
    };
  },
  validations() {
    let validations = {};
    validations.effect = {
      action: {
        required,
      },
      content_id: {
        required: requiredIf(
          () =>
            ![
              AUTOMATION_EFFECT.ARCHIVE_FOLDER,
              AUTOMATION_EFFECT.SEND_TO_GED,
            ].includes(this.effect.action)
        ),
      },
      action_config: {
        required: requiredIf(
          () => this.effect.action == AUTOMATION_EFFECT.SEND_TO_GED
        ),
      },
    };
    validations.eventEffect = {
      action: {
        required,
      },
      content_id: {
        required: requiredIf(
          () =>
            this.eventEffect.action == AUTOMATION_EVENT.STATUS_IS_SET ||
            this.eventEffect.action == AUTOMATION_EVENT.CAMPAIGN_ANSWERED
        ),
      },
      activate_in: {
        required: requiredIf(
          () => this.eventEffect.action == AUTOMATION_EVENT.ACTIVATE_IN
        ),
        minValue: (value) => {
          if (this.eventEffect.action === AUTOMATION_EVENT.ACTIVATE_IN) {
            return value >= 0;
          }
          return true;
        },
      },
    };
    return validations;
  },
  computed: {
    workflowStatuses() {
      if (!this.workflow?.statuses) return [];
      return this.workflow.statuses;
    },
    eventCampaignFiltered() {
      if (this.effect.action === AUTOMATION_EFFECT.SEND_CAMPAIGN) {
        return this.eventCampaigns.items.filter(
          (campaign) => campaign.id !== this.effect.content_id
        );
      }
      return this.eventCampaigns.items;
    },
    effectActionError() {
      if (!this.$v.effect.action.$dirty) return "";
      if (!this.$v.effect.action.required)
        return this.$t("utils.fieldIsRequired");
      return "";
    },
    effectContentError() {
      if (!this.$v.effect.content_id.$dirty) return "";
      if (!this.$v.effect.content_id.required)
        return this.$t("utils.fieldIsRequired");
      return "";
    },
    actionConfigError() {
      if (!this.$v.effect.action_config.$dirty) return "";
      if (!this.$v.effect.action_config.required)
        return this.$t("utils.fieldIsRequired");
      return "";
    },
    eventEffectActionError() {
      if (!this.$v.eventEffect.action.$dirty) return "";
      if (!this.$v.eventEffect.action.required)
        return this.$t("utils.fieldIsRequired");
      return "";
    },
    eventEffectContentError() {
      if (!this.$v.eventEffect.content_id.$dirty) return "";
      if (!this.$v.eventEffect.content_id.required)
        return this.$t("utils.fieldIsRequired");
      return "";
    },
    activeInError() {
      if (!this.$v.eventEffect.activate_in.$dirty) return "";
      if (!this.$v.eventEffect.activate_in.required)
        return this.$t("utils.fieldIsRequired");
      if (!this.$v.eventEffect.activate_in.minValue)
        return this.$t("utils.must_be_positive_number");
      return "";
    },
  },
  watch: {
    "eventCampaignFilter.name"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterCampaigns();
    },
    "effectCampaignFilter.name"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterEffectCampaigns();
    },
  },
  methods: {
    reset() {
      if (this.isSubmiting) return;
      this.$emit("close");
    },

    async fecthOfferTemplates() {
      this.fetchingOfferTemplate = true;
      const response = await OfferAPI.getFolderOfferTemplate({
        organizationId: this.organizationId,
        params: {
          page: 1,
          offer_type: ["CONSULT", "VALIDATION"],
        },
      });
      this.offerTemplates = {
        ...response.data,
        items: response.data.results,
      };
      this.fetchingOfferTemplate = false;
    },
    async fetchCosmoformTemplates() {
      this.fetchingCosmoformTemplate = true;
      this.cosmoformTemplates = await getOrganizationCosmoformTemplate({
        organizationId: this.$route.params.organizationId,
      });
      this.fetchingCosmoformTemplate = false;
    },
    // campaign effect
    async fetchEffectCampaigns() {
      this.effectCampaignFilter.loading = true;
      try {
        this.effectCampaignFilter.page = 1;
        const res = await getOrganizationCampaigns({
          organizationId: this.organizationId,
          filters: this.effectCampaignFilter,
        });
        this.effectCampaigns = {
          ...res.data,
          items: [...res.data.results],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.effectCampaignFilter.loading = false;
    },
    filterEffectCampaigns() {
      if (this.effectCampaignFilter.searchTimeoutRef)
        clearTimeout(this.effectCampaignFilter.searchTimeoutRef);
      this.effectCampaignFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchEffectCampaigns();
      }, 500);
    },
    async fetchEventCampaigns() {
      this.eventCampaignFilter.loading = true;
      try {
        this.eventCampaignFilter.page = 1;
        const res = await getOrganizationCampaigns({
          organizationId: this.organizationId,
          filters: this.eventCampaignFilter,
        });
        this.eventCampaigns = {
          ...res.data,
          items: [...res.data.results],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.eventCampaignFilter.loading = false;
    },
    filterCampaigns() {
      if (this.eventCampaignFilter.searchTimeoutRef)
        clearTimeout(this.eventCampaignFilter.searchTimeoutRef);
      this.eventCampaignFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchEventCampaigns();
      }, 500);
    },

    async fetchDocumentTemplates() {
      this.fetchingDocumentTemplates = true;
      try {
        const res = await OrganizationAPI.getOrganizationDocumentTemplates(
          this.organizationId
        );
        this.documentTemplates = res.data.results;
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }

      this.fetchingDocumentTemplates = false;
    },
    async submit() {
      this.$v.effect.$touch();
      this.$v.eventEffect.$touch();
      if (this.$v.effect.$invalid || this.$v.eventEffect.$invalid) return;
      this.isSubmiting = true;
      try {
        const payload = new FormData();
        payload.append("action", this.effect.action);
        if (this.effect.content_id)
          payload.append("action_content_id", this.effect.content_id);
        payload.append("event", this.eventEffect.action);
        if (this.effect.action_config)
          payload.append(
            "action_config",
            JSON.stringify({ xml_pattern: this.effect.action_config })
          );

        if (this.eventEffect.content_id)
          payload.append("event_content_id", this.eventEffect.content_id);
        else if (this.eventEffect.activate_in)
          payload.append("activate_in", this.eventEffect.activate_in);
        await WorkflowAPI.postOrganizationWorkflowEffet({
          organizationId: this.organizationId,
          workflowId: this.workflow.id,
          payload: payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.snackbar.success.workflowModifiedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("workflow.snackbar.success.errorModifyingWorkflow"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
      this.$emit("effectCreated");
    },
    removeSelectedEffect(item) {
      this.eventEffect.content_id = this.eventEffect.content_id.filter(
        (tmp) => tmp != item.id
      );
    },
    async handleEffectChange() {
      this.effect.content_id = null;
      try {
        switch (this.effect.action) {
          case AUTOMATION_EFFECT.SEND_OFFER:
            if (!this.offerTemplates.items.length) this.fecthOfferTemplates();
            break;
          case AUTOMATION_EFFECT.SEND_FORM:
            if (!this.cosmoformTemplates.length) this.fetchCosmoformTemplates();
            break;
          case AUTOMATION_EFFECT.SEND_CAMPAIGN:
            if (!this.effectCampaigns.items.length) this.fetchEffectCampaigns();
            break;
          case AUTOMATION_EFFECT.GENERATE_DOC:
            if (!this.documentTemplates.length) this.fetchDocumentTemplates();
            break;
        }
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async handleEventChange() {
      this.eventEffect.content_id = null;
      if (this.eventEffect.action == AUTOMATION_EVENT.CAMPAIGN_ANSWERED)
        this.fetchEventCampaigns();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding-top: 24px;
  position: relative;
  .progression {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .input_group {
    .input_label {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 16px;
      .label_content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #353542;
      }
      .label_icon {
        width: 16px;
        height: 16px;
        position: relative;

        .icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
          ::v-deep {
            .v-icon {
              width: 16px;
              height: 16px;
            }
          }
        }
        &:hover {
          .tool_tip_container {
            transition: 125ms ease-in-out;
            display: flex;
          }
        }
        .tool_tip_container {
          display: none;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%, calc(-100% - 16px));
          width: 288px;
          padding: 6px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 10px;
          background: #666;

          .msg_container {
            position: relative;
            display: flex;
            align-items: center;
            .arrow_down {
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translate(-50%, 100%);
            }
          }
          .text__tooltip {
            color: #fff;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
    .input_section {
      padding: 0 16px;
      .section_item {
        .section_label {
          color: #707080;
          font-family: Inter;
          font-size: 14px;
          line-height: 18px;
          font-style: normal;
          font-weight: 400;
          margin-bottom: 4px;
        }
      }
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  gap: 16px;
}
.mail_chips {
  background: rgba(155, 189, 255, 0.2) !important;
  color: #2536cc !important;
  border-radius: 21px;
  ::v-deep .v-chip__close {
    color: #2536cc;
  }
}
</style>
