<template>
  <div class="drop_container">
    <div v-if="selectedFiles.length" class="files">
      <div v-for="(file, i) in selectedFiles" :key="i" class="file_item">
        <div class="file_name" v-text="file.name" />
        <div class="actions">
          <button type="button" class="icon_btn" @click="removeFile(i)">
            x
          </button>
        </div>
      </div>
    </div>
    <div
      class="drop_zone"
      :class="{ is_dragover: isDragOver }"
      @dragover.prevent.stop="onDragOver"
      @dragleave="onDragLeave"
      @drop.prevent.stop="handleDrop"
      @click="handleClick"
    >
      <div class="drop_img">
        <img :src="$findStatic('groupView')" />
      </div>
      <div class="drop_label" v-text="formatedLabel" />
      <div class="drop_label_btn" v-text="$t('browseFiles')" />
      <input
        v-show="false"
        ref="fileInput"
        type="file"
        :multiple="multiple"
        :accept="accept"
        @change="handleFileChange($event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseFileDrop",
  model: {
    prop: "modelValue",
    event: "changeValue",
  },
  props: {
    modelValue: {
      type: [Array, File],
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
    },
    accept: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isDragOver: false,
    };
  },
  computed: {
    formatedLabel() {
      if (this.label) return this.label;
      return this.$tc("dragDocuments", this.multiple ? 0 : 1);
    },
    selectedFiles() {
      if (!this.modelValue) return [];
      if (this.multiple) return this.modelValue;
      return [this.modelValue];
    },
  },
  methods: {
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    handleDrop(event) {
      this.isDragOver = false;
      if (!event.dataTransfer?.files?.length) return;
      const tmp = Array.from(event.dataTransfer.files).filter((f) =>
        this.isValidFile(f)
      );
      const files = this.multiple
        ? [...tmp, ...this.modelValue]
        : tmp.length
        ? tmp[0]
        : null;
      this.setFile(files);
    },
    handleFileChange(event) {
      if (!event.target.files?.length) return;
      const tmp = Array.from(event.target.files);
      const files = this.multiple
        ? [...tmp, ...this.modelValue]
        : tmp.length
        ? tmp[0]
        : null;
      this.setFile(files);
    },
    removeFile(index) {
      const tmp = [...this.selectedFiles];
      tmp.splice(index, 1);
      const files = this.multiple ? tmp : null;
      this.setFile(files);
    },
    setFile(value) {
      this.$emit("changeValue", value);
    },
    handleClick() {
      this.$refs.fileInput?.click();
    },
    isValidFile(file) {
      const acceptedTypes = this.accept.split(",").map((type) => type.trim());
      return acceptedTypes.some(
        (type) => file.type === type || file.name.endsWith(type)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.drop_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .files {
    width: 100%;
    .file_item {
      width: 100%;
      border: 1px solid #9bbdff;
      padding: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      transition: all 0.25s ease-in-out;
      &:last-child {
        margin: 0;
      }

      .file_name {
        color: #4f4f4f;
        font-family: "Poppins";
        font-size: 14px;
        line-height: 18px;
        width: calc(100% - 72px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.25s ease-in-out;
      }
      .actions {
        display: flex;
        align-items: center;
        margin-left: auto;
        .icon_btn {
          height: 20px;
          width: 20px;
          cursor: pointer;
          user-select: none;
          color: #474747;
          font-size: 18px;
          line-height: 20px;
          font-weight: bold;
        }
      }
    }
  }
  .drop_zone {
    border: 1px dashed #9bbdff;
    padding: 16px 32px;
    text-align: center;
    cursor: pointer;
    background: #fff;
    transition: all 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;
    &.is_dragover {
      background: rgba(155, 189, 255, 0.18);
    }
    &:hover {
      background: rgba(155, 189, 255, 0.18);
    }
    .drop_img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: #e9f1ff;
      img {
        margin: 4px;
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
    .drop_label {
      color: #353542;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
    .drop_label_btn {
      text-align: center;
      border-radius: 5px;
      border: 1px solid #eeeef7;
      background: #fff;
      padding: 8px 16px;
      color: #2536cc;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
</style>
