<template>
  <v-data-table :locale="'fr'" v-bind="$attrs" v-on="$listeners">
    <slot />
    <template #item="{ item }">
      <slot name="item" :item="item" />
    </template>
    <template #header="{ header }">
      <slot name="header" :header="header" />
    </template>
    <template #expanded-item="{ item }">
      <slot name="expanded-item" :item="item" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "BaseDataTable",
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header {
  th {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    color: #353542 !important;
  }
}
</style>
