<template>
  <div>
    <div class="bo_title mb-6" v-text="$t('organization.settings')" />
    <Board class="mb-6">
      <template slot="main">
        <v-tabs v-model="tab" color="primary" slider-color="primary">
          <v-tab>
            <div
              class="tab_label"
              v-text="$t('utils.collaboratorsManagement')"
            />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('organization.avatar.chatBot')" />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('organization.clientSpace')" />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('workflow.management')" />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('course.management')" />
          </v-tab>
          <v-tab>
            <div
              class="tab_label"
              v-text="$t('utils.managmentOfQuestionTemplate')"
            />
          </v-tab>
          <v-tab>
            <div class="tab_label" v-text="$t('utils.organizationDirectory')" />
          </v-tab>
          <v-tab>
            <div class="tab_label select_tab">
              {{ $t("utils.imports") }}
            </div>
            <v-menu
              :close-on-content-click="true"
              :nudge-left="80"
              :nudge-bottom="15"
              transition="scale-transition"
              offset-y
              bottom
              min-width="auto"
            >
              <template #activator="{ on }">
                <BaseIcon icon="$mdiMenuDown" v-on="on" />
              </template>
              <div class="item_container">
                <div
                  class="items"
                  :class="{ active: tab == 7 && selectedItem == 'contact' }"
                  @click="setSelectedItem('contact')"
                >
                  {{ $t("client.list") }}
                </div>
                <div
                  class="items"
                  :class="{ active: tab == 7 && selectedItem == 'dossiers' }"
                  @click="setSelectedItem('dossiers')"
                >
                  {{ $tc("organization.folder", 3) }}
                </div>
              </div>
            </v-menu>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item class="py-4 px-1">
            <OrganizationDashboardInvitations class="mt-2" />
            <BaseTitle
              class="mb-6"
              v-text="$tc('organization.collaborator', 2)"
            />
            <OrganizationDashboardCollaborators />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <AvatarEdition />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <ClientSpace />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <WorkflowList
              v-if="!workflowDetail"
              :organization-id="organizationId"
              @display-detail="workflowDetail = true"
            />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <editor-list :organization-id="organizationId" />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <QuestionTemplateList :organization-id="organizationId" />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <OrganizationSignatoryList :organization-id="organizationId" />
          </v-tab-item>
          <v-tab-item class="py-4 px-1">
            <component
              :is="importComponent"
              v-if="tab == 7"
              :organization-id="organizationId"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </Board>
    <router-view />
    <OrganizationInformationEditDialog />
  </div>
</template>

<script>
import Board from "@/core/Components/Board";
import WorkflowList from "../Components/WorkflowList";
import OrganizationSignatoryList from "../Components/OrganizationSignatoryList";
import QuestionTemplateList from "../Components/QuestionTemplateList";
import ContactImport from "../Components/ContactImport.vue";
import FolderImport from "../Components/FolderImport.vue";
import ClientSpace from "../Components/ClientSpace.vue";
import OrganizationInformationEditDialog from "../Components/OrganizationInformationEditDialog.vue";
import AvatarEdition from "../Components/AvatarEdition";
import OrganizationDashboardCollaborators from "@/core/Components/OrganizationDashboard/OrganizationDashboardCollaborators";
import OrganizationDashboardInvitations from "@/core/Components/OrganizationDashboard/OrganizationDashboardInvitations";
import EditorList from "../../Editor/Pages/EditorList.vue";

export default {
  name: "Admin",
  components: {
    Board,
    WorkflowList,
    ClientSpace,
    OrganizationInformationEditDialog,
    AvatarEdition,
    OrganizationSignatoryList,
    QuestionTemplateList,
    OrganizationDashboardCollaborators,
    OrganizationDashboardInvitations,
    EditorList,
    ContactImport,
    FolderImport,
  },
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tab: 0,
      workflowDetail: false,
      breadcrumbsLabel: [
        "utils.collaboratorsManagement",
        "organization.avatar.chatBot",
        "organization.clientSpace",
        "workflow.management",
        "course.management",
        "utils.managmentOfQuestionTemplate",
        "utils.organizationDirectory",
        "utils.imports",
        "utils.importFolder",
      ],
      selectedItem: "contact",
    };
  },
  computed: {
    importComponent() {
      return this.selectedItem === "contact" ? ContactImport : FolderImport;
    },
  },
  watch: {
    activeTab: {
      handler(newVal, oldVal) {
        if (
          (!isNaN(oldVal) && newVal) ||
          (!isNaN(newVal) && newVal !== oldVal)
        ) {
          this.tab = newVal;
        }
      },
      immediate: true,
    },
    tab: {
      handler(newVal, oldVal) {
        if (
          (!isNaN(oldVal) && newVal) ||
          (!isNaN(newVal) && newVal !== oldVal)
        ) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  methods: {
    setSelectedItem(item) {
      this.tab = 7;
      this.selectedItem = item;
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[2].label = this.breadcrumbsLabel[this.tab];
      this.$route.meta.breadcrumbs[2].params.activeTab =
        this.breadcrumbsLabel[this.tab];
    },
  },
};
</script>
<style lang="scss" scoped>
.item_container {
  display: flex;
  flex-direction: column;
  .items {
    height: 39px;
    width: 120px;
    padding: 12px 16px 12px 20px;
    color: var(--Principale-Fonc, #2536cc);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: white;
    cursor: pointer;
    &:hover {
      background: #f0f1fc;
    }
    &.active {
      background: #f0f1fc;
    }
  }
}
</style>
