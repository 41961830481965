import ClientAPI from "./client.api";
import { Import } from "@/core/Models/Import";

export function getClients(organizationId, page) {
  return ClientAPI.getOrganizationClients(organizationId, page);
}

export function getClientDetails(organizationId, clientId) {
  return ClientAPI.getClientDetails(organizationId, clientId);
}

export function getOrganizationClientRecords(
  organizationId,
  clientId,
  page = 1
) {
  return ClientAPI.getOrganizationClientRecords(organizationId, clientId, page);
}

export function askInformation(organizationId, clientId, payload) {
  return ClientAPI.askInformation(organizationId, clientId, payload);
}

export function fetchOrganizationClientLogs(organizationId, clientId) {
  return ClientAPI.getOrganizationClientLogs(organizationId, clientId);
}
export function postRelaunchInvitationRecordFromClient({
  organizationId,
  clientId,
  recordId,
  payload,
}) {
  return ClientAPI.postRelaunchInvitationRecordFromClient({
    organizationId,
    clientId,
    recordId,
    payload,
  });
}

export function postImportContact({ organizationId, file }) {
  const payload = new FormData();
  payload.append("file", file);
  return ClientAPI.postImportContact({ organizationId, payload });
}

export async function getImportContact({ organizationId, filters }) {
  const res = await ClientAPI.getImportContact({ organizationId, filters });
  return {
    count: res.data.count,
    value: res.data.results.map((d) => new Import(d)),
  };
}
