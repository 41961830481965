<template>
  <div class="item_container">
    <div class="info_section">
      <div class="effect_section">
        <span v-text="$t(automationEffect.effectLabel)" />
        <span
          v-if="automationEffect.effectExtraLabel"
          class="extra_label"
          v-text="` #${$t(automationEffect.effectExtraLabel)}`"
        />
      </div>
      <div class="event_section">
        <div class="event_label" v-text="$t(automationEffect.eventLabel)" />
        <div
          v-if="automationEffect.eventExtratLabel"
          class="extra_label"
          v-text="$t(automationEffect.eventExtratLabel)"
        />
      </div>
      <div v-if="automationEffectLogs.length" class="event_section">
        <div class="logs_section">
          <div
            v-for="log in automationEffectLogs"
            :key="log.id"
            class="log_item custom_tooltip"
          >
            <BaseIcon icon="$mdiCheck" color="white" small />

            <div class="tooltip_content">
              <div class="author">
                {{
                  log.author
                    ? $t("automation.started_by", { name: log.author })
                    : $t("automation.send_automatically")
                }}
              </div>

              <div class="date_created">
                {{
                  `${$t("utils.on")} ${$d(
                    new Date(log.dateCreated),
                    "veryshort"
                  )}`
                }}
              </div>
            </div>
          </div>
          <div v-if="automationEffect.logs.length > 5" class="log_count_rest">
            {{ `+${automationEffect.logs.length - 5}` }}
          </div>
        </div>
      </div>
    </div>
    <div class="left_section">
      <div class="action_section">
        <button
          v-if="!doingAction"
          :disabled="!!errorMessage"
          class="action_btn"
          :class="{
            custom_tooltip: !!errorMessage,
          }"
          @click="runEffect"
        >
          <div v-if="errorMessage" class="tooltip_content">
            {{ errorMessage }}
          </div>
          <BaseIcon class="btn_icon" icon="$mdiPlay" />
        </button>
        <v-progress-circular
          v-else
          :size="22"
          class="action_progress"
          indeterminate
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AutomationEffect } from "@/core/Models/AutomationEffect";
import { AUTOMATION_EFFECT } from "@/core/Utils/types.utils";
import FolderAPI from "@/modules/Folder/Services/folder.api";
export default {
  name: "FolderAutomationEffect",
  props: {
    automationEffect: {
      type: AutomationEffect,
      required: true,
    },
    hasEnoxa: {
      type: Boolean,
    },
  },
  data() {
    return {
      doingAction: false,
    };
  },
  computed: {
    ...mapState({
      activeFolder: (state) => state.folder.activeFolder,
    }),
    errorMessage() {
      switch (this.automationEffect.action) {
        case AUTOMATION_EFFECT.SEND_FORM:
        case AUTOMATION_EFFECT.SEND_OFFER:
        case AUTOMATION_EFFECT.SEND_CAMPAIGN:
          if (this.activeFolder?.client?.isTemporary)
            return this.$t("utils.require_assign_contact");
          break;
        case AUTOMATION_EFFECT.GENERATE_DOC:
          if (!this.automationEffect.actionExtra.destinationSlug)
            return this.$t("utils.no_destination_configured");
          break;
        case AUTOMATION_EFFECT.SEND_TO_GED:
          if (!this.hasEnoxa) return this.$t("utils.enoxa_required");
          break;
      }
      return "";
    },
    automationEffectLogs() {
      return this.automationEffect.logs.slice(0, 5);
    },
  },
  methods: {
    async runEffect() {
      if (this.errorMessage) return;
      this.doingAction = true;
      try {
        await FolderAPI.postFolderEffect({
          organizationId: this.$route.params.organizationId,
          folderId: this.$route.params.folderId,
          effectId: this.automationEffect.id,
        });
        switch (this.automationEffect.action) {
          case AUTOMATION_EFFECT.SEND_FORM:
          case AUTOMATION_EFFECT.SEND_OFFER:
          case AUTOMATION_EFFECT.SEND_CAMPAIGN:
            await this.$store.dispatch(
              "folder/getOrganizationOffersRecordFolder",
              {
                organizationId: this.$route.params.organizationId,
                folderId: this.$route.params.folderId,
                page: 1,
              }
            );
            break;
          case AUTOMATION_EFFECT.ARCHIVE_FOLDER:
          case AUTOMATION_EFFECT.SET_STATUS:
            await this.$store.dispatch("folder/fetchActiveFolder", {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
            });
            await this.$store.dispatch("folder/fetchClientOwnfolder", {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
            });
            break;
          case AUTOMATION_EFFECT.GENERATE_DOC:
            if (this.activeFolder.workflow) {
              this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", null);
              await this.$store.dispatch("workflow/fetchActiveWorkflow", {
                organizationId: this.$route.params.organizationId,
                workflowId: this.activeFolder.workflow,
              });
            }
            break;
        }
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.action_successfully_completed"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.$emit("refetch-folder-effect");
      this.doingAction = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.item_container {
  width: 100%;
  background: #fff;
  padding: 24px;
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 15, 144, 0.2),
    0px 5px 15px rgba(20, 20, 243, 0.1);
  .info_section {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .effect_section {
      color: #353542;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      .extra_label {
        color: #2536cc;
      }
    }
    .event_section {
      color: #707080;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 3px;
      .logs_section {
        display: flex;
        gap: 2px;
        align-items: center;
        .log_item {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #18a300;
          border-radius: 50%;
        }
        .custom_tooltip {
          position: relative;
          .tooltip_content {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(110px, calc(-100% - 20px));
            padding: 12px 16px;
            border-radius: 5px;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.25s;
            background-color: #707080;
            z-index: 10;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            text-align: start;
            .date_created {
              color: #b2b2b7;
            }
            .author {
              white-space: nowrap;
            }
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(2px, -21px);
            height: 0;
            width: 0;
            border: 12px solid transparent;
            border-top-color: #707080;
            z-index: 11;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.25s;
          }

          &:hover {
            .tooltip_content {
              opacity: 1;
            }
            &::before {
              opacity: 1;
            }
          }
        }
      }
      .event_label {
        line-height: 18px;
      }
      .extra_label {
        background: #707080;
        font-size: 10px;
        line-height: 12px;
        color: #fff;
        padding: 2px 6px;
        border-radius: 3px;
      }
    }
  }
  .left_section {
    margin-left: auto;
    display: flex;
    gap: 4px;
    align-items: flex-end;

    .action_section {
      display: flex;
      align-items: center;
      gap: 8px;
      .action_btn {
        width: 22px;
        height: 22px;
        border: 2px solid #707080;
        background: #fff;
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        flex: none;
        cursor: pointer;
        .btn_icon {
          ::v-deep {
            .v-icon__svg {
              width: 14px;
              height: 14px;
              color: #707080;
              flex: none;
            }
          }
        }
        &:disabled {
          border-color: #d9d9d9;
          .btn_icon {
            ::v-deep {
              .v-icon__svg {
                color: #d9d9d9;
              }
            }
          }
        }
      }
      .custom_tooltip {
        position: relative;
        .tooltip_content {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(22px, calc(-100% - 20px));
          padding: 12px 16px;
          border-radius: 5px;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.25s;
          background-color: #ff5267;
          z-index: 10;
          width: 200px;
          font-size: 14px;
          line-height: 18px;
          color: #fff;
          text-align: center;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(2px, -21px);
          height: 0;
          width: 0;
          border: 12px solid transparent;
          border-top-color: #ff5267;
          z-index: 11;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.25s;
        }

        &:hover {
          .tooltip_content {
            opacity: 1;
          }
          &::before {
            opacity: 1;
          }
        }
      }
      .action_progress {
        width: 22px;
        height: 22px;
        flex: none;
      }
    }
    .error_message {
      color: #ff5267;
      font-family: Inter;
      font-size: 12px;
      line-height: 18px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
</style>
