var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',_vm._g(_vm._b({class:{ focused: _vm.isFocused, 'error-input': _vm.hasError },attrs:{"outlined":"","dense":""},on:{"focusin":function($event){_vm.isFocused = true},"focusout":function($event){_vm.isFocused = false}},scopedSlots:_vm._u([(_vm.needSelection)?{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}:null,{key:"append-item",fn:function(){return [_vm._t("append-item")]},proxy:true},(_vm.displaySelection)?{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_vm._t("selection",null,{"attrs":attrs,"item":item,"select":select,"selected":selected})]}}:null],null,true)},'v-autocomplete',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }