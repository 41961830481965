<template>
  <div>
    <Board v-if="campaign" class="mb-6">
      <template #main>
        <div class="d-flex flex-column">
          <div class="d-flex justify-space-between">
            <div>
              <BaseTitle class="mb-2" v-text="campaign.name" />
              <router-link
                v-if="isManager"
                :to="{
                  name: 'courseDetail',
                  params: { courseId: course.id },
                }"
              >
                <div
                  class="link"
                  v-text="
                    $t('campaign.courseTitle', {
                      courseName: course.name,
                    })
                  "
                />
              </router-link>
              <BaseDescription
                v-else
                v-text="
                  $t('campaign.courseTitle', {
                    courseName: course.name,
                  })
                "
              />
            </div>
            <BaseChip
              :type="activeCampaignStatusType"
              v-text="activeCampaignStatusDisplay"
            />
          </div>
          <v-checkbox
            v-if="/^IN_PROGRESS$/.test(campaign.status)"
            v-model="isDisplayedInSpace"
            class="checkbox_campaign ml-auto"
            :label="`${$t('campaign.addToClientSpace')}`"
            @change="addToClientSpace"
          />
        </div>

        <div v-if="campaign.assign.email" class="d-flex align-center">
          <BaseAvatar
            class="mr-2"
            :size="32"
            :src="campaign.assign.avatar ? campaign.assign.avatar : null"
          />
          <BaseParagraph v-text="campaign.assign.email" />
        </div>
        <BaseDivider grey class="my-4" />
        <div>
          <div
            v-for="(descriptionLine, index) in campaign.description.split(/\n/)"
            v-show="campaign.description != ''"
            :key="index"
          >
            <BaseParagraph v-text="descriptionLine" />
            <br />
          </div>

          <BaseParagraph
            v-if="campaign.description == ''"
            italic
            v-text="$t('campaign.noDescription')"
          />
        </div>
        <BaseDivider
          v-if="!/^CLOSED$/.test(campaign.status)"
          grey
          class="my-4"
        />
        <div class="d-flex justify-space-between">
          <div class="d-flex justify-space-between">
            <BaseButton
              class="mr-2"
              type="primary"
              @click="$emit('open-create-campaign')"
            >
              {{ $t("utils.edit") }}
            </BaseButton>
            <BaseButton
              v-if="/^DRAFT$/.test(campaign.status)"
              type="secondary"
              color="secondary"
              @click="
                () => {
                  $store.commit('course/setCampaignDeleteDialog', true);
                }
              "
            >
              {{ $t("utils.delete") }}
            </BaseButton>
            <BaseButton
              v-if="/^IN_PROGRESS$/.test(campaign.status)"
              type="primary"
              @click="$emit('open-campaign-invite-dialog')"
            >
              {{ $t("campaign.invite") }}
            </BaseButton>
            <td v-if="campaign.autoReminder">
              <BaseButton
                color="secondary"
                class="btn-cancel ml-5"
                icon="$mdiCloseCircleOutline"
                @click="cancelReminder"
              >
                {{ $t("course.cancelRelaunch") }}
              </BaseButton>
            </td>
          </div>
          <div v-if="!/^CLOSED$/.test(campaign.status)" class="d-flex">
            <BaseButton
              v-if="/^DRAFT$/.test(campaign.status)"
              type="primary"
              @click="updateCampaignStatus('IN_PROGRESS')"
            >
              {{ $t("campaign.start") }}
            </BaseButton>
            <div v-if="/^IN_PROGRESS$/.test(campaign.status)">
              <BaseButton
                class="mr-2"
                type="primary"
                target="_blank"
                @click="anonymousCampaign"
              >
                {{ $t("campaign.answer") }}
              </BaseButton>
              <BaseButton
                class="mr-2"
                type="primary"
                @click="anonymousCampaign('copy_link')"
              >
                {{ $t("campaign.copyLink") }}
              </BaseButton>
              <BaseButton
                type="secondary"
                color="secondary"
                @click="updateCampaignStatus('CLOSED')"
              >
                {{ $t("campaign.stop") }}
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </Board>
    <Board v-else-if="isFetchingActiveCampaign" class="mb-6">
      <template #main>
        <BaseSkeletonLoader type="heading" class="mb-4" />
        <div class="d-flex align-center mb-4">
          <BaseSkeletonLoader type="avatar" class="mr-3" size="32px" />
          <BaseSkeletonLoader
            type="text"
            class="flex-grow-1"
            max-width="150px"
          />
        </div>
        <BaseSkeletonLoader type="paragraph" />
      </template>
    </Board>
    <div v-else class="mb-6">
      <BaseAlert color="secondary lighten-5">
        <BaseDescription
          bold
          color="secondary"
          v-text="$t('campaign.noCampaign')"
        />
      </BaseAlert>
    </div>
  </div>
</template>

<script>
import Board from "@/core/Components/Board";

import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { postCreateCopyCampaignAnonymousLink } from "@/modules/Editor/Services/campaign.service";

export default {
  name: "CampaignDetailHeader",
  components: {
    Board,
  },
  props: {
    isFetchingActiveCampaign: {
      type: Boolean,
      required: true,
    },
    course: {
      type: Object,
      default: () => {},
    },
    campaign: {
      type: Object,
      default: () => {},
      required,
    },
  },
  data() {
    return {
      isDisplayedInSpace: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      activeOrganization: (state) => state.organization.activeOrganization,
    }),
    isManager() {
      return this.user
        ? this.user.isManager(this.activeOrganization?.id)
        : false;
    },
    activeCampaignStatusDisplay() {
      switch (this.campaign.status) {
        case "DRAFT":
          return "Brouillon";
        case "IN_PROGRESS":
          return "En cours";
        case "CLOSED":
          return "Terminée";
        default:
          return null;
      }
    },
    activeCampaignStatusType() {
      switch (this.campaign.status) {
        case "DRAFT":
          return "tertiary";
        case "IN_PROGRESS":
          return "secondary";
        case "CLOSED":
          return "primary";
        default:
          return null;
      }
    },
    answerPath() {
      return `${process.env.VUE_APP_WALLET_URL}/wallet/campaign/${this.campaign.slug}/welcome/`;
    },
  },
  watch: {
    campaign: {
      handler() {
        this.isDisplayedInSpace = this.campaign?.published;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async anonymousCampaign(state) {
      try {
        if (state === "copy_link") {
          return await this.getLinkCampaignAnonymous();
        }
        return await this.answerCampaignAnonymous();
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async answerCampaignAnonymous() {
      const response = await postCreateCopyCampaignAnonymousLink({
        organizationId: this.$route.params.organizationId,
        campaignId: this.$route.params.campaignId,
        settings: {
          params: { set_user: "" },
        },
      });
      return open(`${process.env.VUE_APP_WALLET_URL}${response.data}`);
    },
    async getLinkCampaignAnonymous() {
      const response = await postCreateCopyCampaignAnonymousLink({
        organizationId: this.$route.params.organizationId,
        campaignId: this.$route.params.campaignId,
        settings: {},
      });
      this.$store.dispatch("snackbar/active", {
        message: this.$t("campaign.link_success"),
        type: "SUCCESS",
      });
      return navigator.clipboard.writeText(
        `${process.env.VUE_APP_WALLET_URL}${response.data}`
      );
    },
    async addToClientSpace() {
      const payload = { published: this.isDisplayedInSpace };
      try {
        await this.$store.dispatch("course/updateOrganizationCourseCampaign", {
          organizationId: this.$route.params.organizationId,
          campaignId: this.$route.params.campaignId,
          payload,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.addedSuccessFullyToClientSpace"),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.errorAddingToClientSpace"),
          type: "ERROR",
        });
      }
    },
    updateCampaignStatus(status) {
      const payload = {
        status,
      };
      this.$store
        .dispatch("course/updateOrganizationCourseCampaign", {
          organizationId: this.$route.params.organizationId,
          campaignId: this.$route.params.campaignId,
          payload,
        })
        .then(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: "Campagne commencée avec succès",
            type: "SUCCESS",
          });
        })
        .catch(() => {
          // Display TheSnackbar
          this.$store.dispatch("snackbar/active", {
            message: this.$t("utils.errorHasHappen"),
            type: "ERROR",
          });
        });
    },
    async cancelReminder() {
      const payload = {
        auto_reminder: false,
      };
      const res = await this.$store.dispatch(
        "course/updateOrganizationCourseCampaign",
        {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          campaignId: this.$route.params.campaignId,
          payload,
        }
      );
      res &&
        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.createAndEditDialog.success"),
          type: "SUCCESS",
        });
    },
  },
};
</script>
<style scoped>
::v-deep .checkbox_campaign .v-label {
  color: #2536cc;
}
.link {
  font-size: 12px;
  font-weight: 400;
  color: #2536cc;
  text-decoration: underline;
  cursor: pointer;
}
</style>
