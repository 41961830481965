import { render, staticRenderFns } from "./FolderChangeStatusDialog.vue?vue&type=template&id=ec59ccf2&scoped=true"
import script from "./FolderChangeStatusDialog.vue?vue&type=script&lang=js"
export * from "./FolderChangeStatusDialog.vue?vue&type=script&lang=js"
import style0 from "./FolderChangeStatusDialog.vue?vue&type=style&index=0&id=ec59ccf2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec59ccf2",
  null
  
)

export default component.exports