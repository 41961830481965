var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTitle',{staticClass:"mb-6",domProps:{"textContent":_vm._s(_vm.$t('workflow.management'))}}),(_vm.activeWorkflow)?_c('div',[_c('Board',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w_header"},[_c('BaseTitle',{domProps:{"textContent":_vm._s(_vm.activeWorkflow.name)}}),_c('BaseButton',{attrs:{"icon":"$mdiPencilOutline"},on:{"click":function($event){return _vm.$store.commit(
                'workflow/setModalCreateAndEditWorkflowDialog',
                true
              )}}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('utils.editName'))}})])],1)]},proxy:true},{key:"main",fn:function(){return [_c('BaseDivider',{staticClass:"my-5",attrs:{"grey":true}}),_c('div',{staticClass:"campaign_section"},[_c('div',{staticClass:"campaign_label"},[_c('span',{domProps:{"textContent":_vm._s(((_vm.$t('utils.automatedCampaign')) + " : "))}}),(_vm.activeWorkflow.campaign)?_c('router-link',{staticClass:"campaign_name",attrs:{"to":{
                name: 'campaignDetail',
                params: {
                  organizationId: _vm.organizationId,
                  campaignId: _vm.activeWorkflow.campaign.id,
                },
              }},domProps:{"textContent":_vm._s(_vm.activeWorkflow.campaign.name)}}):_vm._e()],1),_c('BaseButton',{attrs:{"icon":"$mdiPencilOutline"},on:{"click":function($event){_vm.openWorkflowCampaignDialog = true}}},[_c('span',{domProps:{"textContent":_vm._s(
                _vm.$t(_vm.activeWorkflow.campaign ? 'utils.edit' : 'utils.define')
              )}})])],1)]},proxy:true}],null,false,3018460886)}),(_vm.user && _vm.user.isStaff)?[_c('Board',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"w_header"},[_c('BaseTitle',{domProps:{"textContent":_vm._s(_vm.$t('automation.title'))}})],1)]},proxy:true},{key:"main",fn:function(){return [_c('BaseDivider',{staticClass:"my-5",attrs:{"grey":true}}),_c('div',{staticClass:"automation_section"},[_c('div',{staticClass:"action"},[_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){_vm.openAutomationAction = true}}},[_vm._v(" "+_vm._s(_vm.$t("automation.create_btn_label"))+" ")])],1)]),_c('div',{staticClass:"effect_list"},[_c('BaseDataTable',{attrs:{"items":_vm.effectDetails.items,"loading":_vm.effectFilter.loading,"options":{
                page: _vm.effectFilter.page,
                itemsPerPage: _vm.effectFilter.pageSize,
              },"server-items-length":_vm.effectDetails.count,"loading-text":_vm.$t('utils.loadingData')},on:{"update:options":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$tc("utils.action", 2))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("utils.trigger"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("utils.creationDate"))+" ")]),_c('th',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.$t("utils.delete"))+" ")])])])]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('tr',{staticClass:"tab_row"},[_c('td',[_c('div',{staticClass:"row_item"},[_c('div',{staticClass:"item_label",domProps:{"textContent":_vm._s(_vm.$t(item.effectLabel))}}),_c('div',{staticClass:"item_extra",domProps:{"textContent":_vm._s(item.effectExtraLabel)}})])]),_c('td',[_c('div',{staticClass:"row_item"},[_c('div',{staticClass:"item_label",domProps:{"textContent":_vm._s(_vm.$t(item.eventLabel))}}),_c('div',{staticClass:"item_extra",domProps:{"textContent":_vm._s(item.eventExtratLabel)}})])]),_c('td',[_c('div',{staticClass:"row_item"},[_c('div',{staticClass:"item_label",domProps:{"textContent":_vm._s(_vm.$d(new Date(item.dateCreated), 'veryshort'))}})])]),_c('td',[_c('div',{staticClass:"action_section"},[_c('BaseButtonIcon',{attrs:{"small":"","color":"secondary","icon":"$mdiTrashCanOutline"},on:{"click":function($event){return _vm.openRemoveEffectDialog(item)}}})],1)])])]}}],null,false,1041037511)})],1)]},proxy:true}],null,false,1141166566)})]:_vm._e(),_c('Board',{staticClass:"mb-6",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('BaseTitle',{domProps:{"textContent":_vm._s('Statuts')}}),_c('BaseButton',{attrs:{"icon":"$mdiPlus"},on:{"click":function($event){return _vm.$store.commit(
                'workflow/setModalCreateAndEditStatusDialog',
                true
              )}}},[_vm._v(" Créer un statut ")])],1)]},proxy:true},{key:"main",fn:function(){return [_vm._l((_vm.activeWorkflow.statuses),function(status,index){return _c('WorkflowDetailStatus',{key:index,attrs:{"index-status":index + 1,"status":status,"is-last-status":_vm.activeWorkflow.statuses.length - 1 === +index}})}),(_vm.activeWorkflow.statuses.length === 0)?_c('BaseAlert',{attrs:{"color":"secondary lighten-5 text-center"}},[_c('BaseParagraph',{attrs:{"bold":""},domProps:{"textContent":_vm._s(_vm.$t('workflow.status.noStatus'))}})],1):_vm._e()]},proxy:true}],null,false,3906462402)})],2):_vm._e(),_c('WorkflowCreateAndEditDialog'),_c('WorkflowCreateAndEditStatusDialog'),_c('WorkflowDeleteStatusDialog'),_c('WorkflowStatusQuestionCreateAndEditDialog'),_c('WorkflowStatusQuestionDeleteDialog'),_c('WorkflowStatusTargetStatusCreateAndEditDialog'),_c('WorkflowStatusTargetStatusDeleteDialog'),_c('WorkFlowCollaboratorsAddAndDelDialog'),_c('WorkflowRemoveStatusCategory'),_c('WorkflowEditStatusCategory'),(_vm.activeWorkflow && _vm.openWorkflowCampaignDialog)?_c('WorkflowCampaignDialog',{attrs:{"organization-id":_vm.organizationId,"workflow":_vm.activeWorkflow},on:{"close":function($event){_vm.openWorkflowCampaignDialog = false}}}):_vm._e(),(_vm.activeWorkflow && _vm.openAutomationAction)?_c('AutomationActionDialog',{attrs:{"organization-id":_vm.organizationId,"has-enoxa":_vm.activeOrganization.enoxa,"workflow":_vm.activeWorkflow},on:{"close":function($event){_vm.openAutomationAction = false},"effectCreated":_vm.onEffectCreated}}):_vm._e(),(_vm.activeWorkflow && _vm.removeEffectDialogState)?_c('AutomationRemoveDialog',{attrs:{"automation-effect":_vm.selectedEffect,"organization-id":_vm.organizationId,"workflow-id":_vm.activeWorkflow.id},on:{"close":function($event){_vm.removeEffectDialogState = false},"effectRemoved":_vm.onEffectRemoved}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }