<template>
  <div>
    <!-- IF ANSWERS -->
    <BaseRow v-if="record.isCompleted" no-gutters>
      <BaseCol lg="4" col="6" class="pr-10">
        <BaseListItemGroup :value="activeStep" color="primary">
          <BaseListItem
            v-for="(step, index) in record.steps"
            :key="index"
            class="my-2"
            @click="
              () => {
                activeStep = index;
              }
            "
          >
            <BaseListItemContent>
              <BaseListItemTitle>
                <BaseParagraph v-text="step.name" />
              </BaseListItemTitle>
            </BaseListItemContent>
          </BaseListItem>
        </BaseListItemGroup>
      </BaseCol>
      <BaseCol lg="8" col="6">
        <BaseSheet class="rounded-10" color="primary lighten-5">
          <div v-for="(step, index) in record.steps" :key="index">
            <BaseRow
              v-if="step.questions.some((question) => question.answer)"
              v-show="index == activeStep"
              no-gutters
            >
              <BaseCol
                v-for="(question, i) in step.questions.filter(
                  (question) => question.typeQuestion != 'MESSAGE'
                )"
                :key="i"
                lg="6"
                cols="12"
                class="pa-2"
              >
                <RecordAnswer
                  :answer="question.answer"
                  :type-question="question.typeQuestion"
                  :content="question.answer && question.answer.content"
                  :name="question.name"
                  :record-id="recordId"
                  v-on="$listeners"
                />
              </BaseCol>
            </BaseRow>
            <div v-else v-show="index == activeStep" class="pa-2">
              <BaseDescription italic v-text="$tc('answer.name', 0)" />
            </div>
          </div>
        </BaseSheet>
      </BaseCol>
    </BaseRow>
  </div>
</template>

<script>
import RecordAnswer from "./RecordAnswer";

export default {
  name: "Record",
  components: {
    RecordAnswer,
  },
  props: {
    recordId: {
      type: Number,
      required: true,
    },
    record: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {
      answers: null,
      activeStep: 0,
    };
  },
};
</script>
