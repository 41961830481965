import { render, staticRenderFns } from "./ClientCreateFolderDialog.vue?vue&type=template&id=40efd862&scoped=true"
import script from "./ClientCreateFolderDialog.vue?vue&type=script&lang=js"
export * from "./ClientCreateFolderDialog.vue?vue&type=script&lang=js"
import style0 from "./ClientCreateFolderDialog.vue?vue&type=style&index=0&id=40efd862&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40efd862",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
