<template>
  <BaseModal
    v-if="isOpen"
    :max-width="900"
    :value="isOpen"
    modal-title="Générer un document"
    @click:outside="close"
    @close="close"
  >
    <template #modal>
      <div class="modal-container">
        <div class="d-flex align-center">
          <BaseIcon
            icon="$mdiAlertCircleOutline"
            color="#FF5267"
            class="mr-1"
          />
          <span class="subtitle" v-text="$t('folder.missingFields')" />
        </div>
        <div class="subheader mt-3" v-text="$t('folder.generateDocxWarning')" />

        <div class="missing-container mt-3">
          <div
            v-for="(value, index) in valueMandatories"
            :key="index"
            class="missing-item mandatory mx-2"
            v-text="value"
          />
        </div>
        <div class="missing-container my-3">
          <span
            v-for="(value, index) in valueOptionals"
            :key="index"
            class="missing-item optional"
          >
            {{ value }}
          </span>
        </div>
      </div>
    </template>
    <template #actions>
      <BaseButton
        text
        class="ml-auto mx-2"
        type="secondary"
        color="#707080"
        @click.stop="close"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton type="primary" @click.prevent="generateDocx">
        {{ $t("folder.generateDocx") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import Oauth from "../../../core/Models/Oauth";
export default {
  name: "FolderGenerateDocDialog",
  props: {
    status: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
    },
    activeFolder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      missingValues: (state) => state.folder.missingValuesInGenerationDocx,
    }),
    valueMandatories() {
      if (!this.missingValues) return [];
      return this.missingValues.missing;
    },
    valueOptionals() {
      if (!this.missingValues) return [];
      return this.missingValues.missingOptionals;
    },
  },
  methods: {
    close() {
      this.$store.commit("folder/setModalGenerateDocxOpen", false);
    },
    async updateStatusQuestion() {
      await this.$store.dispatch("workflow/fetchFolderQuestions", {
        organizationId: this.$route.params.organizationId,
        workflowId: this.activeFolder.workflow,
        folderId: this.$route.params.folderId,
        statusId: this.status.id,
      });
    },

    async generateDocx() {
      this.$store.commit("snackbar/SET_OVERLAY", true);
      this.$store.dispatch("snackbar/active", {
        message: this.$t("folder.generatingDocument"),
        type: "LOADING",
      });
      try {
        const oauth = Oauth.localStorage;
        const myHeaders = new Headers({
          Authorization: `Bearer ${oauth.accessToken}`,
        });
        const payload = new FormData();
        payload.append("template", this.missingValues.template.id);
        payload.append("force", true);

        const myInit = {
          method: "POST",
          headers: myHeaders,
          body: payload,
        };

        const response = await fetch(
          `/api/web/organization/${this.$route.params.organizationId}/folder/${this.$route.params.folderId}/generate-document/`,
          myInit
        );
        saveAs(await response.blob(), `${this.missingValues.template.name}`);
        let msg = this.$tc("folder.documentGenerated", 1);
        if (
          this.missingValues.template.destination &&
          this.missingValues.template !== ""
        ) {
          // update question status and not download any file
          await this.updateStatusQuestion();
          msg = this.$tc("folder.documentGenerated", 2, {
            stdData: this.missingValues.template.destination,
          });
        }
        this.$store.dispatch("snackbar/active", {
          message: msg,
          type: "SUCCESS",
        });
        this.close();
      } catch (_) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.errorDocumentGenerated"),
          type: "ERROR",
        });
      }
      this.$store.commit("snackbar/SET_OVERLAY", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  font-family: "Inter";
  font-style: normal;
  line-height: 19px;
  font-weight: 400;
  .subtitle {
    font-size: 16px;
    color: #353542;
  }
  .subheader {
    font-size: 14px;
    line-height: 17px;
    color: #707080;
  }
  .missing-item {
    display: flex;
    align-content: center;
    margin: 0px 4px 4px;
    justify-content: top;
    padding: 6px 8px;
    gap: 16px;
    height: 31px;
    color: #707080;
    font-size: 16px;
  }
  .optional {
    border: 1px solid #b6bdff;
    border-radius: 5px;
  }
  .mandatory {
    border: 1px solid #ff5267;
    border-radius: 5px;
  }
  .missing-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
  }
}
</style>
