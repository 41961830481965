<template>
  <div
    class="record_item_container"
    :class="{ container_secondary: record.status == 'INVITED' }"
  >
    <FolderRecordItemHeader
      v-if="record"
      :record="record"
      :expanded="expanded"
      :is-spam="isSpam"
      :is-updated="isUpdated"
      @expandChange="changeExpandStatus()"
    />
    <template v-if="expanded">
      <div :class="classObject">
        <div class="record_detail_container">
          <div v-if="emailContent" class="email_content">
            <div class="title" v-text="$t('utils.emailContent')" />
            <div class="content">
              <BaseMarkdownViewer :content="emailContent" is-text-caption />
            </div>
          </div>
          <div v-if="!record.isCompleted" class="relaunch_container">
            <div class="relaunch_card">
              <div class="d-flex">
                <button
                  class="relaunch_btn"
                  :class="{ relaunched_btn: relaunchNumber > 0 }"
                  :disabled="isArchivedFolder"
                  @click="openRelaunchModal()"
                >
                  <BaseIcon class="icon" icon="$mdiEmailOutline" />
                  <span v-text="$t('course.relaunch')" />
                </button>
              </div>

              <div
                class="relaunch_detail"
                v-text="
                  $t('utils.relaunchNumber', {
                    value: relaunchNumber,
                  })
                "
              />
            </div>
          </div>
          <div class="separator" />
          <v-tabs v-model="activeTab" class="tab_header">
            <v-tab class="tab_header_item">
              {{ $t("utils.all") }}
            </v-tab>
            <v-tab
              v-for="(step, i) in record.steps"
              :key="`sh-${i}`"
              class="tab_header_item"
            >
              {{ step.name }}
            </v-tab>
          </v-tabs>
          <!-- ACTIONS -->
          <div class="record_actions">
            <v-menu offset-y left>
              <template #activator="{ on, attrs }">
                <v-btn
                  depressed
                  plain
                  class="question_filter"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-checkbox
                    :value="isCheck"
                    :true-value="true"
                    :false-value="false"
                    readonly
                    class="custom_checkbox"
                    color="primary"
                    hide-details
                    @click="checkedQuestions = []"
                  />
                  <BaseIcon icon="$mdiChevronDown" />
                </v-btn>
              </template>
              <div class="menu_list">
                <v-btn
                  x-large
                  block
                  text
                  class="menu_list_item"
                  :class="{
                    active: activeCheckQuestionType === CHECK_QUESTION_TYPE.ALL,
                  }"
                  @click="checkQuestion(CHECK_QUESTION_TYPE.ALL)"
                >
                  {{ $t("utils.allFields") }}
                </v-btn>
                <v-btn
                  x-large
                  block
                  text
                  class="menu_list_item"
                  :class="{
                    active:
                      activeCheckQuestionType ===
                      CHECK_QUESTION_TYPE.QUESTION_TYPE_FILE,
                  }"
                  @click="checkQuestion(CHECK_QUESTION_TYPE.QUESTION_TYPE_FILE)"
                >
                  {{ $t("utils.allFiles") }}
                </v-btn>
                <v-btn
                  x-large
                  block
                  text
                  class="menu_list_item"
                  :class="{
                    active:
                      activeCheckQuestionType === CHECK_QUESTION_TYPE.ANSWERED,
                  }"
                  @click="checkQuestion(CHECK_QUESTION_TYPE.ANSWERED)"
                >
                  {{ $t("utils.filledFields") }}
                </v-btn>
                <v-btn
                  x-large
                  block
                  text
                  class="menu_list_item"
                  :class="{
                    active:
                      activeCheckQuestionType ===
                      CHECK_QUESTION_TYPE.NOT_ANSWERED,
                  }"
                  @click="checkQuestion(CHECK_QUESTION_TYPE.NOT_ANSWERED)"
                >
                  {{ $t("utils.emptyFields") }}
                </v-btn>
              </div>
            </v-menu>
            <template v-if="checkedQuestions.length">
              <BaseButton
                :loading="activeState === COMPONENT_STATES.EXPORTING_RECORD"
                type="primary"
                icon="$mdiFolderZip"
                @click="downloadCheckedQuestionInZip()"
              >
                {{ $t("utils.downloadInZipFormat") }}
              </BaseButton>
              <BaseButton
                v-if="!recordAnonymous && displayInValidatedButton"
                color="primary"
                icon="$mdiPlaylistEdit"
                :disabled="isArchivedFolder"
                @click="openSendInvalidateAnswersDialog()"
              >
                {{ $t("utils.requestEditing") }}
              </BaseButton>
              <BaseButton
                v-if="!recordAnonymous && displayValidatedButton"
                :loading="activeState === COMPONENT_STATES.VALIDATE_QUESTION"
                color="primary"
                icon="$mdiCheck"
                :disabled="isArchivedFolder"
                @click="validateCheckedQuestion()"
              >
                {{ $t("utils.validate") }}
              </BaseButton>
            </template>
          </div>
          <v-tabs-items v-model="activeTab">
            <!-- ALL QUESTIONS -->
            <v-tab-item>
              <div class="tab_item">
                <div
                  v-for="(step, i) in record.steps"
                  :key="`as-${i}`"
                  class="step_questions"
                >
                  <div class="step_label" v-text="step.name" />
                  <div class="step_questions_grid">
                    <FolderPreviewQuestion
                      v-for="(question, qi) in step.questions.filter(
                        (q) => q.typeQuestion !== 'MESSAGE'
                      )"
                      :key="`q-${qi}`"
                      v-model="checkedQuestions"
                      :disabled="!question.answer"
                      :question="question"
                      @questionClick="openQuestionValidation"
                    />
                  </div>
                </div>
              </div>
            </v-tab-item>

            <!-- QUESTIONS BY STEP -->
            <v-tab-item v-for="(step, i) in record.steps" :key="`sti-${i}`">
              <div class="tab_item">
                <div class="step_questions">
                  <div class="step_questions_grid">
                    <FolderPreviewQuestion
                      v-for="(question, qi) in step.questions.filter(
                        (q) => q.typeQuestion !== 'MESSAGE'
                      )"
                      :key="`q-${qi}`"
                      v-model="checkedQuestions"
                      :disabled="!question.answer"
                      :question="question"
                      @questionClick="openQuestionValidation"
                    />
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </template>
    <!-- MODAL -->
    <FolderSendInvalidateAnswersDialog
      :dialog-state="activeState === COMPONENT_STATES.OPEN_INVALIDATE_QUESTION"
      :record="record"
      :questions="checkedQuestions"
      @close="reset()"
    />
  </div>
</template>

<script>
import FolderRecordItemHeader from "./FolderRecordItemHeader";
import FolderPreviewQuestion from "@/modules/Folder/Components/FolderPreviewQuestion";
import FolderSendInvalidateAnswersDialog from "@/modules/Folder/Components/FolderSendInvalidateAnswersDialog.vue";

import FolderAPI from "@/modules/Folder/Services/folder.api";

import { mapGetters } from "vuex";

export default {
  name: "FolderRecordItem",
  components: {
    FolderRecordItemHeader,
    FolderPreviewQuestion,
    FolderSendInvalidateAnswersDialog,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
    folderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
      activeTab: 1,
      checkedQuestions: [],
      CHECK_QUESTION_TYPE: {
        ALL: "ALL",
        ANSWERED: "ANSWERED",
        NOT_ANSWERED: "NOT_ANSWERED",
        QUESTION_TYPE_FILE: "QUESTION_TYPE_FILE",
        NULL: "NULL",
      },
      COMPONENT_STATES: {
        DEFAULT: "DEFAULT",
        EXPORTING_RECORD: "EXPORTING_RECORD",
        VALIDATE_QUESTION: "VALIDATE_QUESTION",
        OPEN_INVALIDATE_QUESTION: "OPEN_INVALIDATE_QUESTION",
      },
      activeState: null,
      isUpdated: false,
    };
  },

  computed: {
    ...mapGetters({ isArchivedFolder: "folder/isArchivedFolder" }),
    classObject() {
      const clas = ["item_body"];
      if (this.isSpam) clas.push("is_spam");
      if (this.isUpdated) clas.push("updated");
      switch (this.record.validationStatus) {
        case "UPDATE_REQUESTED":
          clas.push("need_update");
          break;
        case "UPDATED":
          clas.push("updated");
          break;
        case "VALIDATED":
          clas.push("validated");
          break;
        default:
          "";
          break;
      }
      return clas;
    },
    activeCheckQuestionType() {
      if (this.checkedQuestions.length === 0) {
        return null;
      }
      if (
        this.allQuestion().length &&
        this.allQuestion().every((q) =>
          this.checkedQuestions.find((cq) => q.id === cq.id)
        )
      ) {
        return this.CHECK_QUESTION_TYPE.ALL;
      }
      if (
        this.questionTypeFile().length &&
        this.questionTypeFile().every((q) =>
          this.checkedQuestions.find((cq) => q.id === cq.id)
        )
      ) {
        return this.CHECK_QUESTION_TYPE.QUESTION_TYPE_FILE;
      }
      if (
        this.answeredQuestion().length &&
        this.answeredQuestion().every((q) =>
          this.checkedQuestions.find((cq) => q.id === cq.id)
        )
      ) {
        return this.CHECK_QUESTION_TYPE.ANSWERED;
      }
      if (
        this.notAnswerQuestion().length &&
        this.notAnswerQuestion().every((q) =>
          this.checkedQuestions.find((cq) => q.id === cq.id)
        )
      ) {
        return this.CHECK_QUESTION_TYPE.NOT_ANSWERED;
      }
      return this.CHECK_QUESTION_TYPE.NULL;
    },
    isCheck() {
      if (
        !this.activeCheckQuestionType ||
        this.activeCheckQuestionType === this.CHECK_QUESTION_TYPE.NULL
      )
        return false;
      return true;
    },
    recordAnonymous() {
      return this.record.course.anonymous;
    },
    relaunchNumber() {
      return this.record?.reminders?.length
        ? this.record.reminders.length - 1
        : 0;
    },
    isSpam() {
      if (!this.record.reminders[0]?.transactionalEmail) return;
      const { status } = this.record?.reminders[0]?.transactionalEmail;
      return status === "SPAM";
    },
    displayValidatedButton() {
      return this.checkedQuestions.every(
        (question) =>
          question.validation !== "VALIDATED" &&
          question.validation !== "UPDATE_REQUESTED"
      );
    },
    displayInValidatedButton() {
      return !this.checkedQuestions.some(
        (question) =>
          question.validation === "VALIDATED" ||
          question.validation === "UPDATE_REQUESTED"
      );
    },
    emailContent() {
      if (!this.record.reminders?.length) return "";
      return this.record.reminders[0].transactionalEmail.emailContent;
    },
  },
  mounted() {
    if (!this.record.steps) return;
    this.record.steps.forEach((step) => {
      this.isUpdated = step.questions.some(
        (question) => question.validation === "UPDATED"
      );
    });
  },
  methods: {
    changeExpandStatus() {
      this.expanded = !this.expanded;
    },
    reset() {
      this.checkedQuestions = [];
      this.activeState = this.COMPONENT_STATES.DEFAULT;
    },
    checkQuestion(checkQuestionType) {
      switch (checkQuestionType) {
        case this.activeCheckQuestionType:
          this.checkedQuestions = [];
          break;
        case this.CHECK_QUESTION_TYPE.ALL:
          this.checkedQuestions = this.allQuestion();

          break;
        case this.CHECK_QUESTION_TYPE.QUESTION_TYPE_FILE:
          this.checkedQuestions = this.questionTypeFile();
          break;
        case this.CHECK_QUESTION_TYPE.ANSWERED:
          this.checkedQuestions = this.answeredQuestion();
          break;
        case this.CHECK_QUESTION_TYPE.NOT_ANSWERED:
          this.checkedQuestions = this.notAnswerQuestion();
          break;
      }
    },
    allQuestion() {
      let tmpQuestions = [];
      this.record.steps.forEach((step) => {
        tmpQuestions = [...tmpQuestions, ...step.questions];
      });
      return tmpQuestions;
    },
    questionTypeFile() {
      let tmpQuestions = [];
      this.record.steps.forEach((step) => {
        step.questions.forEach((q) => {
          if (["FILE", "MULTIPLE_FILE"].includes(q.typeQuestion))
            tmpQuestions.push(q);
        });
      });
      return tmpQuestions;
    },
    answeredQuestion() {
      let tmpQuestions = [];
      this.record.steps.forEach((step) => {
        step.questions.forEach((q) => {
          if (q.answer?.content) tmpQuestions.push(q);
        });
      });
      return tmpQuestions;
    },
    notAnswerQuestion() {
      let tmpQuestions = [];
      this.record.steps.forEach((step) => {
        step.questions.forEach((q) => {
          if (!q.answer || !q.answer.content) tmpQuestions.push(q);
        });
      });
      return tmpQuestions;
    },
    async downloadCheckedQuestionInZip() {
      this.activeState = this.COMPONENT_STATES.EXPORTING_RECORD;
      const fileUrl = (
        await FolderAPI.postFolderExport({
          organizationId: this.organizationId,
          folderId: this.folderId,
          payload: {
            import_data: this.checkedQuestions
              .filter((q) => !!q.answer)
              .map((q) => q.answer.id),
          },
        })
      ).data.exportFile;
      const downloadLink = document.createElement("a");
      downloadLink.target = "_blank";
      downloadLink.href = fileUrl;
      downloadLink.download = "";
      downloadLink.click();
      this.reset();
    },
    openSendInvalidateAnswersDialog() {
      this.activeState = this.COMPONENT_STATES.OPEN_INVALIDATE_QUESTION;
    },
    async validateCheckedQuestion() {
      this.activeState = this.COMPONENT_STATES.VALIDATE_QUESTION;
      try {
        await this.$store.dispatch("folder/validateRecordAnswers", {
          organizationId: this.organizationId,
          folderId: this.folderId,
          recordId: this.record?.id,
          questions: this.checkedQuestions,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.validationForm.success"),
          type: "SUCCESS",
        });
      } catch (e) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("folder.validationForm.error"),
          type: "ERROR",
        });
      }
      this.reset();
    },
    openRelaunchModal() {
      this.$store.commit("course/SET_RECORD_TO_RELAUNCH", this.record);
      this.$store.commit("folder/SET_RELAUNCH_DIALOG_OPEN", true);
    },
    openQuestionValidation(questions) {
      this.$store.commit("folder/SET_ACTIVE_RECORD", this.record);
      this.$store.commit("folder/SET_ACTIVE_QUESTION", questions[0]);
      this.$store.commit("folder/SET_MODAL_VALIDATION_DIALOG", true);
    },
    async cancelReminder() {
      const { campaign, course } = this.record;
      const payload = {
        auto_reminder: false,
      };
      const res = await this.$store.dispatch(
        "course/updateOrganizationCourseCampaign",
        {
          organizationId: this.$route.params.organizationId,
          courseId: course.id,
          campaignId: campaign.id,
          payload,
        }
      );
      res &&
        this.$store.dispatch("snackbar/active", {
          message: this.$t("campaign.createAndEditDialog.success"),
          type: "SUCCESS",
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.record_item_container {
  background: #fff;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px #cfcfdc, 0px 0px 1px rgba(37, 54, 204, 0.2);
  overflow: hidden;
  &.container_secondary {
    background: #f5f5f5;
  }
  // BODY

  .item_body {
    border-left: 32px solid #b6bdff;
    &.is_spam {
      border-left: 32px solid #ffc084;
    }
    &.AGREE {
      border-left: 32px solid #18a300;
    }
    &.DISAGREE {
      background: #ff5267;
    }
    .separator {
      width: calc(100% - 80px);
      margin: auto;
      height: 1px;
      background: #eeeef7;
    }
    .relaunch_container {
      margin-top: 24px;
      .relaunch_card {
        background: #fafafc;
        border-radius: 5px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .relaunch_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          background: #f4f7ff;
          border-radius: 5px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #2536cc;
          & > .icon {
            &::v-deep {
              .v-icon {
                width: 16px;
                height: 16px;
                color: #2536cc;
              }
            }
          }
          & > * {
            margin: 0 4px;
          }
        }
        .relaunched_btn {
          color: #ff6b00;
          background: #fff3f0;
          & > .icon {
            &::v-deep {
              .v-icon {
                color: #ff6b00;
              }
            }
          }
        }
        .relaunch_detail {
          margin-top: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
        }
      }
    }
    .record_detail_container {
      padding: 24px 32px;
      .tab_header {
        margin-top: 24px;
        .tab_header_item {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .record_actions {
        display: flex;
        align-items: center;
        padding: 16px 0;
        & > * {
          margin: 0 8px;
        }
        .question_filter {
          margin: 0;
          margin-right: auto;
          padding: 0 6px !important;
          display: flex;
          align-items: center;
          .label {
            margin-right: 4px;
          }
          ::v-deep {
            .custom_checkbox.v-input--checkbox {
              margin: 0 !important;
            }
          }
        }
      }
      .tab_item {
        padding: 5px;
        .step_questions {
          width: 100%;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 8px;
          }
          .step_label {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 16px;
            color: #353542;
          }
          .step_questions_grid {
            display: grid;
            padding-right: 8px;
            padding-bottom: 4px;
            grid-column-gap: 16px;
            grid-row-gap: 16px;
            grid-template-columns: 100%;
            max-height: 248px;
            overflow-y: auto;
            overflow-x: hidden;
            @media (min-width: 1200px) {
              grid-template-columns: repeat(2, calc(calc(100% - 16px) / 2));
            }
            @media (min-width: 1400px) {
              grid-template-columns: repeat(3, calc(calc(100% - 32px) / 3));
            }
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-track {
              background: #fff;
              border-radius: 3px;
              border: 1px solid #eeeef7;
            }

            &::-webkit-scrollbar-thumb {
              background: #b6bdff;
              border-radius: 3px;
            }
          }
        }
      }
      .email_content {
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #353542;
          margin-bottom: 16px;
        }
        .content {
          background: #ffffff;
          padding: 24px;
          border-radius: 5px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #353542;
          border: 0.5px solid #cfcfdc;
        }
      }
    }
  }
}
.need_update {
  border-left: 32px solid #ff5b60 !important;
}
.validated {
  border-left: 32px solid #18a300 !important;
}
.updated {
  border-left: 32px solid rgb(255, 171, 90) !important;
}
.menu_list {
  background: #fff;
  .menu_list_item {
    display: flex;
    justify-content: flex-start;
    &.active {
      background: #eeeef7;
    }
    &::v-deep {
      .label {
        font-size: 14px;
      }
    }
  }
}
</style>
