<template>
  <div class="item_header" @click="changeExpandStatus()">
    <div class="icon_left">
      <BaseIcon icon="$mdiLogout" class="icon" />
    </div>
    <div class="sections">
      <div class="section_left">
        <div class="section_info">
          <div class="section_title" v-text="email.subject" />
          <div
            class="section_content"
            v-text="
              $t('folder.createdAt', {
                date: $d(new Date(email.dateCreated), 'veryshort'),
              })
            "
          />
        </div>
      </div>
      <div class="section_center">
        <div style="margin-left: 45px">
          <div class="section_title" v-text="'Destinataire'" />
          <template v-if="emailClient.length">
            <div class="section_footer" v-text="email.to" />
          </template>
        </div>
      </div>
      <div class="section_right">
        <div class="chips" :class="getStatusClass">{{ getStatus }}</div>
      </div>
    </div>
    <div class="icon_right">
      <v-btn icon @click.stop="changeExpandStatus()">
        <BaseIcon :icon="expanded ? '$mdiChevronUp' : '$mdiChevronDown'" />
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "FolderEmailItemHeader",
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    email: {
      type: Object,
      required: true,
    },
    emailClient: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getStatus() {
      let status = this.email.status;

      if (status === "PENDING") return "En cours";
      if (status === "SPAM") return "Error";
      if (status === "OPEN") return "Ouvert";

      return "";
    },
    getStatusClass() {
      let status = this.email.status;
      if (status === "OPEN") return "chips_success";
      else if (status === "SPAM" || status === "ERROR") return "chips_danger";
      else return "";
    },
  },
  methods: {
    changeExpandStatus() {
      this.$emit("expandChange");
    },
  },
};
</script>

<style lang="scss" scoped>
.item_header {
  display: flex;
  cursor: pointer;
  .icon_left {
    width: 32px;
    background: #bda0ed;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    & > .icon {
      &::v-deep {
        .c_icon {
          width: auto;
          height: 14px;
          color: #fff;
        }
        .v-icon {
          width: 14px;
          height: 14px;
          color: #fff;
        }
      }
    }
  }
  .sections {
    padding: 16px;
    display: flex;
    align-items: center;
    flex: 1;
    .section_left {
      padding: 8px;
      padding-left: 16px;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .section_info {
        font-family: "Inter";
        font-style: normal;
        flex: 1;
        .section_title {
          font-weight: 600;
          font-size: 24px;
          line-height: 28px;
          color: #4f4f4f;
        }
        .section_content {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #707080;
          margin-top: 6px;
        }
      }
    }
    .section_center {
      padding: 8px;
      flex: 1;
      .section_title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #707080;
      }
      .section_content {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #353542;
        margin-top: 6px;
      }
      .section_footer {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #2536cc;
        margin-top: 6px;
      }
    }
    .section_right {
      width: 200px;
      flex: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .chips {
        background: #f2ebff;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #702dde;
        padding: 4px 8px;
        border-radius: 4px;
        &.chips_success {
          background: #f0ffd6;
          color: #18a300;
        }
        &.chips_danger {
          background: #fef9fa;
          color: #ff5267;
        }
      }
    }
  }
  .icon_right {
    flex: none;
    width: 76px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

</style>
