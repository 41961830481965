<template>
  <div
    class="preview_container"
    :class="{
      selectable: selectable,
      disable: disabled,
      checked: isCheck,
      expanded: expanded,
      loading: loading,
      update__form: updateNeeded,
      validated__form: validated,
      updated__form: updated,
    }"
    @click="$emit('questionClick', [question])"
  >
    <div class="left_section">
      <template v-if="loading">
        <div class="question_label">
          <v-skeleton-loader class="skeleton" type="text" />
        </div>
        <div class="question_answer">
          <div class="no_content" />
        </div>
      </template>
      <template v-else-if="question">
        <div class="check_btn" @click.stop>
          <v-checkbox v-model="isCheck" color="primary" hide-details />
        </div>
        <div class="question_label" v-text="question.name" />
        <div class="question_answer">
          <template
            v-if="
              content && /DATAGRID|ROW_DATAGRID/.test(question.typeQuestion)
            "
          >
            <span class="link_content" v-text="$t('utils.goTable')" />
          </template>
          <template
            v-else-if="
              content && !/DATAGRID|ROW_DATAGRID/.test(question.typeQuestion)
            "
          >
            <span
              v-if="question.typeQuestion === 'MULTIPLE_FILE'"
              class="link_content"
              v-text="`Voir ${content.length} fichier(s)`"
            />
            <span
              v-else-if="
                question.typeQuestion !== 'MULTIPLE_FILE' &&
                /FILE|SIGNATURE/.test(question.typeQuestion)
              "
              class="link_content"
              v-text="$t('folder.records.seeFile')"
            />

            <div v-else class="text_content" v-text="content" />
          </template>
          <div v-else class="no_content" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ANSWER_VALIDATION_LEVEL } from "@/core/Utils/types.utils";

export default {
  name: "FolderPreviewQuestion",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    question: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isCheck: false,
    };
  },
  computed: {
    content() {
      if (!this.question.answer) return null;
      const tmpAnswer = Array.isArray(this.question.answer)
        ? this.question.answer[this.question.answer.length - 1]
        : this.question.answer;
      switch (this.question.typeQuestion) {
        case "RADIOBUTTON":
          if (
            this.question.hasFreeOption &&
            tmpAnswer?.choices[0]?.name.includes("Autre")
          )
            return tmpAnswer.freeOptionContent;
          else return tmpAnswer.content?.toString();
        case "DATE":
          return tmpAnswer.content
            ? this.$d(new Date(tmpAnswer.content), "veryshort")
            : null;
        case "DATETIME":
          return tmpAnswer.content
            ? this.$d(new Date(tmpAnswer.content), "full")
            : null;
        case "DATAGRID":
        case "ROW_DATAGRID":
          return tmpAnswer.content ? tmpAnswer.content : null;
        case "FILE":
          return tmpAnswer?.content?.length && tmpAnswer?.content[0]?.url;
        case "SIGNATURE":
          return tmpAnswer?.content?.length && tmpAnswer?.content[0]?.url;
        case "MULTIPLE_FILE":
          return tmpAnswer.content;

        default:
          return tmpAnswer.content?.toString();
      }
    },
    updateNeeded() {
      if (!this.question.answer) return;
      return (
        this.question.validation === "UPDATE_REQUESTED" ||
        this.question.answer.validationLevel ==
          ANSWER_VALIDATION_LEVEL.INVALIDATED
      );
    },
    validated() {
      if (!this.question.answer) return;
      return (
        this.question.validation === "VALIDATED" ||
        this.question.answer.validationLevel ==
          ANSWER_VALIDATION_LEVEL.VALIDATED
      );
    },
    updated() {
      if (!this.question.answer) return;
      return this.question.validation === "UPDATED";
    },
  },

  watch: {
    value(questions) {
      if (questions.some((q) => q.id === this.question.id)) {
        this.isCheck = true;
      } else {
        this.isCheck = false;
      }
    },
    isCheck(checked) {
      this.updateValue(checked);
    },
  },
  methods: {
    openFiles() {
      this.content.forEach((file) => {
        open(file.url);
      });
    },
    updateValue(checked) {
      let value = this.value ? [...this.value] : [];
      if (checked) {
        if (!value.some((q) => q.id === this.question.id)) {
          value.push(this.question);
        } else {
          value = value.map((q) =>
            q.id === this.question.id ? this.question : q
          );
        }
      } else if (!checked) {
        value = value.filter((v) => v.id !== this.question.id);
      }
      this.$emit(
        "input",
        value.sort((a, b) => a.order - b.order)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.update__form {
  border-left: 8px solid #ff5b60 !important;

  &.selectable:hover {
    border-left: 24px solid #ff5b60 !important;
    background: #fcd6d6 !important;
  }

  &.checked {
    border-left: 24px solid #ff5b60 !important;
  }
  &.expanded {
    border-left: 24px solid #ff5b60 !important;
  }
  .question_answer {
    color: #ff5b60 !important;
    .no_content {
      background: #ff5b60 !important;
    }
  }
}
.validated__form {
  border-left: 8px solid #18a300 !important;

  &.selectable:hover {
    border-left: 24px solid #18a300 !important;
    background: #f0ffd6 !important;
  }

  &.checked {
    border-left: 24px solid #18a300 !important;
  }
  &.expanded {
    border-left: 24px solid #18a300 !important;
  }
  .question_answer {
    color: #18a300 !important;
    .no_content {
      background: #18a300 !important;
    }
  }
}
.updated__form {
  border-left: 8px solid rgb(255, 171, 90) !important;

  &.selectable:hover {
    border-left: 24px solid rgb(255, 171, 90) !important;
    background: #f0ffd6 !important;
  }

  &.checked {
    border-left: 24px solid rgb(255, 171, 90) !important;
  }
  &.expanded {
    border-left: 24px solid rgb(255, 171, 90) !important;
  }
  .question_answer {
    color: rgb(255, 171, 90) !important;
    .no_content {
      background: rgb(255, 171, 90) !important;
    }
  }
}
.preview_container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 48px;
  background: #fbfbfb;
  border-left: 8px solid #b6bdff;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  transition: all 0.25s ease-in;
  cursor: pointer;

  .left_section {
    display: flex;
    align-items: center;
    width: 100%;
    transition: all 0.25s ease-in;
  }

  &.loading {
    border-left: 8px solid #e0e0e0;
  }

  .check_btn {
    width: 14px;
    height: 14px;
    position: absolute;
    z-index: 5;
    top: 17px;
    left: -19px;
    opacity: 0;
    transform: scale(0);
    transition: all ease-in 0.25s;
    &::v-deep {
      .v-input--checkbox {
        margin: 0;
        padding: 0;
      }
      .v-input--selection-controls__ripple {
        display: none;
      }
      .v-input--selection-controls__input {
        width: 14px;
        height: 14px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .question_label {
    width: 50%;
    flex: none;
    padding-right: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #353542;
    max-height: 32px;
    overflow: hidden;
    .skeleton {
      height: 16px;
      width: 100px;
      ::v-deep {
        .v-skeleton-loader__bone {
          height: 12px;
        }
      }
    }
  }
  .question_answer {
    width: 50%;
    flex: none;
    padding: 0 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #2536cc;
    display: flex;
    justify-content: flex-end;
    .text_content {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .link_content {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .no_content {
      margin-right: 48px;
      width: 16px;
      height: 2px;
      background: #cfcfdc;
    }
  }

  .edit_btn {
    display: flex;
    padding: 4px 8px;
    height: 24px;
    background: #eeeef7;
    border-radius: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #707080;
    transform: scale(0);
    transition: all ease-in 0.25s;
    & > .icon {
      &::v-deep {
        .v-icon {
          width: 14px;
          height: 14px;
          margin-right: 2px;
          color: #707080;
        }
      }
    }
  }

  &.selectable:hover {
    border-left: 24px solid #b6bdff;
    background: #f4f7ff;
    .check_btn {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.disable {
    pointer-events: none;
    background-color: #ededed;
  }

  &.checked {
    border-left: 24px solid #b6bdff;
    .check_btn {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.expanded {
    border-left: 24px solid #b6bdff;
    .check_btn {
      opacity: 1;
      transform: scale(1);
    }
  }
}
.record_question_container {
  position: relative;
  padding: 8px 16px 8px 24px;
  width: calc(100% - 10px);
  margin: 10px 5px;
  height: 42px;
  display: flex;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #cfcfdc;
  border-radius: 10px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #2536cc;
    box-shadow: 0px 0px 12px 4px #ebf2ff;
  }
  .label {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
