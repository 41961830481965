<template>
  <BaseModal
    :value="isOpen"
    :modal-title="formTitle"
    is-complex
    :disabled="isSubmitted"
    @close="$emit('close')"
  >
    <template #modal>
      <div>
        <template v-if="questionClass == 'QUESTION'">
          <span class="sub-title" v-text="$t('utils.fieldSettings')" />
          <div class="input-fields">
            <!-- FIELD TYPE -->
            <base-autocomplete
              v-model="form.fieldType"
              :items="fieldTypes"
              item-text="label"
              item-value="type"
              :label="`${$t('utils.fieldType')}*`"
              :error-messages="fieldTypeErrors"
              :has-error="fieldTypeErrors.length > 0"
              @change="$v.form.fieldType.$touch()"
              @blur="$v.form.fieldType.$touch()"
            />
            <!-- DATATYPES -->
            <base-autocomplete
              v-if="form.fieldType == FIELD_TYPE.DATATYPE"
              v-model="form.datatype"
              :loading="datatypesFilter.loading"
              :items="datatypes.items"
              :filter="() => true"
              :search-input.sync="datatypesFilter.name"
              item-text="name"
              item-value="id"
              :label="`${$t('utils.fieldType')}*`"
              :placeholder="`${$t('utils.fieldType')}*`"
              :error-messages="datatypeErrors"
              :has-error="datatypeErrors.length > 0"
              @change="setCosmoformDefaultValues()"
              @blur="$v.form.datatype.$touch()"
            >
              <template #append-item>
                <div v-intersect="loadMoreDatatypes" />
              </template>
            </base-autocomplete>
            <!-- QUESTION TEMPLATE -->
            <base-autocomplete
              v-else-if="
                form.fieldType == FIELD_TYPE.QUESTION_TEMPLATE && isEdit
              "
              v-model="form.questionTemplate"
              :loading="questionTemplateFilter.loading"
              :items="questionTemplate.items"
              :filter="() => true"
              :search-input.sync="questionTemplateFilter.slug"
              item-text="name"
              item-value="id"
              :label="`${$t('utils.questionTemplates')}*`"
              :error-messages="questionTemplateErros"
              :has-error="questionTemplateErros.length > 0"
              @change="questionTemplateChange()"
            >
              <template #append-item>
                <div v-intersect="loadMoreQuestionTemplates" />
              </template>
            </base-autocomplete>
            <!-- CUSTOM FIELD -->
            <base-autocomplete
              v-else-if="form.fieldType == FIELD_TYPE.CUSTOM_FIELD"
              v-model="form.type"
              :items="typeItems"
              :placeholder="$t('question.chooseAType')"
              item-text="name"
              item-value="type"
              :label="`${$t('workflow.status.question.type')}*`"
              :error-messages="typeErrors"
              :has-error="typeErrors.length > 0"
              @change="$v.form.type.$touch()"
              @blur="$v.form.type.$touch()"
            />
            <!-- IS REQUIRED -->
            <v-switch
              v-model="form.required"
              inset
              :label="$t('course.forms.question.required.label')"
              @change="propertyChange('required')"
            />
          </div>
          <span class="sub-title" v-text="$t('utils.fieldCustomization')" />
          <div class="input-fields">
            <!-- SLUG -->
            <BaseTextField
              v-model.trim="form.slug"
              :label="$t('utils.dataSlug')"
              :disabled="isSlugEditionDisabled"
              :error-messages="slugErrors"
              :has-error="slugErrors.length > 0"
              @blur="$v.form.slug.$touch()"
              @change="propertyChange('slug')"
            />
            <!-- NAME -->
            <BaseTextField
              v-model="form.name"
              :label="`${$t('utils.dataName')}*`"
              :error-messages="nameErrors"
              :has-error="nameErrors.length > 0"
              @change="propertyChange('name')"
            />
            <!-- LABEL -->
            <BaseTextField
              v-model="form.label"
              :label="`${$t('utils.label')}*`"
              :error-messages="labelErrors"
              :has-error="labelErrors.length > 0"
              @change="propertyChange('label')"
            />
          </div>
          <div v-if="isHelpSectionOpen" class="input-fields">
            <!-- HELP SECTION -->
            <div class="help_section">
              <div class="d-flex align-center mb-4">
                <BaseIcon
                  class="mr-2"
                  medium
                  icon="$mdiInformation"
                  color="#353542"
                />
                <div class="help_title" v-text="$t('utils.help')" />
                <BaseIcon
                  icon="$mdiClose"
                  color="#CFCFDC"
                  class="ml-auto"
                  @click.prevent="
                    () => {
                      isHelpSectionOpen = false;
                      form.helpText = '';
                      form.helpFile = null;
                      image = null;
                      propertyChange('image');
                      propertyChange('helpText');
                      propertyChange('helpFile');
                    }
                  "
                />
              </div>
              <BaseTextArea
                v-model="form.helpText"
                rows="3"
                :label="$t('course.forms.question.helpText.label')"
                @change="propertyChange('helpText')"
              />
              <BaseFileInput
                :val="image"
                truncate-length="50"
                append-icon="$mdiDownload"
                :label="$t('course.forms.question.image.label')"
                prepend-icon=""
                :clearable="false"
                outlined
                @change="
                  (value) => {
                    image = value;
                    propertyChange('image');
                  }
                "
              />
              <BaseFileInput
                :val="form.helpFile"
                truncate-length="50"
                append-icon="$mdiDownload"
                label="Fichier d'aide"
                prepend-icon=""
                :error-messages="imageErrors"
                :clearable="false"
                outlined
                @change="
                  (value) => {
                    form.helpFile = value;
                    propertyChange('helpFile');
                  }
                "
              />
            </div>
          </div>
          <div v-if="isQuestionRequireOptions">
            <span class="sub-title" v-text="$tc('navigation.option', 10)" />
            <div class="input-fields">
              <div v-if="!isFetchingOptions">
                <div
                  v-for="(v, index) in $v.form.options.$each.$iter"
                  :key="index"
                  class="option_item"
                >
                  <BaseTextField
                    :ref="`option${+index + 1}`"
                    v-model.trim="form.options[index].name"
                    :error-messages="optionErrors[index]"
                    :label="
                      $t('course.forms.question.option.label', {
                        number: +index + 1,
                      })
                    "
                    :placeholder="
                      $t('course.forms.question.option.placeholder', {
                        number: +index + 1,
                      })
                    "
                    :clearable="true"
                    class="option_name"
                    @keydown.enter="v.name.$model && addOption($event)"
                    @change="isOptionChanged = true"
                  />
                  <BaseTextField
                    v-model="form.options[index].slug"
                    :disabled="isSlugEditionDisabled"
                    :label="
                      $t('course.forms.question.option.slug', {
                        number: +index + 1,
                      })
                    "
                    :placeholder="
                      $t('course.forms.question.option.slug', {
                        number: +index + 1,
                      })
                    "
                    :error-messages="optionSlugErrors[index]"
                    :has-error="optionSlugErrors[index].length > 0"
                    clearable
                    class="option_slug"
                    @input="v.slug.$touch()"
                  />
                  <div class="option_item_actions">
                    <BaseButtonIcon
                      :disabled="index == 0 || isQuestionTemplate"
                      color="primary"
                      class="rounded"
                      icon="$mdiArrowUpThick"
                      x-small
                      @click="moveOption(+index, false)"
                    />
                    <BaseButtonIcon
                      :disabled="
                        index ==
                          Object.keys($v.form.options.$each.$iter).length - 1 ||
                        isQuestionTemplate
                      "
                      color="primary"
                      class="rounded ml-2"
                      icon="$mdiArrowDownThick"
                      x-small
                      @click="moveOption(+index, true)"
                    />
                    <BaseButtonIcon
                      :disabled="form.options.length < 2 || isQuestionTemplate"
                      color="error"
                      class="ml-2"
                      x-small
                      icon="$mdiTrashCanOutline"
                      @click="deleteOption(index)"
                    />
                  </div>
                </div>
                <div v-if="has_free_option" class="option_item">
                  <BaseTextField
                    :value="$t('utils.other')"
                    :label="
                      $t('course.forms.question.option.label', {
                        number: +form.options.length + 1,
                      })
                    "
                    :disabled="has_free_option"
                    clearable
                    class="option_name"
                  />
                  <div class="option_item_actions">
                    <BaseButtonIcon
                      :disabled="has_free_option"
                      color="primary"
                      class="rounded"
                      icon="$mdiArrowUpThick"
                      x-small
                    />
                    <BaseButtonIcon
                      :disabled="has_free_option"
                      color="primary"
                      class="rounded ml-2"
                      icon="$mdiArrowDownThick"
                      x-small
                    />
                    <BaseButtonIcon
                      color="error"
                      class="ml-2"
                      x-small
                      icon="$mdiTrashCanOutline"
                      @click="has_free_option = false"
                    />
                  </div>
                </div>
                <div class="d-flex justify-center">
                  <BaseButton
                    :disabled="isQuestionTemplate"
                    icon="$mdiPlus"
                    class="mr-3"
                    @click="addOption()"
                  >
                    {{ $t("course.forms.question.utils.addOption") }}
                  </BaseButton>
                  <BaseButton
                    v-if="
                      (form.type === 'RADIOBUTTON' ||
                        form.type === 'CHECKBOX') &&
                      !has_free_option
                    "
                    icon="$mdiPlus"
                    :disabled="isQuestionTemplate"
                    @click="addOptionOther()"
                  >
                    {{ $t("course.forms.question.utils.addOptionOther") }}
                  </BaseButton>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="n in 3"
                  :key="n"
                  class="d-flex justify-space-between align-center mb-3"
                >
                  <BaseSkeletonLoader type="chip" />
                  <div class="d-flex flex-wrap align-center">
                    <BaseSkeletonLoader
                      height="20px"
                      width="20px"
                      type="image"
                      class="mr-2"
                    />
                    <BaseSkeletonLoader
                      height="20px"
                      width="20px"
                      type="image"
                      class="mr-2"
                    />
                    <BaseSkeletonLoader
                      height="20px"
                      width="20px"
                      type="image"
                    />
                  </div>
                </div>
                <div class="d-flex justify-center">
                  <BaseSkeletonLoader type="button" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="questionClass == 'MESSAGE'">
          <!-- LABEL -->
          <base-text-field
            v-model="form.label"
            :label="`${$t('course.forms.question.label.labelMessage')} *`"
            :placeholder="$t('course.forms.question.label.placeholderMessage')"
            :error-messages="labelErrors"
            @change="propertyChange('label')"
            @keydown.enter="submit"
          />

          <!-- IMAGE -->
          <div class="d-flex align-center">
            <BaseFileInput
              :val="image"
              truncate-length="50"
              append-icon="$mdiDownload"
              :label="$t('course.forms.question.image.label')"
              prepend-icon=""
              :clearable="false"
              outlined
              accept="image/jpeg,image/png,image/jpg"
              @change="
                (value) => {
                  image = value;
                  propertyChange('image');
                }
              "
            />
            <div class="d-flex align-center">
              <a
                v-if="
                  (question && question.image) || (image && !imageErrors.length)
                "
                :href="localImage ? localImage : question.image"
                target="blank"
                class="flex-grow-0 ml-4"
              >
                <BaseImage
                  :src="localImage ? localImage : question.image"
                  height="32px"
                  width="72px"
                  class="rounded-5"
                />
              </a>
            </div>
          </div>

          <div class="help_input">
            <BaseFileInput
              :val="form.helpFile"
              truncate-length="50"
              append-icon="$mdiDownload"
              :label="$t('utils.helpFile')"
              prepend-icon=""
              :clearable="false"
              outlined
              @change="
                (value) => {
                  form.helpFile = value;
                  propertyChange('helpFile');
                }
              "
            />
          </div>
        </template>
      </div>
    </template>

    <template #actions>
      <BaseButton
        v-if="!isHelpSectionOpen && questionClass !== 'MESSAGE'"
        outlined
        type="secondary"
        color="primary"
        @click="isHelpSectionOpen = true"
      >
        Ajouter une aide
      </BaseButton>
      <BaseButton
        text
        class="mx-2 ml-auto"
        color="#707080"
        :disabled="isSubmitted"
        @click="$emit('close')"
      >
        {{ $t("utils.cancel") }}
      </BaseButton>
      <BaseButton
        color="primary"
        type="primary"
        :loading="isSubmitted"
        @click.prevent="submit"
      >
        {{ $t("utils.validate") }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { validationMixin } from "vuelidate";

import { mapState } from "vuex";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

import { findAllQuestionOptions } from "../Services/course.service";
import { fileURLToFile } from "@/core/Utils/file.utils";
import {
  fetchQuestionTemplates,
  getQuestionTemplateOption,
} from "@/core/Services/questionTemplate.service";
import { fetchDatatypes } from "@/core/Services/datatype.service";

const FIELD_TYPE = {
  DATATYPE: "DATATYPE",
  QUESTION_TEMPLATE: "QUESTION_TEMPLATE",
  CUSTOM_FIELD: "CUSTOM_FIELD",
};

export default {
  name: "CourseQuestionCreateAndEditDialog",
  mixins: [validationMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    questionClass: {
      type: String,
      validator: function (value) {
        return ["MESSAGE", "QUESTION"].indexOf(value) !== -1;
      },
      default: null,
    },
  },
  data() {
    return {
      isHelpSectionOpen: false,
      form: {
        fieldType: "",
        questionTemplate: "",
        datatype: null,
        helpText: "",
        label: "",
        name: "",
        slug: "",
        type: "",
        options: [],
        deletedOptionIds: [],
        required: false,
        helpFile: null,
      },
      isOptionChanged: false,
      isSubmitted: false,
      image: null,
      localImage: "",
      FIELD_TYPE: FIELD_TYPE,
      fieldTypes: [
        {
          type: FIELD_TYPE.DATATYPE,
          label: this.$t("utils.datatypes"),
        },
        {
          type: FIELD_TYPE.QUESTION_TEMPLATE,
          label: this.$t("utils.questionTemplates"),
        },
        {
          type: FIELD_TYPE.CUSTOM_FIELD,
          label: this.$t("utils.customFields"),
        },
      ],
      typeItems: [
        { name: this.$t("question.chooseAType"), type: "" },
        { name: this.$t("question.types.text"), type: "TEXT" },
        { name: this.$t("question.types.textarea"), type: "TEXTAREA" },
        { name: this.$t("question.types.number"), type: "NUMBER" },
        { name: this.$t("question.types.date"), type: "DATE" },
        { name: this.$t("question.types.datetime"), type: "DATETIME" },
        { name: this.$t("question.types.select"), type: "SELECT" },
        { name: this.$t("question.types.checkbox"), type: "CHECKBOX" },
        { name: this.$t("question.types.radio"), type: "RADIOBUTTON" },
        { name: this.$t("question.types.file"), type: "FILE" },
        { name: this.$t("question.types.multiFile"), type: "MULTIPLE_FILE" },
        { name: this.$t("question.types.signature"), type: "SIGNATURE" },
        { name: this.$t("question.types.datagrid"), type: "DATAGRID" },
        { name: this.$t("question.types.rowDatagrid"), type: "ROW_DATAGRID" },
      ],
      isFetchingOptions: false,
      updatedProps: [],
      has_free_option: false,
      questionTemplate: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      questionTemplateFilter: {
        loading: false,
        page: 1,
        pageSize: 10,
        slug: undefined,
        searchTimeoutRef: undefined,
      },
      datatypes: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      datatypesFilter: {
        loading: false,
        page: 1,
        pageSize: 10,
        name: undefined,
        searchTimeoutRef: undefined,
      },
    };
  },
  validations() {
    if (this.questionClass === "MESSAGE")
      return {
        form: {
          label: {
            required,
            maxLength: maxLength(255),
          },
        },
      };
    else {
      let validations = {};
      validations.form = {
        name: {
          required,
          maxLength: maxLength(255),
        },
        slug: {
          maxLength: maxLength(255),
          notSpecialChar: (value) => {
            return value ? /^[a-zA-Z0-9_-]+$/.test(value) : true;
          },
        },
        label: {
          required,
          maxLength: maxLength(255),
        },
        fieldType: {
          required,
        },
      };
      switch (this.form.fieldType) {
        case FIELD_TYPE.DATATYPE:
          validations.form = {
            ...validations.form,
            datatype: {
              required,
            },
          };
          break;
        case FIELD_TYPE.QUESTION_TEMPLATE:
          if (!this.question)
            validations.form = {
              ...validations.form,
              questionTemplate: {
                required,
              },
            };
          break;
        case FIELD_TYPE.CUSTOM_FIELD:
          validations.form = {
            ...validations.form,
            type: {
              required,
            },
          };
          break;
      }
      if (this.isQuestionRequireOptions) {
        validations.form = {
          ...validations.form,
          options: {
            minLength: minLength(1),
            $each: {
              name: {
                required,
                maxLength: maxLength(255),
              },
              slug: {
                maxLength: maxLength(255),
                notSpecialChar: (value) => {
                  return value ? /^[a-zA-Z0-9_-]+$/.test(value) : true;
                },
                isUniqueSlug: (value) => {
                  return value
                    ? this.form.options.filter((o) => o.slug == value).length <=
                        1
                    : true;
                },
              },
            },
          },
        };
      }
      return validations;
    }
  },
  computed: {
    ...mapState({
      course: (state) => state.course.course,
      step: (state) => state.course.activeStep,
      question: (state) => state.course.activeQuestion,
    }),
    isEdit() {
      return !this.question;
    },
    isQuestionTemplate() {
      return (
        this.form.fieldType == FIELD_TYPE.QUESTION_TEMPLATE ||
        this.question?.slug?.includes("STD")
      );
    },
    isSlugEditionDisabled() {
      // edit
      if (this.question) return true;
      // if datatype and template
      return [FIELD_TYPE.DATATYPE, FIELD_TYPE.QUESTION_TEMPLATE].includes(
        this.form.fieldType
      );
    },
    formTitle() {
      switch (this.questionClass) {
        case "MESSAGE":
          return !this.question
            ? this.$t("course.addMessage")
            : this.$t("course.editMessage");
        case "QUESTION":
          return !this.question
            ? this.$t("course.addQuestion")
            : this.$t("course.editQuestion");
      }
      return this.$t("course.addQuestion");
    },
    isQuestionRequireOptions() {
      const requireOptionsRegex =
        /^SELECT|RADIOBUTTON|CHECKBOX|DATAGRID|ROW_DATAGRID$/;
      return this.form.type && requireOptionsRegex.test(this.form.type);
    },
    datatypeErrors() {
      const errors = [];
      if (!this.$v.form.datatype.$dirty) return errors;
      !this.$v.form.datatype.required &&
        errors.push(this.$t("course.forms.question.type.required"));
      return errors;
    },
    questionTemplateErros() {
      if (!this.$v.form.questionTemplate.$dirty) return [];
      if (!this.$v.form.questionTemplate.required)
        return this.$t("course.forms.question.type.required");
      return [];
    },
    fieldTypeErrors() {
      if (!this.$v.form.fieldType.$dirty) return [];
      if (!this.$v.form.fieldType.required)
        return this.$t("course.forms.question.type.required");
      return [];
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.form.type.$dirty) return errors;
      !this.$v.form.type.required &&
        errors.push(this.$t("course.forms.question.type.required"));
      return errors;
    },
    nameErrors() {
      let errors = "";
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required &&
        (errors = this.$t("course.forms.question.name.required"));
      !this.$v.form.name.maxLength &&
        (errors = this.$t("course.forms.question.name.maxLength", { n: 255 }));
      return errors;
    },
    slugErrors() {
      const errors = [];
      if (!this.$v.form.slug.$dirty) return errors;
      if (!this.$v.form.slug.maxLength) {
        errors.push(
          this.$t("course.forms.question.slug.maxLength", { n: 255 })
        );
      }
      if (!this.$v.form.slug.notSpecialChar) {
        errors.push(
          this.$t("course.forms.question.slug.specialCharError", { n: 255 })
        );
      }
      return errors;
    },
    labelErrors() {
      let errors = "";
      if (!this.$v.form.label.$dirty) return errors;
      !this.$v.form.label.required &&
        (errors = this.$t(
          `course.forms.question.label.required${
            /^MESSAGE$/.test(this.questionClass) ? "Message" : "Question"
          }`
        ));
      !this.$v.form.label.maxLength &&
        (errors = this.$t(
          `course.forms.question.label.maxLength${
            /^MESSAGE$/.test(this.questionClass) ? "Message" : "Question"
          }`,
          { n: 255 }
        ));
      return errors;
    },
    imageErrors() {
      let errors = [];
      if (this.image && !/.jpg$|.jpeg$|.png$/.test(this.image.type))
        errors.push(this.$t("utils.pleaseProvideAnImage"));
      if (this.image && this.image.size > 4000000)
        errors.push(this.$t("utils.imageTooBig", { size: "4" }));
      return errors;
    },
    optionErrors() {
      const errors = [];
      if (!this.$v.form.options) return errors;
      for (
        let index = 0;
        index < Object.keys(this.$v.form.options.$each.$iter).length;
        index++
      ) {
        errors.push([]);
        if (!this.$v.form.options.$each.$iter[index.toString()].name.$dirty) {
          continue;
        }
        if (
          !this.$v.form.options.$each.$iter[index.toString()].name.maxLength
        ) {
          errors[index] = [
            this.$t("course.forms.question.option.maxLength", { number: 255 }),
          ];
          continue;
        }
        if (!this.$v.form.options.$each.$iter[index.toString()].name.required) {
          errors[index] = [this.$t("course.forms.question.option.required")];
        }
      }
      return errors;
    },
    optionSlugErrors() {
      const errors = [];
      if (!this.$v.form.options) return errors;
      for (
        let index = 0;
        index < Object.keys(this.$v.form.options.$each.$iter).length;
        index++
      ) {
        errors.push([]);
        const option = this.$v.form.options.$each.$iter[index.toString()];
        if (!option.slug.$dirty) continue;
        if (!option.slug.maxLength) {
          errors[index] = [
            this.$t("course.forms.question.slug.maxLength", { n: 255 }),
          ];
          continue;
        }
        if (!option.slug.notSpecialChar) {
          errors[index] = [
            this.$t("course.forms.question.slug.specialCharError", { n: 255 }),
          ];
          continue;
        }
        if (!option.slug.isUniqueSlug) {
          errors[index] = [
            this.$t("course.forms.question.slug.duplicatedSlug"),
          ];
          continue;
        }
      }
      return errors;
    },
  },
  watch: {
    async isOpen(val) {
      if (val) {
        await this.initialize();
        if (this.question) {
          this.initializeForm();
        }
      } else {
        this.reset();
      }
    },
    image(val) {
      if (val && !this.imageErrors.length) {
        this.localImage = URL.createObjectURL(val);
      } else {
        this.localImage = "";
      }
    },
    isQuestionRequireOptions(newVal) {
      if (newVal && this.form.options.length < 1) {
        this.addOption();
      }
    },
    "questionTemplateFilter.slug"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterQuestionTemplates();
    },
    "datatypesFilter.name"(newVal, oldVal) {
      if (newVal == oldVal || oldVal == null) return;
      this.filterDatatypes();
    },
  },
  methods: {
    async initialize() {
      this.fetchQuestionTemplates();
      this.fetchDatatypes();
    },
    async fetchQuestionTemplates() {
      this.questionTemplateFilter.loading = true;
      try {
        this.questionTemplateFilter.page = 1;
        this.questionTemplate = await fetchQuestionTemplates({
          organizationId: this.$route.params.organizationId,
          page: this.questionTemplateFilter.page,
          slug: this.questionTemplateFilter.slug,
          pageSize: this.questionTemplateFilter.pageSize,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.questionTemplateFilter.loading = false;
    },
    filterQuestionTemplates() {
      if (this.questionTemplateFilter.searchTimeoutRef)
        clearTimeout(this.questionTemplateFilter.searchTimeoutRef);
      this.questionTemplateFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchQuestionTemplates();
      }, 200);
    },
    async loadMoreQuestionTemplates(_, __, isIntersecting) {
      if (
        !isIntersecting ||
        this.questionTemplateFilter.loading ||
        !this.questionTemplate.next
      )
        return;
      this.questionTemplateFilter.page = this.questionTemplate.next;
      this.questionTemplateFilter.loading = true;
      try {
        const tmp = await fetchQuestionTemplates({
          organizationId: this.$route.params.organizationId,
          page: this.questionTemplateFilter.page,
          slug: this.questionTemplateFilter.slug,
          pageSize: this.questionTemplateFilter.pageSize,
        });
        this.questionTemplate = {
          ...tmp,
          items: [...this.questionTemplate.items, ...tmp.items],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.questionTemplateFilter.loading = false;
    },
    async fetchDatatypes() {
      this.datatypesFilter.loading = true;
      try {
        this.datatypesFilter.page = 1;
        this.datatypes = await fetchDatatypes({
          organizationId: this.$route.params.organizationId,
          page: this.datatypesFilter.page,
          name: this.datatypesFilter.name,
          pageSize: this.datatypesFilter.pageSize,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.datatypesFilter.loading = false;
    },
    filterDatatypes() {
      if (this.datatypesFilter.searchTimeoutRef)
        clearTimeout(this.datatypesFilter.searchTimeoutRef);
      this.datatypesFilter.searchTimeoutRef = setTimeout(async () => {
        this.fetchDatatypes();
      }, 500);
    },
    async loadMoreDatatypes(_, __, isIntersecting) {
      if (
        !isIntersecting ||
        this.datatypesFilter.loading ||
        !this.datatypes.next
      )
        return;
      this.datatypesFilter.page = this.datatypes.next;
      this.datatypesFilter.loading = true;
      try {
        const tmp = await fetchDatatypes({
          organizationId: this.$route.params.organizationId,
          page: this.datatypesFilter.page,
          name: this.datatypesFilter.name,
          pageSize: this.datatypesFilter.pageSize,
        });
        this.datatypes = {
          ...tmp,
          items: [...this.datatypes.items, ...tmp.items],
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.datatypesFilter.loading = false;
    },
    async initializeForm() {
      this.form.slug = this.question.datatype
        ? this.question.datatype
        : this.question.slug;
      this.form.name = this.question.name;
      this.form.label = this.question.label;
      this.form.type = this.question.typeQuestion;
      this.form.helpText = this.question.helpText;
      this.form.required = this.question.required;
      this.has_free_option = this.question.hasFreeOption;
      this.question.choices
        ? (this.form.options = this.question.choices
            .slice()
            .sort((a, b) => a.order - b.order))
        : null;
      this.form.datatype = this.question.datatype;
      if (this.form.datatype) this.form.fieldType = FIELD_TYPE.DATATYPE;
      else if (this.question.slug.includes("STD"))
        this.form.fieldType = FIELD_TYPE.QUESTION_TEMPLATE;
      else this.form.fieldType = FIELD_TYPE.CUSTOM_FIELD;
      if (this.isQuestionRequireOptions) {
        this.isFetchingOptions = true;
        let questionOptions = await this.getOptions(1);
        this.form.options = [...questionOptions.results];
        if (questionOptions.next) {
          questionOptions = await this.getOptions(+questionOptions.next);
          if (!questionOptions) return;
          this.form.options = [
            ...this.form.options,
            ...questionOptions.results,
          ];
        }
        this.isFetchingOptions = false;
      }
      if (!this.question) return;
      if (this.question.image) {
        this.image = await fileURLToFile(
          this.question.image,
          this.question.image?.split("/").reverse()[0]
        );
      }
      if (this.question.helpFile) {
        this.form.helpFile = await fileURLToFile(
          this.question.helpFile,
          this.question.helpFile?.split("/").reverse()[0]
        );
      }
      if (
        this.question.helpFile ||
        this.question.image ||
        this.question.helpText
      )
        this.isHelpSectionOpen = true;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) return;
      this.isSubmitted = true;
      if (!this.question?.id) {
        await this.createStepQuestion();
      } else {
        await this.editStepQuestion();
      }
      this.$emit("close");
      this.reset();
    },
    async createStepQuestion() {
      try {
        const questionOrder = this.step.questions.length
          ? this.step.questions
              .slice()
              .sort((a, b) => a.order - b.order)
              .reverse()[0].order + 1
          : 0;
        await this.$store.dispatch("course/createQuestion", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.step.id,
          payload: {
            ...this.buildQuestion(),
            order: questionOrder,
          },
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.createQuestionConfirmation"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    async editStepQuestion() {
      try {
        await this.$store.dispatch("course/editQuestion", {
          organizationId: this.$route.params.organizationId,
          courseId: this.$route.params.courseId,
          stepId: this.step.id,
          questionId: this.question.id,
          question: this.buildQuestion(),
          deletedOptionsIds: this.form.deletedOptionIds,
          isOptionChanged: this.isOptionChanged,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t("course.editQuestionConfirmation"),
          type: "SUCCESS",
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    buildQuestion() {
      let question = {
        label: this.form.label,
      };
      switch (this.questionClass) {
        case "MESSAGE":
          question.typeQuestion = this.questionClass;
          question.name = this.questionClass;
          break;
        case "QUESTION":
          question.datatype = this.form.datatype;
          question.typeQuestion = this.form.type;
          question.slug = this.form.slug;
          question.name = this.form.name;
          question.helpText = this.form.helpText;
          question.required = this.form.required;
          question.has_free_option = this.has_free_option;
          if (this.isQuestionRequireOptions) {
            question.choices = this.form.options;
          }
      }
      if (this.image) {
        // If the image name is longer than a 100 characters truncate it to 100 maximum
        if (this.image.name.length > 100) {
          let strs = this.image.name.split(".");
          const extension = "." + strs.splice(strs.length - 1, 1)[0];
          const name =
            strs.join(".").substr(0, 99 - extension.length) + extension;
          this.image = new File([this.image], name, { type: this.image.type });
        }
        question.image = this.image;
      }
      if (this.form.helpFile) {
        question.helpFile = this.form.helpFile;
      }
      if (this.isQuestionRequireOptions) {
        this.orderOptions();
      }
      if (this.question && this.question.id) {
        for (const key in question) {
          if (
            Object.hasOwnProperty.call(question, key) &&
            !this.updatedProps.includes(key) &&
            key !== "choices" &&
            key !== "has_free_option"
          )
            delete question[key];
        }
        question.order = this.question.order;
      }
      return question;
    },
    reset() {
      this.$v.$reset();
      this.form = {
        fieldType: null,
        questionTemplate: null,
        datatype: null,
        type: "",
        name: "",
        slug: "",
        label: "",
        helpText: "",
        required: false,
        options: [],
        deletedOptionIds: [],
        helpFile: null,
      };
      this.image = null;
      this.localImage = "";
      this.isHelpSectionOpen = false;
      this.updatedProps = [];
      this.isOptionChanged = false;
      this.has_free_option = false;
      this.isSubmitted = false;
    },
    setCosmoformDefaultValues() {
      this.$v.form.datatype.$touch();
      let datatype = this.datatypes.items.find(
        (dt) => dt.id === this.form.datatype
      );
      if (!datatype) return;
      this.form.name = datatype.name;
      this.form.slug = datatype.slug;
      this.form.label = datatype?.label || datatype.name;
      this.form.options = [];
    },
    async questionTemplateChange() {
      this.$v.form.questionTemplate.$touch();
      const questionTemplate = this.questionTemplate.items.find(
        (qt) => qt.id == this.form.questionTemplate
      );
      if (!questionTemplate) return;
      this.form.name = questionTemplate.name;
      this.form.type = questionTemplate.typeQuestion;
      this.form.slug = questionTemplate.slug;
      this.form.label = questionTemplate.label;
      if (questionTemplate.helpFile || questionTemplate.helpText !== "") {
        this.isHelpSectionOpen = true;
      }
      this.form.helpText = questionTemplate.helpText;
      this.form.helpFile = questionTemplate.helpFile
        ? await fileURLToFile(
            questionTemplate.helpFile,
            this.$t("utils.helpFile")
          )
        : null;
      this.fetchQuestionTemplateOption(questionTemplate.id);
    },
    async fetchQuestionTemplateOption(questionTemplateId) {
      if (!this.isQuestionRequireOptions) return;
      this.isFetchingOptions = true;
      try {
        this.form.options = await getQuestionTemplateOption({
          organizationId: this.$route.params.organizationId,
          questionTemplateId: questionTemplateId,
        });
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.isFetchingOptions = false;
    },
    addOption(event) {
      event && event.target.blur();
      this.form.options.push({ order: this.form.options.length });
    },
    addOptionOther() {
      this.has_free_option = true;
    },
    moveOption(index, upperOrder) {
      let temp = this.form.options[index];
      this.isOptionChanged = true;
      this.form.options.splice(
        index,
        1,
        this.form.options[upperOrder ? index + 1 : index - 1]
      );
      this.form.options.splice(upperOrder ? index + 1 : index - 1, 1, temp);
      this.orderOptions();
    },
    deleteOption(index) {
      if (this.form.options[index].id) {
        this.form.deletedOptionIds.push(this.form.options[index].id);
      }
      this.form.options.splice(index, 1);
      this.orderOptions();
    },
    orderOptions() {
      this.form.options.forEach((option, index) => {
        option.order = index;
      });
    },
    async getOptions(page) {
      try {
        const response = await findAllQuestionOptions(
          this.$route.params.organizationId,
          this.$route.params.courseId,
          this.step.id,
          this.question.id,
          page
        );
        return response;
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
    },
    propertyChange(property) {
      if (!this.updatedProps.includes(property))
        this.updatedProps.push(property);
    },
  },
};
</script>

<style lang="scss" scoped>
.help_section {
  display: flex;
  flex-direction: column;
  padding: 18px;
  gap: 8px;
  min-height: 255px;
  background: #fcfcfc;
  border: 1px solid #cfcfdc;
  border-radius: 5px;
  .help_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #353542;
  }
}
.sub-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #353542;
}
.input-fields {
  padding: 16px 16px 0 16px;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
::v-deep .v-skeleton-loader__text {
  height: 40px;
}
.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.option_item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  .option_slug {
    flex: 1;
  }
  .option_slug {
    flex: none;
    width: 200px;
  }
  .option_item_actions {
    display: flex;
    align-items: center;
    flex: none;
    width: 76px;
    margin-top: -26px;
  }
}
</style>
