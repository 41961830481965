import { render, staticRenderFns } from "./FolderCreateAndEditDialog.vue?vue&type=template&id=0e77c0bb&scoped=true"
import script from "./FolderCreateAndEditDialog.vue?vue&type=script&lang=js"
export * from "./FolderCreateAndEditDialog.vue?vue&type=script&lang=js"
import style0 from "./FolderCreateAndEditDialog.vue?vue&type=style&index=0&id=0e77c0bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e77c0bb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
