<template>
  <BaseDialog max-width="800" persistent :value="true">
    <template #modal>
      <BaseCosmoCard>
        <template #title>
          <div class="modal_header">
            <BaseTitle v-text="$t('utils.automateTheWorkflow')" />
            <BaseButtonIcon
              class="close_icon"
              icon="$mdiClose"
              :disabled="isSubmiting"
              @click="reset()"
            />
          </div>
        </template>
        <div class="modal_content">
          <v-progress-linear
            v-if="isSubmiting"
            class="progression"
            indeterminate
          />
          <div class="input_group">
            <div class="input_label">
              <div class="label_content" v-text="$t('utils.relatedCampaign')" />
              <div class="label_icon">
                <v-icon class="icon">
                  {{ "$mdiInformation" }}
                </v-icon>
                <div class="tool_tip_container">
                  <div class="msg_container">
                    <span
                      class="text__tooltip"
                      v-text="$t('workflowCampaignModal.tooltip')"
                    />
                    <v-icon class="arrow_down">$arrowDown</v-icon>
                  </div>
                </div>
              </div>
            </div>
            <base-autocomplete
              v-model="campaign"
              :items="campaignDetail.detail.items"
              outlined
              item-text="name"
              item-value="id"
              :loading="campaignDetail.loading"
              :disabled="isSubmiting"
              :filter="() => true"
              :search-input.sync="campaignDetail.filters.name"
              clearable
            />
          </div>
        </div>
        <div class="modal_footer">
          <BaseButton
            :disabled="isSubmiting"
            color="grey"
            type="secondary"
            @click="reset()"
          >
            {{ $t("utils.cancel") }}
          </BaseButton>
          <BaseButton
            :disabled="isSubmiting || !hasChange"
            color="primary"
            type="primary"
            @click="submit()"
          >
            {{ $t("utils.validate") }}
          </BaseButton>
        </div>
      </BaseCosmoCard>
    </template>
  </BaseDialog>
</template>

<script>
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";
import CampaignAPI from "@/modules/Editor/Services/campaign.api";
import Campaign from "@/core/Models/Campaign";

export default {
  name: "WorkflowCampaignDialog",
  props: {
    organizationId: {
      type: [Number, String],
      required: true,
    },
    workflow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFetchingCampaign: true,
      isSubmiting: false,
      campaignList: [],
      campaign: null,
      campaignDetail: {
        loading: false,
        filters: {
          pageSize: 10,
          page: 1,
          name: null,
          status: "IN_PROGRESS",
        },
        detail: {
          count: 0,
          next: 0,
          previous: 0,
          items: [],
        },
      },
      searchTimeoutRef: null,
    };
  },
  computed: {
    hasChange() {
      return this.campaign != this.workflow.campaign?.id;
    },
  },
  watch: {
    "campaignDetail.filters.name": {
      handler(newVal, oldVal) {
        if (newVal == oldVal) return;
        this.filterCampaigns();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    reset() {
      if (this.isSubmiting) return;
      this.$emit("close");
    },
    initialize() {
      if (this.workflow.campaign?.id) {
        this.campaign = this.workflow.campaign?.id;
        this.campaignDetail.filters.name = this.workflow.campaign.name;
      } else this.fetchCampaigns();
    },
    async fetchCampaigns() {
      this.campaignDetail.loading = true;
      try {
        const data = (
          await CampaignAPI.getOrganizationCampaigns({
            organizationId: this.organizationId,
            filters: this.campaignDetail.filters,
          })
        ).data;
        this.campaignDetail.detail = {
          ...data,
          items: data.results.map((f) => {
            const res = new Campaign(f);
            return res;
          }),
        };
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.campaignDetail.loading = false;
    },
    filterCampaigns() {
      if (this.searchTimeoutRef) clearTimeout(this.searchTimeoutRef);
      this.searchTimeoutRef = setTimeout(async () => {
        this.fetchCampaigns();
      }, 500);
    },
    async submit() {
      this.isSubmiting = true;
      try {
        const res = await WorkflowAPI.patchOrganizationWorkflow(
          this.organizationId,
          this.workflow.id,
          {
            campaign: this.campaign ? this.campaign : null,
          }
        );
        this.$store.commit("workflow/SET_ACTIVE_WORKFLOW", {
          ...this.workflow,
          campaign: res.data.campaign,
        });
        this.$store.dispatch("snackbar/active", {
          message: this.$t(
            "workflow.snackbar.success.workflowModifiedSuccessfully"
          ),
          type: "SUCCESS",
        });
      } catch {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("workflow.snackbar.success.errorModifyingWorkflow"),
          type: "ERROR",
        });
      }
      this.isSubmiting = false;
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 8px;
  flex-wrap: wrap;
  .modal_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #353542;
  }
  .close_icon {
    margin-left: auto;
  }
}
.modal_content {
  padding-top: 24px;
  position: relative;
  .progression {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .input_group {
    .input_label {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 16px;
      .label_content {
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
      .label_icon {
        width: 16px;
        height: 16px;
        position: relative;

        .icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
          ::v-deep {
            .v-icon {
              width: 16px;
              height: 16px;
            }
          }
        }
        &:hover {
          .tool_tip_container {
            transition: 125ms ease-in-out;
            display: flex;
          }
        }
        .tool_tip_container {
          display: none;
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translate(-50%, calc(-100% - 16px));
          width: 288px;
          padding: 6px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 10px;
          background: #666;

          .msg_container {
            position: relative;
            display: flex;
            align-items: center;
            .arrow_down {
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translate(-50%, 100%);
            }
          }
          .text__tooltip {
            color: #fff;
            text-align: center;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 8px 0;
  gap: 16px;
}
</style>
