<template>
  <v-data-table
    :locale="'fr'"
    class="elevation-2 my-4"
    :headers="listHeaders"
    :items="sortedRecords"
    :loading="isLoadingRecords"
    item-key="id"
    show-expand
  >
    <template #item="{ item, isExpanded, expand }">
      <tr class="row-click">
        <td @click="expand(!isExpanded)">
          <div class="d-flex flex-column ma-2 justify-center">
            <BaseParagraph
              v-if="item.client"
              class="mb-2"
              v-text="item.client.email"
            />
            <BaseParagraph
              v-if="item.client"
              type="secondary"
              v-text="`${item.client.firstName} ${item.client.lastName}`"
            />

            <BaseParagraph v-else v-text="item.email" />
          </div>
        </td>
        <td @click="expand(!isExpanded)">
          <BaseParagraph v-text="$d(new Date(item.dateCreated), 'veryshort')" />
        </td>
        <td v-if="!item.answer" @click="expand(!isExpanded)">
          <BaseParagraph
            v-text="
              item.nextReminderDate
                ? $d(new Date(item.nextReminderDate), 'veryshort')
                : ''
            "
          />
        </td>
        <td @click="expand(!isExpanded)">
          <BaseButtonIcon
            :class="{
              'openRecord-enter-active': !isExpanded,
              'openRecord-leave-active': isExpanded,
            }"
            icon="$mdiChevronDown"
          />
        </td>
      </tr>
    </template>
    <template #expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <div class="my-4">
          <Record
            v-if="/^AGREED$/.test(recordStatus)"
            :record-id="item.id"
            :record="item"
          />
          <BaseRow
            v-else-if="/^DISAGREED$/.test(recordStatus)"
            :record-id="item.id"
            class="d-flex justify-center align-center"
          >
            <BaseCol class="d-flex" cols="7">
              <BaseParagraph
                class="ml-auto mb-2 d-block"
                text="primary"
                v-text="$t('client.notAgreedLabel')"
              />
            </BaseCol>
            <BaseCol class="d-flex" cols="5">
              <BaseButton
                type="warning"
                color="red"
                class="d-flex align-center ml-auto mr-11"
                @click="openRelaunchModal({ item, isDisagree: true })"
              >
                <BaseIcon icon="$mdiEmailOutline" color="red" class="mr-2" />
                <BaseParagraph
                  bold
                  text="secondary"
                  color="red"
                  v-text="$t('folder.relaunch')"
                />
              </BaseButton>
            </BaseCol>
          </BaseRow>
          <BaseRow v-else class="d-flex justify-center align-center">
            <BaseCol class="d-flex" cols="7">
              <BaseParagraph
                class="ml-auto mb-2 d-block"
                text="primary"
                v-text="$t('client.notFinishedLabel')"
              />
            </BaseCol>
            <BaseCol class="d-flex" cols="5">
              <BaseButton
                type="primary"
                color="purple"
                class="d-flex align-center ml-auto mr-11"
                @click="openRelaunchModal({ item, isDisagree: false })"
              >
                <BaseIcon icon="$mdiEmailOutline" color="purple" class="mr-2" />
                <BaseParagraph
                  bold
                  text="secondary"
                  color="purple"
                  v-text="$t('folder.relaunch')"
                />
              </BaseButton>
            </BaseCol>
          </BaseRow>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import Record from "@/core/Components/Record";

export default {
  name: "CampaignDetailRecordsList",
  components: {
    Record,
  },
  props: {
    listHeaders: {
      type: Array,
      required: true,
    },
    records: {
      type: Array,
      default: () => [],
    },
    recordStatus: {
      type: String,
      default: "AGREED",
    },
    isLoadingRecords: {
      type: Boolean,
    },
  },
  computed: {
    sortedRecords() {
      return [...this.records].sort((a, b) => b.dateCreated - a.dateCreated);
    },
  },
  methods: {
    openRelaunchModal(item) {
      this.$store.commit("folder/SET_RELAUNCH_DIALOG_OPEN", true);
      this.$store.commit("folder/SET_RECORD_TO_RELAUNCH", item);
    },
  },
};
</script>

<style scoped>
@keyframes openRecord-in {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.openRecord-enter-active {
  animation: openRecord-in 0.5s;
}
.openRecord-leave-active {
  animation: openRecord-in 0.5s reverse;
  transform: rotate(180deg);
}
::v-deep .row-click {
  cursor: pointer;
}
</style>
