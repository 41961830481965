import { render, staticRenderFns } from "./PreviewCosmoform.vue?vue&type=template&id=0cf70eae&scoped=true"
import script from "./PreviewCosmoform.vue?vue&type=script&lang=js"
export * from "./PreviewCosmoform.vue?vue&type=script&lang=js"
import style0 from "./PreviewCosmoform.vue?vue&type=style&index=0&id=0cf70eae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf70eae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
