import { cosmoAPI } from "@/core/Api/cosmoAxiosInstance";

export default {
  //#region Record > Question - API
  getDatatypes({ page, typeQuestion, pageSize, name }) {
    let params = [];
    if (page) params.push(`page=${page}`);
    if (typeQuestion) params.push(`type_question=${typeQuestion}`);
    if (pageSize) params.push(`page_size=${pageSize}`);
    if (name) params.push(`name=${name}`);
    return cosmoAPI.get(`/datatype/?${params.join("&")}`);
  },
};
