<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 9H19L13.5 3.5V9ZM6.5 2H14.5L20.5 8V20C20.5 20.5304 20.2893 21.0391 19.9142 21.4142C19.5391 21.7893 19.0304 22 18.5 22H6.5C5.96957 22 5.46086 21.7893 5.08579 21.4142C4.71071 21.0391 4.5 20.5304 4.5 20V4C4.5 2.89 5.39 2 6.5 2ZM11.7 18.46L16.45 13.71L15.28 12.3L11.7 15.88L10.11 14.3L8.95 15.46L11.7 18.46Z"
      fill="#2536CC"
    />
  </svg>
</template>

<script>
export default {
  name: "FileDone",
};
</script>
