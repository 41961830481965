<template>
  <div class="workflow_container">
    <BaseStepper v-if="workflow" key="2" v-model="activeStepperStatus">
      <BaseStepperHeader :title="$tc('utils.status', 2)">
        <BaseStepperStep
          v-for="(status, index) in workflow.statuses"
          :key="`ss${status.id}-${index}`"
          :step="index + 1"
          :label="status.name"
          :complete="isComplete(status)"
          :active="folderActiveStatusId == status.id"
        />
      </BaseStepperHeader>

      <BaseStepperItems>
        <BaseStepperContent
          v-for="(status, index) in workflow.statuses"
          :key="`sc${index}${status.id}`"
          :step="index + 1"
        >
          <div class="stepper_content">
            <div class="status_section">
              <FolderWorkflowStatus
                :status="status"
                :loading="fetchingStatusDetail"
              />
            </div>
            <div class="action_section">
              <FolderDetailActions
                :status="status"
                :loading="fetchingStatusDetail"
                :automation-effects="automationEffects"
                @refetch-folder-effect="fetchWorkflowEffect"
              />
            </div>
          </div>
        </BaseStepperContent>
      </BaseStepperItems>
    </BaseStepper>

    <!-- DIALOG -->
    <FolderChangeStatusDialog />
    <FolderAskInformationDialog />
    <FolderOfferCreateAndEditDialog />
    <FolderStatusQuestionCreateDialog />
    <FolderWorkflowSendCosmoFormDialog />
    <FolderWorkflowAnwserQuestionsDialog />
    <FolderGenerateDocxDialog
      v-if="isModalGenerateDocOpen"
      :is-open="isModalGenerateDocOpen"
      :active-folder="activeFolder"
      :status="activeStatus"
    />
  </div>
</template>

<script>
import FolderDetailActions from "@/modules/Folder/Components/FolderDetailActions";
import FolderWorkflowStatus from "@/modules/Folder/Components/FolderWorkflowStatus";
import FolderChangeStatusDialog from "@/modules/Folder/Components/FolderChangeStatusDialog";
import FolderAskInformationDialog from "@/modules/Folder/Components/FolderAskInformationDialog";
import FolderOfferCreateAndEditDialog from "@/modules/Folder/Components/FolderOfferCreateAndEditDialog";
import FolderStatusQuestionCreateDialog from "@/modules/Folder/Components/FolderStatusQuestionCreateDialog";
import FolderWorkflowSendCosmoFormDialog from "@/modules/Folder/Components/FolderWorkflowSendCosmoFormDialog";
import FolderWorkflowAnwserQuestionsDialog from "@/modules/Folder/Components/FolderWorkflowAnwserQuestionsDialog";
import { mapState } from "vuex";
import FolderGenerateDocxDialog from "./FolderGenerateDocxDialog.vue";
import { getFolderEffects } from "@/modules/Folder/Services/folder.service";

export default {
  name: "FolderWokflowDetail",
  components: {
    FolderDetailActions,
    FolderWorkflowStatus,
    FolderChangeStatusDialog,
    FolderAskInformationDialog,
    FolderOfferCreateAndEditDialog,
    FolderStatusQuestionCreateDialog,
    FolderWorkflowSendCosmoFormDialog,
    FolderWorkflowAnwserQuestionsDialog,
    FolderGenerateDocxDialog,
  },
  props: {
    folderActiveStatusId: {
      type: Number,
      required: true,
    },
    workflow: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fetchingStatusDetail: false,
      automationEffects: [],
      fetchingAutomationEffect: true,
      activeStepperStatus: 0,
    };
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
      isModalGenerateDocOpen: (state) =>
        state.folder.modals.isModalGenerateDocOpen,
      activeFolder: (state) => state.folder.activeFolder,
    }),
    activeStatus() {
      return this.activeWorkflow?.statuses?.find(
        (status) => status.id === this.folderActiveStatusId
      );
    },
    workflowId() {
      return this.workflow ? this.workflow.id : null;
    },
  },
  watch: {
    folderActiveStatusId(newVal, oldVal) {
      if (!isNaN(newVal) && !isNaN(oldVal)) this.updateActiveStepperStatus();
    },
    activeStepperStatus: {
      handler(newVal) {
        if (newVal) {
          this.getStatusDetail(parseInt(this.workflow.statuses[newVal - 1].id));
          this.$store.commit("folder/SET_ACTIVE_FOLDER", {
            ...this.activeFolder,
            activeStatus: +this.workflow.statuses[newVal - 1].id,
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.updateActiveStepperStatus();
    this.fetchWorkflowEffect();
  },
  methods: {
    isComplete(status) {
      return this.activeStatus?.order > status?.order;
    },
    async updateActiveStepperStatus() {
      if (this.workflow) {
        const activeIndex =
          this.workflow.statuses.findIndex(
            (s) => s.id == this.folderActiveStatusId
          ) + 1;
        if (activeIndex) {
          this.activeStepperStatus = activeIndex;
        } else {
          this.activeStepperStatus =
            this.workflow.statuses.findIndex(
              (s) => s.id == this.workflow.primaryStatus
            ) + 1;
        }
      }
    },
    async getStatusDetail(statusId) {
      this.fetchingStatusDetail = true;
      try {
        if (this.$route.params.folderId) {
          await Promise.all([
            this.$store.dispatch("workflow/fetchFolderQuestions", {
              organizationId: this.$route.params.organizationId,
              workflowId: this.workflowId,
              folderId: this.$route.params.folderId,
              statusId: statusId,
            }),
            this.$store.dispatch("workflow/fetchFolderStatusActions", {
              organizationId: this.$route.params.organizationId,
              folderId: this.$route.params.folderId,
              statusId: statusId,
            }),
          ]);
        }
      } catch (error) {
        const { data, status } = error.response;
        if (status === 404 || status === 500) throw new Error(data.detail);
      }
      this.fetchingStatusDetail = false;
    },
    async fetchWorkflowEffect() {
      this.fetchingAutomationEffect = false;
      this.automationEffects = await getFolderEffects({
        organizationId: this.$route.params.organizationId,
        folderId: this.$route.params.folderId,
      });
      this.fetchingAutomationEffect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.workflow_container {
  margin-bottom: 24px;
  .stepper_content {
    display: flex;
    .status_section {
      width: calc(100% - 350px);
      flex: none;
    }
    .action_section {
      width: 350px;
      flex: none;
    }
  }
}
</style>
