<template>
  <BaseCard class="pa-6" is-rounded>
    <div class="d-flex">
      <div class="logo-container">
        <BaseImage :src="$findStatic('cosmoLogo')" />
      </div>

      <div id="button-container" class="d-flex flex-column ml-auto">
        <BaseButton
          type="secondary"
          icon="$mdiDownload"
          class="mb-2"
          @click="$emit('download-data')"
        >
          <span>{{ $t("utils.downloadData") }}</span>
        </BaseButton>
        <BaseButton
          type="secondary"
          icon="$mdiDownload"
          @click="$emit('download-zip')"
        >
          <span>{{ $t("utils.downloadFiles") }}</span>
        </BaseButton>
      </div>
    </div>
    <div v-if="client && folder" class="d-flex flex-column">
      <BaseTitle class="my-2" v-text="'Récapitulatif'" />
      <BaseParagraph
        v-text="
          $t('folder.clientFullData.FolderName', {
            folderName: folder.name,
          })
        "
      />
      <BaseParagraph
        v-text="
          $t('folder.clientFullData.contact', {
            contactName: getFullName(client),
            contactEmail: client.email,
          })
        "
      />

      <BaseParagraph
        v-text="
          $t('folder.clientFullData.createdAt', {
            date: $d(new Date(folder.dateCreated), 'veryshort'),
          })
        "
      /> 
    </div>
  </BaseCard>
</template>

<script>
export default {
  name: "FullDataCardHeader",
  props: {
    client: {
      type: Object,
      required: true,
    },
    folder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasClient() {
      return this.client.isTemporary;
    },
  },

  methods: {
    getFullName(client) {
      if (client.lastname === "" && client.firstname === "") return "Anonyme";
      return `${client.lastname} ${client.firstname}`;
    },
  },
};
</script>
