<template>
  <div>
    <BaseTitle class="mb-6" v-text="$t('workflow.management')" />
    <div v-if="activeWorkflow">
      <!-- Workflow Header -->
      <Board class="mb-6">
        <template #header class="mb-6">
          <div class="w_header">
            <BaseTitle v-text="activeWorkflow.name" />
            <BaseButton
              icon="$mdiPencilOutline"
              @click="
                $store.commit(
                  'workflow/setModalCreateAndEditWorkflowDialog',
                  true
                )
              "
            >
              <span v-text="$t('utils.editName')" />
            </BaseButton>
          </div>
        </template>
        <template #main>
          <BaseDivider class="my-5" :grey="true" />
          <div class="campaign_section">
            <div class="campaign_label">
              <span v-text="`${$t('utils.automatedCampaign')} : `" />
              <router-link
                v-if="activeWorkflow.campaign"
                class="campaign_name"
                :to="{
                  name: 'campaignDetail',
                  params: {
                    organizationId: organizationId,
                    campaignId: activeWorkflow.campaign.id,
                  },
                }"
                v-text="activeWorkflow.campaign.name"
              />
            </div>
            <BaseButton
              icon="$mdiPencilOutline"
              @click="openWorkflowCampaignDialog = true"
            >
              <span
                v-text="
                  $t(activeWorkflow.campaign ? 'utils.edit' : 'utils.define')
                "
              />
            </BaseButton>
          </div>
        </template>
      </Board>
      <!-- AUTOMATION SECTION -->
      <template v-if="user && user.isStaff">
        <Board class="mb-6">
          <template #header class="mb-6">
            <div class="w_header">
              <BaseTitle v-text="$t('automation.title')" />
            </div>
          </template>
          <template #main>
            <BaseDivider class="my-5" :grey="true" />
            <div class="automation_section">
              <div class="action">
                <BaseButton
                  icon="$mdiPlus"
                  @click="openAutomationAction = true"
                >
                  {{ $t("automation.create_btn_label") }}
                </BaseButton>
              </div>
            </div>
            <div class="effect_list">
              <BaseDataTable
                :items="effectDetails.items"
                :loading="effectFilter.loading"
                :options="{
                  page: effectFilter.page,
                  itemsPerPage: effectFilter.pageSize,
                }"
                :server-items-length="effectDetails.count"
                :loading-text="$t('utils.loadingData')"
                @update:options="onPaginationChange"
              >
                <template #header>
                  <thead>
                    <tr>
                      <th>
                        {{ $tc("utils.action", 2) }}
                      </th>
                      <th>
                        {{ $t("utils.trigger") }}
                      </th>
                      <th>
                        {{ $t("utils.creationDate") }}
                      </th>
                      <th class="text-end">
                        {{ $t("utils.delete") }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <template #item="{ item }">
                  <tr class="tab_row">
                    <td>
                      <div class="row_item">
                        <div class="item_label" v-text="$t(item.effectLabel)" />
                        <div
                          class="item_extra"
                          v-text="item.effectExtraLabel"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="row_item">
                        <div class="item_label" v-text="$t(item.eventLabel)" />
                        <div
                          class="item_extra"
                          v-text="item.eventExtratLabel"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="row_item">
                        <div
                          class="item_label"
                          v-text="$d(new Date(item.dateCreated), 'veryshort')"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="action_section">
                        <BaseButtonIcon
                          small
                          color="secondary"
                          icon="$mdiTrashCanOutline"
                          @click="openRemoveEffectDialog(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </template>
              </BaseDataTable>
            </div>
          </template>
        </Board>
      </template>
      <Board class="mb-6">
        <template #header class="mb-6">
          <div class="d-flex justify-space-between align-center">
            <BaseTitle v-text="'Statuts'" />
            <!-- create workflow status button -->
            <BaseButton
              icon="$mdiPlus"
              @click="
                $store.commit(
                  'workflow/setModalCreateAndEditStatusDialog',
                  true
                )
              "
            >
              Créer un statut
            </BaseButton>
          </div>
        </template>
        <template #main>
          <WorkflowDetailStatus
            v-for="(status, index) in activeWorkflow.statuses"
            :key="index"
            :index-status="index + 1"
            :status="status"
            :is-last-status="activeWorkflow.statuses.length - 1 === +index"
          />
          <BaseAlert
            v-if="activeWorkflow.statuses.length === 0"
            color="secondary lighten-5 text-center"
          >
            <BaseParagraph bold v-text="$t('workflow.status.noStatus')" />
          </BaseAlert>
        </template>
      </Board>
    </div>
    <WorkflowCreateAndEditDialog />
    <WorkflowCreateAndEditStatusDialog />
    <WorkflowDeleteStatusDialog />
    <WorkflowStatusQuestionCreateAndEditDialog />
    <WorkflowStatusQuestionDeleteDialog />
    <WorkflowStatusTargetStatusCreateAndEditDialog />
    <WorkflowStatusTargetStatusDeleteDialog />
    <WorkFlowCollaboratorsAddAndDelDialog />
    <WorkflowRemoveStatusCategory />
    <WorkflowEditStatusCategory />
    <WorkflowCampaignDialog
      v-if="activeWorkflow && openWorkflowCampaignDialog"
      :organization-id="organizationId"
      :workflow="activeWorkflow"
      @close="openWorkflowCampaignDialog = false"
    />
    <AutomationActionDialog
      v-if="activeWorkflow && openAutomationAction"
      :organization-id="organizationId"
      :has-enoxa="activeOrganization.enoxa"
      :workflow="activeWorkflow"
      @close="openAutomationAction = false"
      @effectCreated="onEffectCreated"
    />
    <AutomationRemoveDialog
      v-if="activeWorkflow && removeEffectDialogState"
      :automation-effect="selectedEffect"
      :organization-id="organizationId"
      :workflow-id="activeWorkflow.id"
      @close="removeEffectDialogState = false"
      @effectRemoved="onEffectRemoved"
    />
  </div>
</template>

<script>
import Board from "@/core/Components/Board.vue";
import WorkflowDetailStatus from "../Components/WorkflowDetailStatus";
import WorkflowCreateAndEditDialog from "../Components/WorkflowCreateAndEditDialog";
import WorkflowCreateAndEditStatusDialog from "../Components/WorkflowCreateAndEditStatusDialog";
import WorkflowDeleteStatusDialog from "../Components/WorkflowDeleteStatusDialog";
import WorkflowStatusQuestionCreateAndEditDialog from "../Components/WorkflowStatusQuestionCreateAndEditDialog";
import WorkflowStatusQuestionDeleteDialog from "../Components/WorkflowStatusQuestionDeleteDialog";
import WorkflowStatusTargetStatusCreateAndEditDialog from "../Components/WorkflowStatusTargetStatusCreateAndEditDialog";
import WorkflowStatusTargetStatusDeleteDialog from "../Components/WorkflowStatusTargetStatusDeleteDialog";
import WorkFlowCollaboratorsAddAndDelDialog from "../Components/WorkFlowCollaboratorsAddAndDelDialog";
import WorkflowRemoveStatusCategory from "../Components/WorkflowRemoveStatusCategory";
import WorkflowEditStatusCategory from "../Components/WorkflowEditStatusCategory";
import WorkflowCampaignDialog from "@/modules/Admin/Components/WorkflowCampaignDialog.vue";
import AutomationActionDialog from "@/modules/Admin/Components/AutomationActionDialog.vue";
import AutomationRemoveDialog from "@/modules/Admin/Components/AutomationRemoveDialog.vue";
import WorkflowAPI from "@/modules/Admin/Services/workflow.api";

import { mapState } from "vuex";
import { AutomationEffect } from "@/core/Models/AutomationEffect";

export default {
  name: "WorkflowDetail",
  components: {
    Board,
    WorkflowDetailStatus,
    WorkflowCreateAndEditDialog,
    WorkflowCreateAndEditStatusDialog,
    WorkflowDeleteStatusDialog,
    WorkflowStatusQuestionCreateAndEditDialog,
    WorkflowStatusQuestionDeleteDialog,
    WorkflowStatusTargetStatusCreateAndEditDialog,
    WorkflowStatusTargetStatusDeleteDialog,
    WorkFlowCollaboratorsAddAndDelDialog,
    WorkflowRemoveStatusCategory,
    WorkflowEditStatusCategory,
    WorkflowCampaignDialog,
    AutomationActionDialog,
    AutomationRemoveDialog,
  },
  props: {
    workflowId: {
      type: String,
      required: true,
    },
    organizationId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      openWorkflowCampaignDialog: false,
      openAutomationAction: false,
      removeEffectDialogState: false,
      selectedEffect: null,
      effectDetails: {
        items: [],
        count: 0,
        next: 0,
        previous: 0,
      },
      effectFilter: {
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  computed: {
    ...mapState({
      activeWorkflow: (state) => state.workflow.activeWorkflow,
      activeOrganization: (state) => state.organization.activeOrganization,
      user: (state) => state.account.user,
    }),
  },
  watch: {
    activeWorkflow: {
      handler(newVal, oldVal) {
        if ((!oldVal && newVal) || (newVal && newVal.name !== oldVal.name)) {
          this.setBreadcrumbLabel();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        if (!this.workflowId) return;
        await this.$store.dispatch("workflow/fetchActiveWorkflow", {
          organizationId: this.organizationId,
          workflowId: this.workflowId,
        });
      } catch (error) {
        throw new Error(error);
      }
    },
    setBreadcrumbLabel() {
      this.$route.meta.breadcrumbs[3].label = `${this.activeWorkflow.name} `;
    },
    async fetchWorkflowEffect() {
      this.effectFilter.loading = true;
      try {
        const res = await WorkflowAPI.getOrganizationWorkflowEffect({
          organizationId: this.organizationId,
          workflowId: this.workflowId,
          params: {
            page: this.effectFilter.page,
            page_size: this.effectFilter.pageSize,
          },
        });
        this.effectDetails = {
          ...res.data,
          items: res.data.results.map((d) => new AutomationEffect(d)),
        };
      } catch (error) {
        this.$store.dispatch("snackbar/active", {
          message: this.$t("utils.errorHasHappen"),
          type: "ERROR",
        });
      }
      this.effectFilter.loading = false;
    },
    onPaginationChange(pagination) {
      this.effectFilter.page = pagination.page;
      this.effectFilter.pageSize =
        pagination.itemsPerPage < 0
          ? this.effectDetails.count
          : pagination.itemsPerPage;
      this.fetchWorkflowEffect();
    },
    onEffectCreated() {
      this.effectFilter.page = 1;
      this.fetchWorkflowEffect();
    },
    openRemoveEffectDialog(effect) {
      this.selectedEffect = effect;
      this.removeEffectDialogState = true;
    },
    onEffectRemoved(effect) {
      this.removeEffectDialogState = false;
      this.effectDetails.items = this.effectDetails.items.filter(
        (e) => e.id != effect.id
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.w_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.campaign_section {
  display: flex;
  align-items: center;
  gap: 16px;
  .campaign_label {
    color: #353542;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    .campaign_name {
      color: #2536cc;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
.automation_section {
  .action {
    display: flex;
    justify-content: flex-end;
  }
}
.effect_list {
  padding-top: 16px;
  .tab_row {
    .row_item {
      padding: 16px 0;
      font-family: "Inter";
      font-style: normal;
      .item_label {
        color: #353542;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }
      .item_extra {
        color: #707080;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
    .action_section {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
    }
  }
}
</style>
