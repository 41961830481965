import CosmoUser from "./CosmoUser";
import User from "./User";
import Record from "./Record";
import Log from "./Log";

export const VALIDATION_STATUS = {
  UPDATE_REQUESTED: "UPDATE_REQUESTED",
  UPDATED: "UPDATED",
  VALIDATED: "VALIDATED",
};

export const FOLDER_STATE = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};
export default class Folder {
  constructor({
    id = Number.NaN,
    name = "",
    client = new CosmoUser(),
    assign = null,
    owners = [],
    records = [],
    logs = [],
    recordsCompletedCount = Number.NaN,
    recordsCount = Number.NaN,
    status = Number.NaN,
    workflow = Number.NaN,
    validationStatus = "",
    dateCreated = null,
    dateUpdated = null,
    emails = [],
    hasSpamEmail = null,
    state = FOLDER_STATE.OPEN,
    activeStatus = Number.NaN,
  } = {}) {
    this.id = Number.parseInt(id, 10);
    this.name = name;
    this.client = client ? new CosmoUser(client) : null;
    this.assign = assign;
    this.owners = owners ? owners.map((owner) => new User(owner)) : [];
    this.logs = logs ? logs.map((log) => new Log(log)) : [];
    this.records = records ? records.map((record) => new Record(record)) : [];
    this.recordsCompletedCount = Number.parseInt(recordsCompletedCount, 10);
    this.recordsCount = Number.parseInt(recordsCount, 10);
    this.status = Number.parseInt(status, 10);
    this.workflow = Number.parseInt(workflow, 10);
    this.validationStatus = validationStatus;
    this.dateCreated = dateCreated ? new Date(dateCreated) : null;
    this.dateUpdated = dateUpdated ? new Date(dateUpdated) : null;
    this.hasSpamEmail = hasSpamEmail;
    this.state = state;
    this.emails = emails ? emails : [];
    this.activeStatus = activeStatus;
  }

  /**
   * Update Record Question
   * @param {number} recordId
   * @param {Question} question
   */
  updateRecordQuestion(recordId, question) {
    if (this.records.length) {
      const record = this.records.find((r) => r.id == recordId);
      for (const step of record.steps) {
        step.questions = step.questions.map((q) => {
          if (q.id == question.id) {
            return question;
          }
          return q;
        });
        // Check Step Validation
        if (
          step.questions.some(
            (q) =>
              q.answer.validationStatus == VALIDATION_STATUS.UPDATE_REQUESTED
          )
        ) {
          step.validationStatus = VALIDATION_STATUS.UPDATE_REQUESTED;
        } else if (
          step.questions.some(
            (q) => q.answer.validationStatus == VALIDATION_STATUS.UPDATED
          )
        ) {
          step.validationStatus = VALIDATION_STATUS.UPDATED;
        } else if (
          step.questions.some(
            (q) => q.answer.validationStatus == VALIDATION_STATUS.VALIDATED
          )
        ) {
          step.validationStatus = VALIDATION_STATUS.VALIDATED;
        }
      }
    }
  }
}
