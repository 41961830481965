import QuestionTemplateAPI from "@/core/Services/questionTemplate.api";
import Option from "@/core/Models/Option";
import QuestionTemplate from "@/core/Models/QuestionTemplate";

export async function fetchQuestionTemplates({
  organizationId,
  page,
  slug,
  pageSize,
  typeQuestion,
  name,
}) {
  const res = await QuestionTemplateAPI.getQuestionTemplates({
    organizationId,
    page,
    slug,
    pageSize,
    typeQuestion,
    name,
  });
  return {
    items: res.data.results,
    count: res.data.count,
    next: res.data.next,
    previous: res.data.previous,
    page: res.data.page,
  };
}

export async function getQuestionTemplateOption({
  organizationId,
  questionTemplateId,
}) {
  let page = 1;
  let options = [];
  while (page) {
    const data = (
      await QuestionTemplateAPI.getQuestionTemplateOption({
        organizationId,
        questionTemplateId,
        page,
      })
    ).data;
    options = [...options, ...data.results];
    page = data.next;
  }
  return options.map((t) => new Option(t));
}

export async function createQuestionTemplate({ organizationId, payload }) {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("label", payload.label);
  formData.append("has_free_option", payload.hasFreeOption);
  formData.append("type_question", payload.typeQuestion);
  formData.append("conditions", payload.conditions);
  formData.append("error_message", payload.errorMessage);
  if (payload.canWizidee != undefined)
    formData.append("can_wizidee", payload.canWizidee);
  payload.slug ? formData.append("slug", payload.slug) : null;
  payload.helpText ? formData.append("help_text", payload.helpText) : null;
  payload.helpFile ? formData.append("help_file", payload.helpFile) : null;
  // CREATE QUESTION TEMPLATE
  const createdQuestion = (
    await QuestionTemplateAPI.postQuestionTemplate({
      organizationId,
      payload: formData,
    })
  ).data;
  // CREATE OPTIONS
  if (payload.options?.length) {
    createdQuestion.options = [];
    for (const option of payload.options) {
      const res = await QuestionTemplateAPI.postQuestionTemplateOption({
        organizationId,
        questionTemplateId: createdQuestion.id,
        payload: option,
      });
      createdQuestion.options.push(res.data);
    }
  }
  return new QuestionTemplate(createdQuestion);
}

export async function editQuestionTemplate({
  organizationId,
  questionTemplateId,
  deletedOptionsIds,
  payload,
}) {
  const formData = new FormData();
  if (payload.name) formData.append("name", payload.name);
  if (payload.label) formData.append("label", payload.label);
  if (payload.errorMessage != undefined)
    formData.append("error_message", payload.errorMessage);
  if (payload.conditions != undefined)
    formData.append("conditions", payload.conditions);
  if (payload.canWizidee != undefined)
    formData.append("can_wizidee", payload.canWizidee);
  if (payload.helpText != undefined)
    formData.append("help_text", payload.helpText);
  if (payload.helpFile != undefined)
    formData.append("help_file", payload.helpFile);
  if (payload.has_free_option !== undefined)
    formData.append("has_free_option", payload.hasFreeOption);
  const editedQuestion = (
    await QuestionTemplateAPI.patchQuestionTemplate({
      organizationId,
      questionTemplateId,
      payload: formData,
    })
  ).data;
  // EDIT OPTIONS
  if (payload.options?.length) {
    // DELETE OPTIONS
    for (const optionId of deletedOptionsIds) {
      await QuestionTemplateAPI.deleteQuestionTemplateOption({
        organizationId,
        questionTemplateId,
        optionId,
      });
    }
    // EDIT OR CREATE OPTION
    for (const option of payload.options) {
      if (option.id) {
        await QuestionTemplateAPI.patchQuestionTemplateOption({
          organizationId,
          questionTemplateId: questionTemplateId,
          optionId: option.id,
          payload: {
            name: option.name,
            order: option.order,
          },
        });
        continue;
      }
      const newOption = (
        await QuestionTemplateAPI.postQuestionTemplateOption({
          organizationId,
          questionTemplateId: questionTemplateId,
          payload: option,
        })
      ).data;
      option.id = newOption.id;
    }
    editedQuestion.options = payload.options;
  }
  return new QuestionTemplate(editedQuestion);
}
